<template>
    <div class="top__item date-block"  v-if="(user.groupCode == 'T' || user.groupCode == 'P')">
        <span class="text-color-grey text-size-xs">Вознаграждение</span>
        <span class="text-bold text-size-xxl mt8 mb8">{{ cost }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
        <span class="text-color-grey text-size-xs" v-if="user.groupCode == 'T'">250₽ за метр</span>
        <span class="text-color-grey text-size-xs" v-if="user.groupCode == 'P'">100₽ за метр</span>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "TopItemBlockReward",
    props: {
        item: {}
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        cost(){
            let flatArea = this.item.props.flatArea.replace(',','.');
            if(flatArea < 50){
                flatArea = 50
            }
            return (flatArea * (this.user.groupCode == 'T' ? 250 : 100)).toFixed(0)
        }
    },
    methods: {
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
    }
}
</script>

<style scoped>

</style>