<script>
    import { mapState } from 'vuex';

    export default {
        name: "SmsOutOfTimeLink",
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                button: 'normal',
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
        methods: {
            openSendSms(item, type) {
                this.button = 'load';
                this.$store.dispatch('popups/getData', {id: item.id, modalData: type}).then(response => {
                    this.button = 'normal';
                    this.$store.commit('requests/setItemProps', {
                        item: this.item, props: {
                            smsSendText: response.items,
                        }
                    });
                    this.$nextTick(() => {
                        this.$store.dispatch('popups/open', {name: this.popups.sendSms.name, request: item});
                    })
                });
            },
        }
    }
</script>

<template>
    <a href="javascript:void(0);"
       class="link-yellow df mr40" @click="openSendSms(item, 'SendSmsOutOfTime')">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg" class="mr4">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1 3C1 2.44772 1.44772 2 2 2H10C10.5523 2 11 2.44771 11 3V8.5C11 9.05228 10.5523 9.5 10 9.5H7.70711L6.35355 10.8536C6.15829 11.0488 5.84171 11.0488 5.64645 10.8536L4.29289 9.5H2C1.44772 9.5 1 9.05228 1 8.5V3ZM10 3H2V8.5H4.5C4.63261 8.5 4.75979 8.55268 4.85355 8.64645L6 9.79289L7.14645 8.64645C7.24022 8.55268 7.36739 8.5 7.5 8.5H10V3Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.75 5.75C6.75 6.16421 6.41421 6.5 6 6.5C5.58579 6.5 5.25 6.16421 5.25 5.75C5.25 5.33579 5.58579 5 6 5C6.41421 5 6.75 5.33579 6.75 5.75Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.75 5.75C8.75 6.16421 8.41421 6.5 8 6.5C7.58579 6.5 7.25 6.16421 7.25 5.75C7.25 5.33579 7.58579 5 8 5C8.41421 5 8.75 5.33579 8.75 5.75Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.75 5.75C4.75 6.16421 4.41421 6.5 4 6.5C3.58579 6.5 3.25 6.16421 3.25 5.75C3.25 5.33579 3.58579 5 4 5C4.41421 5 4.75 5.33579 4.75 5.75Z"/>
        </svg>
        <span>Не успеваю</span>
    </a>
</template>

<style scoped>

</style>