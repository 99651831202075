<template>
    <div class="">
        <requests-list/>

        <!-- Окончательно одобрить -->
        <popup :show="popups.approve.show"
                :width="540"
               :name="popups.approve.name">
            <approve/>
        </popup>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Popup from '@/components/modal/Popup.vue';
    import RequestsList from '@/components/requests/RequestsList.vue';
    import Approve from '@/components/modal/requests/Approve.vue';

    export default {
        name: "RequestsWaitContract",
        props: {
        },
        components: {
            Popup,
            RequestsList,
            Approve
        },
        data: function(){
          return {

          }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
        methods: {
            fetchData () {
                //console.log('fetch');
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
