<template>
  <div class="baza-list">
    <baza-item v-for="request in requests" :item="request" :key="request.id"/>
    <div v-if="isLoad" class="baza-item --ghost">
      <div class="baza-item__header">
        <div class="l">
          <span></span>
        </div>
        <div class="r">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="baza-item__info">
        <div class="top">
          <div class="l">
            <div class="top__item date-block">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="top__item date-block">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="top__item date-block">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="r">
            <span></span>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom__item customer">
            <div class="l">
              <span></span>
            </div>
            <div class="r">
              <span></span>
            </div>
          </div>
          <div class="bottom__item addr">
            <div class="l">
              <span></span>
            </div>
            <div class="r">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import BazaItem from '@/components/BazaItem.vue'


    export default {
        name: "RequestsList",
        props: {
            status: {
                default: function(){
                    return 'new';
                }
            }
        },
        components: {
            Tooltip,
            Popup,
            BazaItem,

        },
        data: function(){
          return {
              init: false
          }
        },
        computed: {
            ...mapState({
                isLoad: state => state.requests.isLoad,
                page: state => state.requests.page,
                popups: state => state.popups.items,
                requests: state => state.requests.items,
            }),
        },
        mounted() {
            this.fetchData({pageNum: false});
            this.init = true;
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            this.init = false;
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: function () {
                let scroll = window.scrollY;
                let viewport = window.innerHeight;
                let bodyHeight = document.body.clientHeight;
                if(bodyHeight-80 <= scroll + viewport && this.init) {
                    this.fetchData({pageNum: this.page});
                }
            },
            fetchData (data) {
                this.$nextTick(() => {
                    let pageNum = 0;
                    if (typeof data !== 'undefined') {
                      pageNum = data.pageNum;
                    }
                    this.$store.dispatch('requests/getList', {status: this.status, page: pageNum, additionalElement: this.$route.query.id ? this.$route.query.id : false }).then(() => {
                        setTimeout(() => {
                            let countdownItems = document.getElementsByClassName('js-countdown');

                            for (let i in countdownItems) {
                                let eventTime;
                                if (typeof(countdownItems[i]) === 'object') {
                                    if (typeof(window.diff_intervals) !== 'object') {
                                        window.diff_intervals = {};
                                    }

                                    if (window.diff_intervals[countdownItems[i].getAttribute('data-id')]) {
                                        clearInterval(window.diff_intervals[countdownItems[i].getAttribute('data-id')]);
                                    }

                                    //таймер отключили, если понадобится, то внизу код надо раскомментировать.
                                    let eventTime = new Date(countdownItems[i].getAttribute('data-time')).getTime();
                                    let now = new Date().getTime();
                                    let distance = eventTime - now;

                                    // Time calculations for days, hours, minutes and seconds
                                    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                                    hours = hours < 10 ? '0' + hours : hours;
                                    minutes = minutes < 10 ? '0' + minutes : minutes;
                                    seconds = seconds < 10 ? '0' + seconds : seconds;

                                    countdownItems[i].innerHTML = '<span class="countdown">' + hours + ':' + minutes + '</span>';

                                    /*window.diff_intervals[countdownItems[i].getAttribute('data-id')] = setInterval(() => {
                                        if (!countdownItems[i]) {
                                            clearInterval(window.diff_intervals[countdownItems[i]]);
                                        } else {

                                            let eventTime = new Date(countdownItems[i].getAttribute('data-time')).getTime();
                                            let now = new Date().getTime();
                                            let distance = eventTime - now;

                                            // Time calculations for days, hours, minutes and seconds
                                            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                                            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                                            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                                            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                                            hours = hours < 10 ? '0' + hours : hours;
                                            minutes = minutes < 10 ? '0' + minutes : minutes;
                                            seconds = seconds < 10 ? '0' + seconds : seconds;

                                            countdownItems[i].innerHTML = '<span class="countdown">' + hours+':'+minutes+':'+seconds + '</span>';
                                        }
                                    }, 1000);*/
                                }
                            }
                        }, 200);
                    });
                });
            },
            openHideCoupon(){
              this.$store.dispatch('popups/open', {name: this.popups.hideCoupon.name});
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
