<template>
    <div class="popup__header">
        <div class="title">Какую кнопку нажать?</div>
    </div>

    <div class="popup__content">
        <span class="text-uppercase text-bold">Дать подумать</span>
        <br>Заказчику нужно подумать несколько дней,
        <br>сравнить цены или посоветоваться с супругой.<br><br>
        <span class="text-uppercase text-bold">Отказаться</span>
        <br>Заказчик вам не нравится или сотрудничество
        <br>с ним невозможно по другим причинам.
        <br>
        <br>
        <span class="text-uppercase text-bold">Отложить</span>
        <br>Сотрудничество откладывается на срок более недели,
        <br>но заказчик хочет с вами работать.
        <br>
        <br>
        <span class="text-uppercase text-bold">Предоплата</span>
        <br>Заказчик внёс денежную сумму, чтобы
        <br>зарезервировать место в графике работ.
        <br>
        <br>
            <span class="text-uppercase text-bold">Взять в работу</span>
        <br>Сделка состоялась и вы уже точно
        <br>заключили с заказчиком договор.
        <hr class="mt24 mb24">
        <div class="text-italic ">При любом действии всегда пишите подробный комментарий, чтобы даже через полгода можно было легко вспомнить, как было дело.</div>
         <button type="button" name="button" class="btn btn--trans mt24" @click="closeHowNamePrice">всё понятно!</button>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "HowPressBtn",
        components: {
        },
        methods: {
			closeHowNamePrice(){
				this.$store.commit('popups/close', {name: this.popups.howPressBtn.name});
			},

        },
        data: function () {
            return {

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
    }
</script>

<style scoped>

</style>
