<template>
<div>
    <div class="popup__header">
        <div class="title">Сменить Ассистента руководителя</div>
        <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
    </div>
    <div class="popup__content">
        <div class="mt40">
            <worker-select :change-person="true" v-model="form.workerAssistant" :workers="allWorkers.assistants" :title="'Ассистент руководителя'" :type="'AR'" :error="form.workerAssistant.error" :error-text="'Выберите ассистента руководителя'" @toggled="removeError(form.workerAssistant)"/>
        </div>
        <div class="df df-space-between mt40">
            <submit-btn @click="send" tag="button" :type="btnType" :class="{disabled: false}">
                подтвердить
            </submit-btn>
        </div>
    </div>
</div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import WorkerSelect from '@/components/forms/WorkerSelect.vue';

    export default {
        name: "ChangeAR",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            WorkerSelect
        },
        mounted() {
            if(this.item.extProps.staff.AR){
                let AR = this.item.extProps.staff.AR;
                this.form.workerAssistant.id =  AR.id;
                this.form.workerAssistant.firstName =  AR.firstName;
                this.form.workerAssistant.lastName =  AR.lastName;
                this.form.workerAssistant.number =  AR.number;
                for(let i in this.allWorkers.assistants){
                    let assistant = this.allWorkers.assistants[i];
                    if(assistant.id === this.form.assistants.id){
                        this.form.workerAssistant.cnt = assistant.cnt;
                    }
                }
            }
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                if(!error) {
                    let params = {
                        action: 'RequestChangeAR',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        params.params[field] = this.form[field].id;

                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((data) => {
                        this.btnType = 'ok';
                        if(this.user.char === 'ar'){
                            this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        } else {
                            this.$store.commit('requests/setItem', {item: this.item, returnItem: data.data});
                        }
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.changeAssistant.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }   
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                appPath: process.env.VUE_APP_PATH_BACK,
                form: {
                    workerAssistant:{
                        id: '',
                        firstName: '',
                        lastName: '',
                        number: '',
                        models: '',
                        drawings: '',
                        cnt: 0,
                        error: false,
                    }
                }
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                options: state => state.options,
                user: state => state.auth.user,
                item: state => state.popups.curItemData,
                optionsMain: state => state.options,
                allWorkers: state => state.requests.allWorkers,
            }),
        },
    }
    </script>

<style scoped>

</style>
