<template>
    <div class="status grey" v-if="code === 'reject_not_call'">
        отклонена<br>без отзвона
    </div>
    <div class="status red" v-if="code === 'not_pay_on_time'">
        не оплачена<br>вовремя
    </div>
    <div class="status grey" v-if="code === 'customer_refused'">
        Заказчик<br>отказался
    </div>
    <div class="status red" v-if="code === 'master_refused'">
        Исполнитель<br>сам отказался
    </div>
    <div class="status green" v-if="code === 'order_submitted'">
        отчёт сдан
    </div>
    <div class="status red" v-if="code === 'order_not_submitted'">
        отчёт не сдан<br>{{ stampValue }} дн.
    </div>
    <div class="status green" v-if="code === 'video_ready'">
        рекомендую снять<br>видео
    </div>
    <div class="status red" v-if="code === 'video_no_ready'">
        не рекомендую<br>снимать видео
    </div>
    <div class="status red" v-if="code === 'expired_master_late'">
        время истекло
    </div>
    <div class="status red" v-if="code === 'secret_not_need'">
        ОТКАЗАЛСЯ<br>В СЕКРЕТНОМ<br>РАЗДЕЛЕ
    </div>
    <div class="status yellow" v-if="code === 'secret_special'">
        ТОЛЬКО<br>СПЕЦМОНТАЖ
    </div>
</template>

<script>
export default {
    name: "Stamp",
    props: {
        code: {
            default: ''
        },
        stampValue: {
            default: ''
        },
    }
}
</script>

<style scoped>

</style>