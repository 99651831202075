<template>
    <div>
        <div class="popup__header">
            <div class="title" v-if="notCall">Пометить заявку как спам</div>
            <div class="title" v-else>Отклонить заявку</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <input-select v-if="!notCall" :select-arrow-style="'top: 8px;'" :select-list-style="'max-height: 240px;'" :choose-block-class="'mt8 mb40 choose-block'" :caption-class="'text-size-ms'" @toggled="form.reason.error = false" :title="'типовая причина'" v-model="form.reason.value" :error="form.reason.error" :options="reasons"/>
            <div class="text-color-grey text-uppercase text-size-xs mt40" v-if="!notCall">
                Повторные заявки
            </div>
            <div class="dif mb24 mt8" v-if="!notCall">
                <tumbler @change="form.tumbler.error=false" :error="form.tumbler.error" :options="option" :elem-width="114" v-model="form.tumbler.value"/>
            </div>
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :auto-focus="true"
                            :label="'Причина отклонения'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Укажите причину отклонения"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40" v-if="!notCall">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Заказчику объяснены причины отклонения</label>
            </div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Отклонённые»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import InputSelect from '@/components/forms/InputSelect.vue';
import Tumbler from '@/components/Tumbler.vue';


export default {
        name: "RejectRequest",
        props:{
            notCall: {
                default: false
            }
        },
        components: {
            Tumbler,
            SubmitBtn,
            TextareaField,
            InputSelect
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                this.form.tumbler.error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false
                    if(this.form[field].value.toString().length == 0 && field == 'text' || this.form[field].value === false && !this.notCall){
                        errorField = true;
                    }

                    if((field === 'reason') && !this.form[field].value.valueClear && !this.notCall) {
                        errorField = true;
                    }

                    if(!this.notCall && field === 'tumbler' && Object.keys(this.form[field].value).length === 0) {
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }

                if(!error) {
                    let params = {
                        action: this.notCall ? 'RejectRequestNotCall' : 'RejectRequest',
                        id: this.item.id,
                        params: {}
                    };
                    for (let field in this.form) {
                        if ( field == 'reason') {
                            params.params[field] = this.form[field].value.valueClear;
                        } else if ( field == 'tumbler') {
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }

                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id})
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.rejectRequest.name});
                            this.$store.commit('popups/close', {name: this.popups.rejectRequestNow.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                reasons: [],
                form: {
                    tumbler: {
                        error: false,
                        value: {}
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    reason: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },
                option: [
                    {name: 'Разрешить', id: '0'},
                    {name: 'Запретить', id: 'Y'},
                ],
            }
        },
        mounted() {
            for(let index in this.item.props.reasons) {
                this.reasons.push({value: this.item.props.reasons[index].name,valueClear: this.item.props.reasons[index].value, desc: ''})
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
