<template>
    <div>
        <div class="popup__header">
            <div class="title">Запросить подтверждение</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="mb40">
                <textarea-field :class="'full'"
                    v-model="form.text.value"
                    :error-text="''"
                    :label="'Характеристика от координатора и руководителя'"
                    :is-error="form.text.error"
                    @focus="form.text.error = false"
                    placeholder="Напишите совместную характеристику на заказчика"
                    :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <span class="text-italic text-color-grey">
                Заказчику будет отправлена СМС и Email со ссылкой на подтверждение<br>
                3D-модели и просьбой сделать это как можно скорей.
            </span>
            <hr>
            <div class="text-size-l text-bold mb12">Будем предлагать ремонт?</div>
            <tumbler @change="removeError(form.option)" :options="optionsWillOffer" :widthAuto="true" v-model="form.option.value" :error="form.option.error"/>
            <template v-if="form.option.value.id != 279">
                <textarea-field :class="'full mt40'"
                    v-model="form.comment.value"
                    :error-text="''"
                    :label="'Прокомментируйте своё решение'"
                    :is-error="form.comment.error"
                    :auto-focus="true"
                    @focus="form.comment.error = false"
                    placeholder="Напишите подробный комментарий..."
                    :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </template>
            <div class="text-italic text-color-red2 mt40">
                Обратите внимание, больше вы не будете взаимодействовать<br>
                с заказчиком, поэтому изменить свой выбор по ремонту уже не сможете.<br>
                После подтверждения заказчиком 3D-модели, технолог разработает<br>
                схемы и чертежи, а после проверки они сразу будут отправлены<br>
                заказчику на электронную почту.
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Ожидают подтверждения»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import Tumbler from '../../Tumbler.vue';

    export default {
        name: "ModelRequestConfirm",
        components: {
            SubmitBtn,
            TextareaField,
            Tumbler,
        },
        data: function () {
            return {
                btnType: 'normal',
                optionsWillOffer: [
                    {id: 277, name: 'Да, будем' },
                    {id: 278, name: 'Нет, не будем' },
                    {id: 279, name: 'Регионы' },
                ],
                form: {
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    comment: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            this.form.text.value = this.item.props.characterKR;
            const option = this.optionsWillOffer.find(option => +option.id === +this.item.props.autosale_repair);
            if (option) {
                this.form.option.value = option;
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    this.form[field].error = false;
                }
                if (this.form.option.value === false) {
                    error = true;
                    this.form.option.error = true;
                }
                if (this.form.option.value.id !== 279) {
                    if(this.form.comment.value.length == 0){
                        error = true;
                        this.form.comment.error = true;
                    }
                }
                if(this.form.text.value.length == 0){
                    error = true;
                    this.form.text.error = true;
                }
                if(!error) {
                    let params = {
                        action: 'ModelRequestConfirm',
                        id: this.item.id,
                        params: {
                            text: this.form.text.value,
                            comment: this.form.comment.value,
                            option: this.form.option.value.id,
                        }
                    };
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.modelRequestConfirm.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
    }
</script>

<style scoped>

</style>
