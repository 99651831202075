<template>
    <div class="top">
        <div class="l">
            <div class="top__item date-block"  :class="{mr40: hasState(['repair_in_process_*','repair_terminate_*','repair_finish_*'])}">
                <!-- Типы замера  -->
                <span class="dif">
                    <span class="text-color-grey text-size-xs" v-if="item.props.typeMetering === ''">Дата замера</span>
                    <span class="text-color-grey text-size-xs remote" v-if="item.props.typeMetering === 'remote'">дистанционный замер</span>
                    <span class="text-color-grey text-size-xs normal" v-if="item.props.typeMetering === 'normal'">обычный замер</span>
                    <span class="open-gallery ml4" :class="{load:loadContactGallery}">
                        <span @click="openContractGallery(item)" class="i i-photo i--blue" v-if="item.props.contractId && item.props.typeMetering === 'remote'"></span>
                    </span>
                </span>

                <span class="text-bold text-size-xxl mt8 mb8 text-uppercase df">{{item.props.dateProject.cardDate}}<span class="i-wrapper pencil ml4" v-if="item.props.isEditDate" @click="openPopup(item, popups.measureDate.name)"><span class="i i-pencil2 i--blue"></span><span class="i i-pencil2-active i--blue"></span></span></span>
                <div class="df">
                            <span class="text-color-grey text-size-xs">
                                <span v-if="hasState(['request_new_default','request_new_director','request_new_director','request_new_analytic','request_new_metering','request_new_metering_empty','request_wait_contract_default','request_check_contract','request_pre_approved_default', 'request_wait_interview_default', 'director_measurer_money', 'request_rejected_default', 'measure_queue_default', 'measure_confirmed_default', 'measure_confirmed_ARKR', 'measure_confirmed_Z', 'measure_in_work_default', 'measure_in_work_bill', 'measure_in_work_ARKR', 'measure_in_work_Z', 'measure_put_aside_default', 'measure_canceled_default', 'measure_completed_default', 'measure_in_work_Z_cashless'])">{{item.props.dateProject.cardTime}}</span>
                                <tooltip v-if="item.props.earlyMetering && hasState(['request_new_default','request_new_metering','request_new_metering_empty','request_wait_contract_default','request_check_contract','request_pre_approved_default', 'request_wait_interview_default', 'director_measurer_money', 'request_rejected_default', 'measure_queue_*', 'measure_confirmed_default', 'measure_confirmed_ARKR', 'measure_confirmed_Z', 'measure_in_work_default', 'measure_in_work_bill', 'measure_in_work_ARKR', 'measure_in_work_Z', 'measure_put_aside_default', 'measure_canceled_default', 'measure_completed_default', 'concepts_in_work_default', 'concepts_in_work_ARKR', 'concepts_approved_default'])"
                                         :direction="'right-center-direction'"
                                         :is-btn-close="false"
                                         :add-style="{width: 268 + 'px'}">
                                    <template v-slot:tooltipBtn>
                                        <span class="link">
                                            Готовы раньше
                                        </span>
                                    </template>

                                    <template v-slot:tooltipContent>
                                        <span v-html="convertNewLine(item.props.putAsideComment)"></span>
                                    </template>
                                </tooltip>
                            </span>
                    <a v-if="(!hasState(['repair_finish_*','repair_request_M','repair_think_M','repair_reject_M','repair_aside_M','repair_prepay_M','repair_in_process_M','repair_terminate_M']) && item.props.conceptFile.path || item.props.conceptFileNew.path) && !hasState(['request_new_default','request_new_metering','request_new_metering_empty','request_wait_contract_default','request_check_contract','request_pre_approved_default', 'request_wait_interview_default', 'director_measurer_money', 'request_rejected_default', 'measure_queue_default', 'measure_confirmed_default', 'measure_confirmed_ARKR', 'measure_confirmed_Z', 'measure_in_work_default', 'measure_in_work_bill', 'measure_in_work_ARKR', 'measure_in_work_Z', 'measure_put_aside_default', 'measure_canceled_default', 'measure_completed_default', 'concepts_in_work_default', 'concepts_in_work_ARKR', 'concepts_approved_default'])" @click="openConceptGallery()" href="javascript:void(0);" class="link text-uppercase text-size-xs mr16">&nbsp;Концепция</a>
                    <a href="javascript:void(0);" @click="openPopup(item, popups.replaceConcept.name)" v-if="!hasState(['repair_finish_*','repair_request_M','repair_think_M','repair_reject_M','repair_aside_M','repair_prepay_M','repair_in_process_M','repair_terminate_M']) && hasState(['concepts_on_approval_default'])" class="link text-uppercase text-size-xs">
                        Заменить
                    </a>
                    <a target="_blank" v-if="hasState(['repair_finish_*','repair_request_M','repair_think_M','repair_reject_M','repair_aside_M','repair_prepay_M','repair_in_process_M','repair_terminate_M']) && item.props.projectLinkGen" :href="item.props.projectLinkGen" class="link text-uppercase text-size-xs ml4">Проект</a>
                    <a target="_blank" v-else-if="hasState(['repair_finish_*','repair_request_M','repair_think_M','repair_reject_M','repair_aside_M','repair_prepay_M','repair_in_process_M','repair_terminate_M']) && item.props.projectLink" :href="item.props.projectLink" class="link text-uppercase text-size-xs ml4">Проект</a>
                    <div class="has-hint" v-else-if="hasState(['repair_finish_*','repair_request_M','repair_think_M','repair_reject_M','repair_aside_M','repair_prepay_M','repair_in_process_M','repair_terminate_M'])">
                        <a href="javascript:void(0);" class="link text-uppercase text-size-xs ml4">Проект</a>
                        <div class="hint">Проект не найден</div>
                    </div>
                </div>
            </div>
            <top-item-block-area :item="item"/>
            <top-item-block-reward :item="item"/>
            <top-item-block-contract-price :item="item"/>
            <top-item-block-report :item="item"/>
            <top-item-block-contract :item="item"/>

        </div>
        <div class="r" style="position: relative;">
            <div class="change-link change_z mb8" v-if="showChangeZ()"><a @click.prevent="openChangeZ(item)" href="#">сменить замерщика</a></div>
            <stickers :item="item"/>
            <div class="change-link change_command mt8" v-if="showChangeCommand()"><a @click.prevent="openChangeCommand(item)" href="#">сменить команду</a></div>
        </div>
    </div>
    <concept-gallery ref="viewerConcept" :showConceptGallery="showConceptGallery" :item="item"/>
</template>

<script>
import {mapState} from "vuex";
import ConceptGallery from '@/components/BazaItem/ConceptGallery.vue'
import TopItemBlockArea from '@/components/BazaItem/TopItemBlockArea.vue'
import TopItemBlockContract from '@/components/BazaItem/TopItemBlockContract.vue'
import TopItemBlockContractPrice from '@/components/BazaItem/TopItemBlockContractPrice.vue'
import TopItemBlockReport from '@/components/BazaItem/TopItemBlockReport.vue'
import TopItemBlockReward from '@/components/BazaItem/TopItemBlockReward.vue'
import Stickers from '@/components/BazaItem/Stickers.vue'
import Tooltip from '@/components/Tooltip.vue';
import { convertNewLine } from "@/helpers/util/helper";

export default {
    name: "TopBlock",
    components: {
        ConceptGallery,
        TopItemBlockArea,
        TopItemBlockContract,
        TopItemBlockContractPrice,
        TopItemBlockReport,
        TopItemBlockReward,
        Stickers,
        Tooltip
    },

    props: {
        item: {},
    },
    data(){
        return {
            showConceptGallery: false,
            loadContactGallery: false
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            user: state => state.auth.user,
            workers: state => state.requests.workers,
            menuList: state => state.menus.itemsTop,
        }),
        isGiveDesign() {
            return this.$route.name === 'give-design';
        },
    },
    methods: {
        convertNewLine(text) {
            return convertNewLine(text);
        },
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
        openPopup(item, popup){
            if((popup == this.popups.repairTerminate.name || popup == this.popups.repairFinish.name) && item.extProps.stamp == 'order_not_submitted'){
                this.$store.dispatch('popups/open', {name: this.popups.repairFinishError.name, request: item});
            } else {
                this.$store.dispatch('popups/open', {name: popup, request: item});
            }

        },
        openConceptGallery(item){
            this.showConceptGallery=false;
            this.showConceptGallery=true;
            this.$nextTick(()=>{
                this.$refs.viewerConcept.showViewerConcept(0);
            });
        },
        openContractGallery(item){
            if(!item.contract){
                this.loadContactGallery=true;
                this.$store.dispatch('popups/getData',{id: item.props.contractId, modalData: 'MeasureDetail'}).then(response => {
                    this.loadContactGallery = false;
                    this.$store.commit('requests/setItemProps', {item: this.item, props: {
                            contract: response.items,
                        }});
                    this.$nextTick(()=>{
                        this.openPopup(this.item, this.popups.docsFiles.name);
                    });
                });
            } else {
                this.$nextTick(()=>{
                    this.openPopup(this.item, this.popups.docsFiles.name);
                });
            }

        },
        showChangeCommand(){
            return (this.user.groupCode == 'R' || this.user.groupCode == 'K')
                && this.hasState([
                    'request_new_*,',
                    'request_new_metering_empty',
                    'request_think_*',
                    'request_wait_contract_*',
                    'request_check_contract_*',
                    'request_pre_approved_*',
                    'request_wait_interview_*',
                    'measure_queue_*',
                    'measure_confirmed_*',
                    'measure_in_work_*',
                    'measure_put_aside_*',
                    'concepts_in_work_*',
                    'concepts_approved_*',
                    'concepts_accepted_*'
                ])  && !this.isGiveDesign;
        },
        showChangeZ(){
            return (this.user.groupCode == 'R' || this.user.groupCode == 'K')
                && (this.hasState(['measure_confirmed_*']) || this.hasState(['measure_queue_*']) )
                && !this.isGiveDesign;
        },
        openChangeZ(item){
            this.$store.dispatch('popups/getData',{id: item.extProps.team.id, modalData: 'AllWorkers'}).then(response => {
                this.$store.commit('requests/setAllWorkers', response.items);
                this.$store.dispatch('popups/open', {name: this.popups.changeZ.name, request: item});
            });
        },
        openChangeCommand(item){
            this.$store.dispatch('popups/getData',{id: item.extProps.team.id, modalData: 'AllWorkers'}).then(response => {
                this.$store.commit('requests/setAllWorkers', response.items);
                this.$store.dispatch('popups/open', {name: this.popups.changeCommand.name, request: item});
            });
        },
    }
}
</script>

<style scoped>

</style>