<template>
    <div class="route-block text-center">
        <div class="hand">
            <img src="@/assets/img/hand.svg" alt="">
        </div>
        <div class="text-bold text-size-xxxl mt16">
            {{fromName}} – это уже <router-link :to="toLink" class="text-uppercase">{{toName}}</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RouteBlock",
        props: {
            fromName: '',
            toName: '',
            toLink: ''
        },
        computed: {
        },
        methods:{

        }
    }
</script>
