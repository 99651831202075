<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    name: "PurposeSet",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :add-class-wrap="'details-tooltip'"
        :isChangeablePos="false"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold">
                назначен «{{ item.props.autosale_master.title }}» {{
                    item.props.autosale_master_set_date
                }}</a>
        </template>
        <template v-slot:tooltipContent>
            <div v-if="item.props.is_msk === 'Y'" class="">
                <span v-html="convertNewLine( item.props.statusComment )"></span> <br> <br>
                {{ item.props.statusChangeUser.lastName }} {{ item.props.statusChangeUser.firstName }}
                ({{ item.props.statusChangeUser.groupCode + item.props.statusChangeUser.number }}),
                {{ item.props.statusDate }}
            </div>
            <div v-else>Автоматически, потому что в Питере у нас только один партнёр <br><br>
                {{ item.props.autosale_master_set_date }}
            </div>
        </template>
    </tooltip>
</template>

<style scoped>

</style>