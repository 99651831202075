<template>
    <div>
        <div class="popup__header">
            <div class="title">Обычный замер</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-color-grey text-uppercase text-size-xs">
                Дата замера
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 text-uppercase">
                {{ item.props.dateProject.cardDate }}
            </div>
            <div class="text-color-grey text-size-xs dif">
                <span class="mr8">{{ item.props.dateProject.cardTime }}</span>
                <tooltip
                        v-if="item.putAside == options.putAsideYes"
                        :direction="'right-center-direction'"
                        :is-btn-close="false"
                        :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <span class="link-color text-uppercase">
                            Готовы раньше
                        </span>
                    </template>

                    <template v-slot:tooltipContent>
                        <span v-html="convertNewLine(item.props.putAsideComment)"></span>
                    </template>
                </tooltip>
            </div>

            <hr>

            <div class="text-color-grey text-uppercase text-size-xs">
                Замерщик
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 dif" v-if="item.extProps.team">
                <span class="mr8">{{ item.extProps.team.users.measurer.lastName }} {{ item.extProps.team.users.measurer.firstName }}</span>
                <span class="member p-none" :class="item.extProps.team.users.measurer.char">{{ item.extProps.team.users.measurer.groupCode }}{{ item.extProps.team.users.measurer.number }}</span>
            </div>

            <hr>

            <div class="text-color-grey text-uppercase text-size-xs">
                Налогообложение
            </div>
            <div class="dif mt12 no-error-icon">
                <tumbler @change="removeError(form.option)" :options="typeTax" :elem-width="101" v-model="form.option.value" :error="form.option.error"/>
                <div class="ml16">
                    <tooltip
                            :direction="'top-right-direction'"
                            :is-btn-close="false"
                            :add-style="{width: 290 + 'px'}">
                        <template v-slot:tooltipBtn class="text-uppercase link-color text-size-xs ml16">
                            <span class="text-uppercase link-color text-size-xs">как выбрать?</span>
                        </template>

                        <template v-slot:tooltipContent>
                          <div class="text-size-m">
                            <span>
                                <strong>«Патент Москва»</strong> выбирается при замере в Москве.
                            </span>
                            <br><br>
                            <span class="mt8">
                                <strong>«Патент МО»</strong> выбирается при замере МО и при дистанционном замере.
                            </span>
                            <br><br>
                            <span class="mt8">
                                <strong>«УСН 6%»</strong> выбирается при обычном замере за пределами МО, когда замерщик едет в командировку.
                            </span>
                          </div>
                        </template>
                    </tooltip>
                </div>
            </div>
            <hr>

            <div class="mt16">
                <input @change="removeError(form.checkbox)" v-model="form.checkbox.value" :class="{error:form.checkbox.error, 'repeat-error': form.checkbox.repeatError}" type="checkbox" id="checkbox-standard-measure">
                <label for="checkbox-standard-measure">Я знаю, что систему налогообложения можно выбрать только 1 раз</label>
            </div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    предварительно одобрить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен<br>в «Предварительно одобрены»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import { convertNewLine } from "@/helpers/util/helper";

    export default {
        name: "StandardMeasure",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(this.form[field].value.toString().length == 0 || this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'StandardMeasure',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form){
                        if( field == 'option'){
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id})
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.standardMeasure.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox: {
                        value: false,
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {

        },
        watch: {
            'form.checkbox.error'(val,oldVal){
                if(oldVal){
                    this.form.checkbox.repeatError = true;
                }
            },
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
                typeTax: state => state.options.items.typeTax ? state.options.items.typeTax : [],
            }),
        },
    }
</script>

<style scoped>

</style>
