<template>
    <div>
        <div class="popup__header">
            <div class="title">Взять объект в работу</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
            <div class="dif">
                <div class="" style="width: 266px">
                    <input-styled v-model="form.objectName.value"
                                      name="objectName"
                                      :placeholder="'Название объекта'"
                                      :placeholder-input="'Задайте название объекта'"
                                      :isError="form.objectName.error"
                                      :errorText="form.objectName.errorText"
                                      :autoFocus="true"
                                      @focus="removeError(form.objectName)"
                                      @input="removeError(form.objectName)"
                                      @keyup="objectName($event.target.value)"></input-styled>
                </div>
                <div v-if="form.objectName.value===''" :class="{'text-color-red': form.objectName.error}" class="text-size-xs text-color-grey text-uppercase ml8">
                    Название должно быть уникальным
                </div>
                <div v-else-if="nameValid === true" class="text-size-xs text-color-grey text-uppercase ml8 dif">
                    <span class="i i-ok2 i--green mr4"></span>Название уникальное
                </div>
                <div v-else-if="nameShow.length>0"  :class="{'text-color-red': form.objectName.error}" class="text-size-xs text-color-grey ml8" style="width: 202px">
                    {{nameShow}}
                </div>
            </div>
            <hr>
            <div class="">
                <calendar
                    @update:modelValue="updateSelectedDateMeasure($event)"
                    :putAsideYes="form.option.id == options.putAsideYes"
                    :typeMetering="item.props.typeMetering==''?false:item.props.typeMetering"
                    v-if="calendarShow"
                    @switchChanged="setUrgent"
                    ref="calendarDateMeasure"
                    :disabled-dates="offDates"
					:is-urgent="true"
                    v-model="form.selectedDateMeasure" >
                </calendar>
            </div>
            <div class="mt24">
                <tumbler @change="form.tumbler.error=false" :error="form.tumbler.error" :options="early" :elem-width="114" v-model="form.option"/>
            </div>
            <div class="mt24">
                <textarea-field :class="'full'"
                                v-model="form.text1.value"
                                :error-text="''"
                                :label="'Комментарий'"
                                :is-error="form.text1.error"
                                @focus="form.text1.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="calendar__close" :class="{'--showed': !calendarFirstHidden}" @click="calendarFirstHidden = true">
                <div class="up">
                    <span class="i i-arrow2"></span>
                </div>
            </div>
            <div class="">
                <calendar
                    v-if="calendarShow"
                    @update:modelValue="updateSelectedDateCall($event)"
                    :measureDate="form.selectedDateMeasure"
                    :disabled-dates="[]"
                    :is-call="true"
                    @switchChanged="setUrgent"
                    v-model="form.selectedDateCall"
                    :calendar-hidden="calendarSecondHidden"
                    @calendarshow="calendarSecondHidden = false">
                </calendar>
            </div>
            <div class="calendar__close" :class="{'--showed': !calendarSecondHidden}" @click="calendarSecondHidden = true">
                <div class="up">
                    <span class="i i-arrow2"></span>
                </div>
            </div>
            <div class="mb40 mt40">
                <textarea-field :class="'full'"
                                v-model="form.text2.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.text2.error"
                                @focus="form.text2.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
			<div :class="{'disabled': !item.props.isNewPrapay}">
				<input-select-time @toggled="form.prepaymentTime.error = false" :title="'время на внесение предоплаты'" v-model="form.prepaymentTime.value" :error="form.prepaymentTime.error"/>
			</div>
			<hr>
            <div class="mt40" :class="{'disabled': !item.props.isNewPrapay}">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Заказчик в курсе, что неоплаченная заявка удаляется автоматически</label>
            </div>
            <hr>
            <span v-if="item.props.isNewPrapay" class="text-italic text-color-grey">
                Заказчику будет отправлено СМС и Email со ссылкой на форму для внесения предоплаты 5000 ₽.<br><br>

                После оплаты заказчику будет отправлено СМС с названием объекта, а так же с датой и временем замера. Кроме этого, там будет информация, что будет дополнительный созвон за 10 дн. до замера.
            </span>
            <span v-else class="text-italic text-color-grey">
                Эта заявка оформлена по старой логике предоплаты, так что после подтверждения она сразу попадёт в «Замер / В очереди».<br><br>

                Заказчику будет отправлено СМС с датой замера и созвона с замерщиком.
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="form.btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Ждём оплату»<br>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
	import InputSelectTime from '@/components/forms/InputSelectTime.vue';
    import Calendar from '@/components/Calendar.vue';
    export default {
        name: "Approve",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField,
            Calendar,
            InputStyled,
			InputSelectTime,
        },
        methods: {
            updateSelectedDateCall($event){
                this.updatedDateCall = true;
            },
            updateSelectedDateMeasure($event){

                let dd = new Date($event.date.replaceAll('.','-')),
                    curdate = new Date();

                dd.setDate(dd.getDate() - 10);
                if(dd.getTime() < curdate.getTime()){
                    dd = curdate;
                }

                this.updatedDateMeasure = true;
                this.form.selectedDateCall.date = dd.getFullYear()+'.'+(dd.getMonth()+1)+'.'+dd.getDate();
                this.checkUrgent();
            },
            objectName(val){
                if(val.length > 2){
                    if(this.timeout){clearTimeout(this.timeout);}
                    this.timeout = setTimeout(() => {
                        this.$store.dispatch('popups/getData',{name: val, id: this.item.id, modalData: 'ObjectNameBusy'}).then(response => {
                            this.nameShow = response.items;
                            this.nameValid = response.items === false;
                        });
                    }, 50);
                }
                else{
                    this.nameValid = false;
                    this.nameShow = false;
                }
            },
            removeError(form) {
                form.error = false;
            },
            setDate(val){
                this.selectedDate = val;
            },
            send(){
                let error = false;
                this.form.tumbler.error = false;
                this.form.text1.error = false;
                this.form.text2.error = false;
                this.form.objectName.error = false;

                if(Object.keys(this.form.option).length == 0){
                    this.calendarFirstHidden = false;
                    setTimeout(() => {
                        this.form.tumbler.error = true;
                    });

                    error = true;
                }
                if(this.form.text1.value.length == 0){
                    setTimeout(() => {
                        this.form.text1.error = true;
                    });

                    error = true;
                }
                if(this.form.text2.value.length == 0){
                    this.calendarFirstHidden = false;
                    setTimeout(() => {
                        this.form.text2.error = true;
                    });

                    error = true;
                }
                if(this.form.objectName.value.length == 0 || this.nameValid !== true){
                    setTimeout(() => {
                        this.form.objectName.error = true;
                    });
                    error = true;
                }
                if(!this.form.checkbox1.value && this.item.props.isNewPrapay){
                    setTimeout(() => {
                        this.form.checkbox1.error = true;
                    });
                    error = true;
                }
                if(!this.form.prepaymentTime.value && this.item.props.isNewPrapay){
                    this.form.prepaymentTime.error = true;
                    error = true;
                }
                if(!error && this.nameValid === true){
                    this.form.btnType = 'load';
                    let params =  {
                        action: 'SetApprove',
                        id: this.item.id,
                        params: {
                            selectedDateMeasure: this.form.selectedDateMeasure.date+' '+this.form.selectedDateMeasure.time.hours+':'+this.form.selectedDateMeasure.time.minutes,
                            selectedDateCall: this.form.selectedDateCall.date+' '+this.form.selectedDateCall.time.hours+':'+this.form.selectedDateCall.time.minutes,
                            name: this.form.objectName.value,
                            isEarlier: this.form.option.id,
                            isEarlierComment: this.form.text1.value,
                            characterKR: this.form.text2.value,
                            prepaymentTime: this.form.prepaymentTime.value,
                            switchUrgent: this.form.switchUrgent.value ? 'Y':'N',
                        }
                    }

                    if (this.form.selectedDateCall.date === '2020.12.12') {
                        //если дата равна дате по умолчанию, то выдать уведомление
                        this.$store.commit('popups/open', {name: 'errorModal'});

                        let paramsLog = params;

                        paramsLog['form'] = this.form;
                        paramsLog['early'] = this.early;
                        paramsLog['calendarFirstHidden'] = this.calendarFirstHidden;
                        paramsLog['calendarSecondHidden'] = this.calendarSecondHidden;
                        paramsLog['calendarShow'] = this.calendarShow;
                        paramsLog['timeout'] = this.timeout;
                        paramsLog['nameValid'] = this.nameValid;
                        paramsLog['updatedDateMeasure'] = this.updatedDateMeasure;
                        paramsLog['updatedDateCall'] = this.updatedDateCall;
                        paramsLog['nameShow'] = this.nameShow;

                        //отправить лог
                        this.$store.dispatch('requests/sendLog', paramsLog);
                        this.$store.commit('popups/close', {name: this.popups.approve.name});

                    } else {
                        this.$store.dispatch('requests/actionRequest', params).then((data) => {
                            this.form.btnType = 'ok';
                            this.$store.commit('requests/deleteRequest', {id: this.item.id});
                            if (data.data.ext_data) {
                                this.$store.commit('popups/close', {name: this.popups.approve.name});
                                this.$store.commit('popups/open', {
                                    name: this.popups.availableDate.name,
                                    request: data.data.ext_data
                                });
                            } else {
                                setTimeout(() => {
                                    this.$store.commit('popups/close', {name: this.popups.approve.name});
                                }, this.options.delayPopupClose);
                            }
                        }, () => {
                        });
                    }
                }
            },
            setUrgent(value){
                this.form.switchUrgent.value = value;
            },
            checkUrgent(){
                this.$nextTick(()=>{
                    if(this.$refs.calendarDateMeasure){
                        if(this.daysCount <= 10){
                            this.$refs.calendarDateMeasure.switchSet(true)
                        } else {
                            this.$refs.calendarDateMeasure.switchSet(false)
                        }
                    }
                })

            },

        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                    selectedDateMeasure: {date: '2020.12.12', time: {hours: 12, minutes: 30}},
                    selectedDateCall: {date: '2020.12.12', time: {hours: 12, minutes: 30}},
                    option: {},
                    tumbler: {
                        error: false,
                    },
                    objectName: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    text1: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    text2: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    prepaymentTime:{
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    switchUrgent: {
                        value: false,
                        error: false,
                        repeatError: false,
                    }
                },
                early: [
                    {name: 'Готовы раньше', id: '168'},
                    {name: 'Не готовы раньше', id: '185'},
                ],
                dates: [],
                calendarFirstHidden: true,
                calendarSecondHidden: true,
                calendarShow: false,
                timeout: false,
                nameValid: false,
                updatedDateMeasure: false,
                updatedDateCall: false,
                nameShow: '',
				time: '',
            }
        },
        mounted() {
            if(this.item){
                this.form.selectedDateMeasure = {date: this.item.props.dateProject.calendarDate, time: {hours: this.item.props.dateProject.calendarHour, minutes: this.item.props.dateProject.calendarMinute}};

                let dd = new Date(this.item.props.dateProject.calendarDate.replaceAll('.','-')),
                    curdate = new Date();
                dd.setDate(dd.getDate() - 10);
                if(dd.getTime() < curdate.getTime()){
                    dd = curdate;
                }
                this.form.selectedDateCall = {date: dd.getFullYear()+'.'+(dd.getMonth()+1)+'.'+dd.getDate(), time: {hours: 11, minutes: '00'}};

                if(this.item.props.putAside !== false){
                    this.form.option = {name:'', id: this.item.props.putAside};
                }
                this.form.text1.value = this.item.props.putAsideComment;
                //this.form.objectName.value = this.item.name;
                this.form.text2.value = this.item.props.characterKR;
                this.calendarShow = true;
                this.$store.dispatch('popups/getData',{id: this.item.id, modalData: 'CalendarDays'}).then(response => {
                    this.dates = response.items.days;
                });
                this.checkUrgent();
            }
        },
        computed: {
            offDates(){
                let arr = this.dates.slice(0, this.dates.length);
                return arr;
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
            daysCount(){
                let today = new Date();
                let date = this.form.selectedDateMeasure.date.split('.');
                let choosenDate = new Date(date[0], date[1] - 1, date[2]);
                return ((choosenDate - today.getTime()) / (1000 * 3600 * 24)).toFixed(0)
            },
        },
    }
</script>

<style scoped>
    .popup__content .disabled {
        opacity: 0.3;
        pointer-events:none;
    }
</style>
