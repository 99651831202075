<template>
    <transition name="fade-popup">
        <div class="popup__full-size" v-if="show">
            <div class="full-size__header">
                <div class="inner-wrapper">
                    <div class="">
                        <a href="javascript:void(0);" class="back-btn" @click="closeMeasureDetail">
                            <span class="i i-arrow3"></span>
                            <span>выйти без сохранения</span>
                        </a>
                    </div>
                    <div class="df">
                        <span class="txt">
                            {{ item.props.passedTimeDesc }}
                        </span>
                        <submit-btn class="btn--mini ml16"  @click="saveForm()" tag="button" :type="btnType">
                            сохранить изменения
                        </submit-btn>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="main-title">
                    <div class="main-title__left">
                        <div class="h1">Дистанционный замер №{{ item.name }}</div>
                    </div>

                    <div class="main-title__right">
                        <span class="i i-info mr4"></span>
                        <span class="t">Дистанционный замер, по которому необходимо<br>принять решение о возможности разработки проекта</span>
                    </div>
                </div>

                <div class="df df-space-between">
                    <div class="full-size__elem">
                        <div class="head">
                            Данные для договора
                        </div>
                        <div class="form">
                            <div class="" style="width: 244px">
                                <input-styled v-model="form.clientSurname.value"
                                              name="clientSurname"
                                              :placeholder="'Фамилия'"
                                              :placeholder-input="'Иванов'"
                                              :isError="form.clientSurname.error"
                                              @focus="removeError(form.clientSurname)"
                                              @input="removeError(form.clientSurname)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 244px">
                                <input-styled v-model="form.clientName.value"
                                              name="clientName"
                                              :placeholder="'Имя'"
                                              :placeholder-input="'Иван'"
                                              @focus="removeError(form.clientName)"
                                              @input="removeError(form.clientName)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 244px">
                                <input-styled v-model="form.clientPatronymic.value"
                                              name="clientPatronymic"
                                              :placeholder="'Отчество'"
                                              :placeholder-input="'Иванович'"
                                              :isError="form.clientPatronymic.error"
                                              @focus="removeError(form.clientPatronymic)"
                                              @input="removeError(form.clientPatronymic)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 244px">
                                <input-styled v-model="form.clientPhone.value"
                                              name="clientPhone"
                                              :is-block-arrow-phone="true"
                                              :placeholder="'Телефон'"
                                              :disabled="true"
                                              :placeholder-input="'Укажите телефон'"
                                              :isValidate="true"
                                              :isError="form.clientPhone.error"
                                              @focus="removeError(form.clientPhone)"
                                              @input="removeError(form.clientPhone)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 244px">
                                <input-styled v-model="form.clientEmail.value"
                                              name="clientEmail"
                                              :placeholder="'Email'"
                                              :placeholder-input="'Укажите почту'"
                                              :isError="form.clientEmail.error"
                                              @focus="removeError(form.clientEmail)"
                                              @input="removeError(form.clientEmail)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 100%">
                                <input-styled v-model="form.objectAddress.value"
                                              name="objectAddress"
                                              :placeholder="'Адрес объекта'"
                                              :placeholder-input="'Укажите адрес объекта'"
                                              :isError="form.objectAddress.error"
                                              @focus="removeError(form.objectAddress)"
                                              @input="removeError(form.objectAddress)"></input-styled>
                            </div>
                            <div class="mt24" style="width: 100%">
                                <input-styled v-model="form.registrationAddress.value"
                                              name="registrationAddress"
                                              :placeholder="'Адрес регистрации'"
                                              :placeholder-input="'Укажите адрес регистрации'"
                                              :isError="form.registrationAddress.error"
                                              @focus="removeError(form.registrationAddress)"
                                              @input="removeError(form.registrationAddress)"></input-styled>
                            </div>
                        </div>
                    </div>
                    <div class="full-size__elem" >
                        <div class="head">
                            Документы и файлы
                        </div>
                        <div class="files" v-if="item.props.contract.props">

                            <!-- <div class="file-wrap">
                                <div class="file load">
                                    <div class="preloader"></div>
                                    <span class="name">
                                        техника.png
                                    </span>
                                </div>
                            </div>

                            <div class="file-wrap">
                                <div class="file error">
                                    <div class="i i-close3"></div>
                                    <div class="i i-warning i--red"></div>
                                    <span class="text-bold text-size-s mt8">Недопустимый<br>формат!</span>
                                    <span class="name">
                                        техника.png
                                    </span>
                                </div>
                            </div> -->
                            <div  @click="showViewer(index)" v-for="(file, index) in item.props.contract.props.files" :class="{'hidden': index > 8}" class="file-wrap">
                                <!-- Изображения  -->
                                <a class="file img" href="javascript:void(0);" v-if="file.type == 'img'"  :style="{'background-image':' url(\'' + appPath + file.icon.url +  '\')'}" :class="{more: index == 8 && item.props.contract.props.files.length > 9}">
                                    <div class="i i-fullscreen"></div>
                                    <span class="more" v-if="index == 8 && item.props.contract.props.files.length > 9">Ещё {{item.props.contract.props.files.length - 8}}</span>
                                </a>

                                <!-- Документы-->
                                <a class="file doc" href="javascript:void(0);" v-else :class="{more: index == 8 && files.length > 8}">
                                    <div class="doc__type">
                                        {{file.docType}}
                                    </div>
                                    <span class="name">
                                        {{file.name}}
                                    </span>
                                    <span class="more" v-if="index == 8 && item.props.contract.props.files.length > 9">Ещё {{item.props.contract.props.files.length - 8}}</span>
                                </a>
                            </div>
                        </div>
                        <div v-else class="files"></div>
                    </div>
                </div>
            </div>

            <div  v-if="item.props.contract.props" class="fullscreen-viewer" ref="viewer"
             @keyup.space="slideNext" @keyup.esc="closeViewer">
                <div class="fullscreen-viewer__inner" :class="{'nav-hovered-prev': hoveredPrev, 'nav-hovered-next': hoveredNext}">
                    <splide class="fullscreen-viewer__carousel" ref="viewerSlider" :infiniteScroll="true">
                        <splide-slide :key="index" v-for="(file,index) in item.props.contract.props.files">
                            <!-- Изображения -->
                            <div class="item"  v-if="file.type == 'img'" >
                                <a target="_blank" :href="appPath + file.original.url"><img :src="appPath + file.resize.url" alt=""></a>
                                <div class="inner-nav">
                                    <div class="prev" @click="slidePrev" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                                    <div class="next" @click="slideNext"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                                </div>
                            </div>

                            <!-- Документы -->
                            <div class="item" v-else>
                                <a download target="_blank" class="file doc" :href="appPath + file.original.url">
                                    <div class="doc__type">
                                        {{ file.docType}}
                                    </div>
                                    <span class="name">
                                        {{ file.name }}
                                    </span>
                                </a>
                                <div class="inner-nav">
                                    <div class="prev" @click="slidePrev" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                                    <div class="next" @click="slideNext"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                                </div>
                            </div>
                        </splide-slide>
                    </splide>
                </div>
                <div role="button" class="fullscreen-viewer__close" @click="closeViewer"></div>
                <div class="fullscreen-viewer__navigation">
                    <button type="button" class="prev" @click="slidePrev"></button>
                    <button type="button" class="next" @click="slideNext"></button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapState} from 'vuex'
import Tooltip from '@/components/Tooltip.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import InputStyled from '@/components/forms/InputStyled.vue';
import validate from '@/helpers/validate';
import {number_format} from '@/helpers/formatted';
// import {Hooper, Slide, Navigation as HooperNavigation} from 'hooper';
    export default {
        name: "MeasureDetail",
        components: {
            Tooltip,
            SubmitBtn,
            InputStyled,
            Splide,
            SplideSlide,
            // Hooper,
            // Slide,
            // HooperNavigation,
        },
        emits: ['splide:hidden', 'splide:move','splide:arrows:updated','splide:pagination:updated', 'splide:moved','splide:active','splide:visible','splide:inactive'],
        methods: {
            removeError(form) {
                form.error = false;
            },
            setDate(val){
                this.selectedDate = val;
            },
            closeMeasureDetail(){
                this.$store.commit('popups/close', {name: this.popups.measureDetail.name});
            },
            closeViewer() {
                if (this.isShowedViewer) {
                    this.$refs.viewer.classList.add('viewer-transition');
                    this.$refs.viewer.classList.remove('showed');
                    document.getElementsByTagName('body')[0].classList.remove("noscroll");
                    document.getElementsByClassName('popup__full-size')[0].classList.remove("noscroll");
                    let self = this;
                    setTimeout(function () {
                        if (self.$refs.viewer) {
                            self.$refs.viewer.classList.remove('viewer-transition');
                        }
                        if (self.$refs.viewerHooper) {
                            self.$refs.viewerHooper.restart();
                        }
                        self.isShowedViewer = false;
                    }, 200);
                }
            },
            slideNext() {
                this.$refs.viewerSlider.go( '+' );
            },
            slidePrev() {
                this.$refs.viewerSlider.go( '-' );
            },
            showViewer(index) {
                this.$refs.viewerSlider.go(index);
                this.$refs.viewer.classList.add('viewer-transition');
                this.$refs.viewer.querySelector('.splide__track').setAttribute('tabindex', 0);
                let self = this;
                setTimeout(function () {
                    self.$refs.viewer.classList.remove('viewer-transition');
                }, 400);
                setTimeout(function () {
                    self.$refs.viewer.classList.add('showed');
                    document.getElementsByTagName('body')[0].classList.add("noscroll");
                    document.getElementsByClassName('popup__full-size')[0].classList.add("noscroll");
                    self.isShowedViewer = true;
                    self.$refs.viewer.querySelector('.splide__track').focus();
                }, 10);
            },
            saveForm(){
                let errorField = false;
                for(let field in this.form){
                    errorField = false;
                    if(this.form[field].value.length == 0){
                        errorField = true;
                    }
                    /*if(field == 'clientPhone' && !validate.isPhone(this.form[field].value)){
                        errorField = true;
                    }*/
                    if(field == 'clientEmail' && !validate.isEmail(this.form[field].value)){
                        errorField = true;
                    }
                    if(errorField){
                        this.form[field].error = true;
                    }
                }
                if(!errorField) {
                    let params = {
                        action: 'SetContractProps',
                        id: this.item.id,
                        params: {
                            contractId: this.item.props.contractId
                        }
                    };
                    for(let field in this.form){
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(response => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItem', {item: this.item, returnItem: response.data});
                        setTimeout(()=>{
                            this.btnType = 'normal';
                            this.$store.commit('popups/close', {name: this.popups.measureDetail.name});
                        }, this.options.delayPopupClose);
                    });
                }
            },
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
        },
        props: {
            show: {
                default: function(){
                    return false;
                }
            }
        },
        data: function () {
            return {
                appPath: process.env.VUE_APP_PATH_BACK,
                viewerShow: false,
                hoveredPrev: false,
                hoveredNext: false,
                currentSlide: '',
                isShowedViewer: false,
                btnType: 'normal',

                form: {
                    clientSurname: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    clientName: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    clientPatronymic: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    clientPhone: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    clientEmail: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    objectAddress: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    registrationAddress: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },
            }
        },
        mounted() {

        },
        watch: {
            show(val){
                if(val == true && this.item.props.contract && this.item.props.contract.props){
                    this.form.clientSurname.value = this.item.props.contract.props.clientSurname;
                    this.form.clientName.value = this.item.props.contract.props.clientName;
                    this.form.clientPatronymic.value = this.item.props.contract.props.clientPatronymic;
                    this.form.clientEmail.value = this.item.props.contract.props.clientEmail;
                    this.form.clientPhone.value = this.item.props.contract.props.clientPhone;
                    this.form.objectAddress.value = this.item.props.contract.props.objectAddress;
                    this.form.registrationAddress.value = this.item.props.contract.props.registrationAddress;

                }
            }
        },
        computed: {
            offDates(){
                return this.dates.slice(0, this.dates.length);
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
                masks: state => state.masks.items,
            }),
        },
    }
</script>

<style scoped>
    .fade-popup-enter-active, .fade-popup-leave-active {
        transition: opacity .15s;
    }
    .fade-popup-enter, .fade-popup-leave-to {
        opacity: 0;
    }
</style>
