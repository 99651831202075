<template>
    <div>
        <div class="popup__header">
            <div class="title">Вернуть в «{{ item.props.restoreStatusTitle }}»</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «{{ item.props.restoreStatusTitle }}»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubmitBtn from '@/components/forms/SubmitBtn.vue';

export default {
    name: "RestoreStatus",
    components: {
        SubmitBtn,
    },
    methods: {
        send() {
            let error = false;

            if (!error) {
                let params = {
                    action: 'RestoreStatus',
                    id: this.item.id,
                    params: {
                        code: this.item.props.restoreStatusCode
                    }
                };

                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id})
                    setTimeout(() => {
                        this.$store.commit('popups/close', {name: this.popups.restoreStatus.name});
                    }, this.options.delayPopupClose);
                }, () => {
                });
            }
        }
    },
    data: function () {
        return {
            btnType: 'normal',
            titles: {}
        }
    },
    mounted() {
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
}
</script>

<style scoped>

</style>
