<template>
    <div>
        <div class="popup__header">
            <div class="title">Принять концепцию</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="download-concept mb40">
                <div class="files">
                    <a target="_blank" :href="appPath + item.props.conceptFile.path" class="file img" :style="{'background-image':' url(\'' + appPath + item.props.conceptFile.icon +  '\')'}"></a>
                </div>
            </div>
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от замерщика ({{ item.extProps.team.users.measurer.lastName }} {{ item.extProps.team.users.measurer.firstName }})
            </div>
            <div class="mt8" v-html="convertNewLine(item.props.characterZ)"></div>

            <hr>

            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="'Характеристика от координатора и руководителя'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Напишите совместную характеристику на заказчика"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Файл с концепцией и фото объекта находятся на Google-диске</label>
            </div> 
            <hr>
            <div class="text-size-l text-bold mb12">Будем предлагать ремонт?</div>
            <tumbler @change="removeError(form.option)" :options="optionsWillOffer" :widthAuto="true" v-model="form.option.value" :error="form.option.error"/>
            <template v-if="form.option.value && form.option.value.id !== 279">
                <textarea-field :class="'full mt40'"
                    v-model="form.comment.value"
                    :error-text="''"
                    :label="'Прокомментируйте своё решение'"
                    :is-error="form.comment.error"
                    :auto-focus="true"
                    @focus="form.text.error = false"
                    placeholder="Напишите подробный комментарий..."
                    :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </template>
            <div class="text-italic text-color-grey mt40">
                Заказчику будет отправлена ссылка на специальную страницу, где он посмотрит видеообращение Алексея Земскова, а затем совершит определённые действия...
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Принятые»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import { convertNewLine } from "@/helpers/util/helper";
    import Tumbler from '../../Tumbler.vue';

    export default {
        name: "AcceptConcept",
        components: {
            SubmitBtn,
            TextareaField,
            Tumbler,
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    this.form[field].error = false;
                    let errorField = false;
                    if(field === 'text' && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if(field === 'checkbox1' && this.form[field].value === false){
                        errorField = true;
                    }
                    if(field === 'option' && this.form[field].value === false){
                        errorField = true;
                    }
                    if(field === 'comment' && (this.form.option.value.id === 277 || this.form.option.value.id === 278) && this.form[field].value.length === 0){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'AcceptConcept',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field === 'file' || field === 'option'){
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.acceptConcept.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                appPath: process.env.VUE_APP_PATH_BACK,
                btnType: 'normal',
                optionsWillOffer: [
                    {id: 277, name: 'Да, будем' },
                    {id: 278, name: 'Нет, не будем' },
                    {id: 279, name: 'Регионы' },
                ],
                form: {
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    comment: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            this.form.text.value = this.item.props.characterKR;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
