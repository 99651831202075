<template>
    <div class="inp-s-wrap full" :class="{ '--icon': isEdit || isValidate || isClearBtn, active: showLabel, 'inp-disabled': disabled, 'repeat-error':  repeatError }">
        <span class="inp-s-label" :class="{ error: isError, 'repeat-error':  repeatError }">{{ placeholder }}</span>

        <input :type="type"
            ref="field"
            :id="inputId"
            @keyup.enter="enter()"
            @focus="setFocus(true)"
            @blur="setFocus(false)"
            class="inp-s"
            :placeholder="showPlaceholder ? placeholderInput : ''"
            :class="{ error: isError,disabled:disabled, 'repeat-error':  repeatError }"
            :disabled="disabled"
            :name="nameInput"
            :value="modelValue"
            v-mask="maskPrice"
            :autocomplete="fieldAutocomplete"
            @input="updateValue"
        >

        <span class="field-clear__btn" v-if="isClearBtn" @click="clear" >
            <span class="i i-close2"></span>
        </span>

        <span class="inp-s-error" v-if="isError">{{ errorText }}</span>

        <span class="inp-s-icon">
            <span class="i i-pencil2 --edit" v-if="isEdit" @click="edit"></span>
            <span class="i i-ok2" v-if="isValidate" @click="check"></span>
        </span>

    </div>
</template>

<script>
    import {number_format} from '@/helpers/formatted.js';

    export default {
        name: "InputPrice",
        props: {
            modelValue: {default: ''},
            max: {default: 9999999},
            placeholder: {default: ''},
            placeholderInput: {default: ''},
            inputId: {default: ''},
            disabled: {default: false},
            name: {default: 'name'},
            isError: {default: false},
            isEdit: {default: false},
            isValidate: {default: false},
            errorText: {default: ''},
            mask: {default: ''},
            isClearBtn: {default: false},
            fieldAutocomplete: {default: 'nope'},
            autoFocus: {default: false},
        },
        data: function () {
            return {
                type: 'text',
                focus: false,
                showPlaceholder: false,
                nameInput: '',
                repeatError: false,
            }
        },
        computed: {
            showLabel() {
                return this.focus || this.modelValue.toString().length > 0;
            },
            maskPrice() {
                return this.$store.getters['masks/price']
            }
        },
        mounted(){
            if(this.name !== 'name'){
                this.nameInput = this.name;
            } else {
                this.nameInput = Math.random().toString(36).substr(2, 5);
            }
            if(this.autoFocus === true) {
                this.inputFocus('field');
            }
        },
        emits: ['input', 'enter', 'check', 'focus', 'blur', 'edit', 'update:modelValue','paste'],
        watch:{
            isError(val,oldVal){
                if(oldVal){
                    this.repeatError = true;
                }
            }
        },
        methods: {
            setCursor(node,pos){
                if(!node){
                    return false;
                }else if(node.createTextRange){
                    var textRange = node.createTextRange();
                    textRange.collapse(true);
                    textRange.moveEnd(pos);
                    textRange.moveStart(pos);
                    textRange.select();
                    return true;
                }else if(node.setSelectionRange){
                    node.setSelectionRange(pos,pos);
                    return true;
                }

            },
            updateValue: function (event) {
                this.$emit('update:modelValue', +event.target.value < this.max ?  event.target.value : this.max.toString());
                this.$emit('input');
            },
            enter: function () {
                this.$emit('enter');
            },
            check: function () {
                this.$emit('check')
            },
            clear: function() {
                this.$refs.field.focus();
                this.$emit('input', '');
            },
            setFocus(value) {
                let self = this
                value = value || false;
                this.focus = value;
                this.repeatError = false;
                if(value){
                    this.$emit('focus');
                    setTimeout(function () {
                        self.showPlaceholder = true;
                    }, 50);
                    if(this.modelValue.length < 1){
                        setTimeout(function(){
                            self.setCursor(self.$refs.field, 0);
                        }, 100);
                    }
                } else {
                    this.$refs.field.value = this.modelValue.trim()
                    this.$emit('blur');
                    this.showPlaceholder = false;
                }
            },
            edit: function () {
                this.$emit('edit');
            },
            inputFocus(ref) {
                if(!!this.$refs[ref]){
                    this.$nextTick(() => {this.$refs[ref].focus()});
                }
            },
        }
    }
</script>

<style>

</style>
