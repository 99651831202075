<template>
    <div>
        <div class="popup__header">
            <div class="title">Адрес объекта</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full mt24'"
                            v-model="form.address.value"
                            :error-text="''"
                            :label="'Адрес объекта'"
                            :auto-focus="true"
                            :is-error="form.address.error"
                            @focus="form.address.error = false"
                            placeholder="Укажите адрес объекта"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            <div class="df df-space-between mt40">
                <submit-btn  @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    export default {
        name: "ObjectAddr",
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                this.form.address.error = false;
                if(this.form.address.value.length == 0){
                    setTimeout(() => {
                        this.form.address.error = true;
                    });

                    error = true;
                }
                if(!error) {
                    let params = {
                        action: 'SetAddress',
                        id: this.item.id,
                        params: {}
                    };
                    params.params.address = this.form.address.value;
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItemProps', {item: this.item, props: {
                                address: this.form.address.value,
                            }});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.objectAddr.name});
                        },this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    address: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },
            }
        },
        mounted() {
            this.form.address.value  = this.item.props.address;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
