<template>
    <div>
        <div class="popup__header">
            <div class="title">Поставить 3D на паузу</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full'"
                            v-model="form.reason.value"
                            :error-text="''"
                            :auto-focus="true"
                            :label="'Причина приостановки 3D-модели'"
                            :is-error="form.reason.error"
                            @focus="form.reason.error = false"
                            placeholder="Прокомментируйте своё решение"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <hr>
            <div class="mb40">
                <textarea-field :class="'full'"
                                v-model="form.characterKR.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.characterKR.error"
                                @focus="form.characterKR.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <span class="text-italic text-color-grey">
                Заказчику будет отправлена СМС и Email о том, что разработка проекта<br>
                будет приостановлена до получения от него запроса о возобновлении.
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «На паузе»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';

export default {
    name: "PauseModel",
    components: {
        SubmitBtn,
        TextareaField,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send(){
            let error = false;
            for(let field in this.form){
                let errorField = false;
                this.form[field].error = false;

                if((field == 'characterKR' || field == 'reason') && this.form[field].value.length == 0){
                    errorField = true;
                }

                if(errorField){
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }
            if(!error && !this.fileLoad) {
                let params = {
                    action: 'ModelPause',
                    id: this.item.id,
                    params: {}
                };
                for(let field in this.form) {
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id});
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.modelSetPause.name});
                    }, this.options.delayPopupClose);
                },()=>{});
            }
        }
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                characterKR: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                reason: {
                    value: '',
                    error: false,
                    repeatError: false,
                }
            },

        }
    },
    mounted() {
        this.form.characterKR.value = this.item.props.characterKR;
        this.form.reason.value = this.item.props.reasonPause3D ? this.item.props.reasonPause3D : '';
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
}
</script>

<style scoped>

</style>
