<template>
    <div>
        <div class="popup__header">
            <div class="title">Назначить собеседование</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
            <div class="">
                <calendar 
                    :disabled-dates="offDates" 
                    :is-call="true" v-model="form.selectedDate" 
                    :timer-left="true" 
                    :calendar-hidden="calendarHidden" 
                    @calendarshow="calendarHidden = false">
                </calendar>
            </div>
            <div class="calendar__close" :class="{'--showed': !calendarHidden}" @click="calendarHidden = true">
                <div class="up">
                    <span class="i i-arrow2"></span>
                </div>
            </div>
            <span class="text-italic text-color-grey">
                Заказчику и Руководителю будет отправлено СМС-уведомление<br>
                с датой и временем собеседования
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="form.btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Ждут собеседования»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import Calendar from '@/components/Calendar.vue';
    export default {
        name: "ScheduleInterview",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField,
            Calendar,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            setDate(val){
                this.selectedDate = val;
            },
            send(){
                this.form.btnType = 'load'
                let params =  {
                    action: 'SetInterviewDate',
                    id: this.item.id,
                    params: {
                        selectedDate: this.form.selectedDate.date+' '+this.form.selectedDate.time.hours+':'+this.form.selectedDate.time.minutes, 
                    }
                };
                this.$store.dispatch('requests/actionRequest', params).then((data) => {
                    this.form.btnType = 'ok';
                    //this.$store.commit('requests/setItem', {item: this.item, returnItem: data.data});
                    this.$store.commit('requests/deleteRequest', {id: this.item.id});
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.scheduleInterview.name});
                    },this.options.delayPopupClose);
                },()=>{});
            }, 
            formatDate(date) {
                let plusHour = 0;
                let m = date.getMinutes();
                let minutes = '00';
                if(m > 0) minutes = 15;  
                if(m > 15) minutes = 30;    
                if(m > 30) minutes = 45;     
                if(m > 45) {minutes = '00'; plusHour++; };
                date.setHours(date.getHours()+plusHour);

              let dd = date.getDate();
              if (dd < 10) dd = '0' + dd;

              let mm = date.getMonth() + 1;
              if (mm < 10) mm = '0' + mm;
              let yyyy = date.getFullYear();

              return {date:yyyy + '.' + mm + '.' + dd, time:{hours: date.getHours(), minutes: minutes}};
            }
        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                    selectedDate: this.formatDate(new Date())
                },
                dates: [],
                calendarHidden: true,
            }
        },
        mounted() {
            if(this.item){
                let date = new Date();
                date.setHours(date.getHours()+2);
                this.form.selectedDate = this.formatDate(date);
                this.calendarShow = true;
            }
        },
        computed: {
            offDates(){
                let arr = this.dates.slice(0, this.dates.length);
                return arr;
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
