<template>
    <div class="popup__header">
        <div class="title">Документы и файлы</div>
        <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
    </div>


    <div class="popup__content">
		<div class="files docs-images" v-if="item.props.contract.props">
			<div  @click="showViewer(index)" v-for="(file, index) in item.props.contract.props.files" class="file-wrap">
				<!-- Изображения  -->
				<a class="file img" href="javascript:void(0);" v-if="file.type == 'img'"  :style="{'background-image':' url(\'' + appPath + file.icon.url +  '\')'}" :class="{more: index == 8 && item.props.contract.props.files.length > 9}">
					<div class="i i-fullscreen"></div>
					<span class="more" v-if="index == 8 && item.props.contract.props.files.length > 9">Ещё {{item.props.contract.props.files.length - 8}}</span>
				</a>

				<!-- Документы-->
				<a class="file doc" href="javascript:void(0);" v-else>
					<div class="doc__type">
						{{file.docType}}
					</div>
					<span class="name">
						{{file.name}}
					</span>
					<span class="more" v-if="index == 8 && item.props.contract.props.files.length > 9">Ещё {{item.props.contract.props.files.length - 8}}</span>
				</a>
			</div>
		</div>
    </div>

	<div class="fullscreen-viewer" ref="viewer"
          @keyup.space="slideNext" @keyup.esc="closeViewer">
        <div class="fullscreen-viewer__inner" :class="{'nav-hovered-prev': hoveredPrev, 'nav-hovered-next': hoveredNext}">
            <splide class="fullscreen-viewer__carousel" ref="viewerSlider" :infiniteScroll="true">
                <splide-slide :key="index" v-for="(file,index) in item.props.contract.props.files">
                    <!-- Изображения -->
                    <div class="item"  v-if="file.type == 'img'" >
                        <a target="_blank" :href="appPath + file.original.url"><img :src="appPath + file.resize.url" alt=""></a>
                        <div class="inner-nav">
                            <div class="prev" @click="slidePrev" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                            <div class="next" @click="slideNext"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                        </div>
                    </div>

                    <!-- Документы -->
                    <div class="item" v-else>
                        <a download target="_blank" class="file doc" :href="appPath + file.original.url">
                            <div class="doc__type">
                                {{ file.docType}}
                            </div>
                            <span class="name">
                                {{ file.name }}
                            </span>
                        </a>
                        <div class="inner-nav">
                            <div class="prev" @click="slidePrev" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                            <div class="next" @click="slideNext"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                        </div>
                    </div>
                </splide-slide>
            </splide>
        </div>
        <div role="button" class="fullscreen-viewer__close" @click="closeViewer"></div>
        <div class="fullscreen-viewer__navigation">
            <button type="button" class="prev" @click="slidePrev"></button>
            <button type="button" class="next" @click="slideNext"></button>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
	import {Splide, SplideSlide} from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    export default {
        name: "DocsFiles",
        components: {
			Splide,
            SplideSlide,
        },
        methods: {
			showViewer(index) {
                this.$refs.viewerSlider.go(index);
                this.$refs.viewer.classList.add('viewer-transition');
                this.$refs.viewer.querySelector('.splide__track').setAttribute('tabindex', 0);
                let self = this;
                setTimeout(function () {
                    self.$refs.viewer.classList.remove('viewer-transition');
                }, 400);
                setTimeout(function () {
                    self.$refs.viewer.classList.add('showed');
                    document.getElementsByTagName('body')[0].classList.add("noscroll");
                    self.isShowedViewer = true;
                    self.$refs.viewer.querySelector('.splide__track').focus();
                }, 10);
            },
			closeViewer() {
                if (this.isShowedViewer) {
                    this.$refs.viewer.classList.add('viewer-transition');
                    this.$refs.viewer.classList.remove('showed');
                    document.getElementsByTagName('body')[0].classList.remove("noscroll");
                    let self = this;
                    setTimeout(function () {
                        if (self.$refs.viewer) {
                            self.$refs.viewer.classList.remove('viewer-transition');
                        }
                        if (self.$refs.viewerHooper) {
                            self.$refs.viewerHooper.restart();
                        }
                        self.isShowedViewer = false;
                    }, 200);
                }
            },
            slideNext() {
                this.$refs.viewerSlider.go( '+' );
            },
            slidePrev() {
                this.$refs.viewerSlider.go( '-' );
            },

        },
        data: function () {
            return {
				showContactGallery: false,
				appPath: process.env.VUE_APP_PATH_BACK,
				isShowedViewer: false,
            }
        },
		emits: ['splide:hidden', 'splide:move','splide:arrows:updated','splide:pagination:updated', 'splide:moved','splide:active','splide:visible','splide:inactive'],
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                user: state => state.auth.user,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
