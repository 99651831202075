<template>
    <div class="popup__header">
        <div class="title">Взять предоплату</div>
        <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
    </div>

    <div class="popup__content">
        <div class="inp-s-high" style="flex: 1 1 0" :class="{'error': false}">
            <div class="top-label">
                Сумма предоплаты
            </div>
            <input-price   v-model="form.sum.value"
                           name="sum"
                           :isError="form.sum.error"
                           :errorText="form.sum.errorText"
                           @focus="removeError(form.sum)"
                           @input="removeError(form.sum)"></input-price>
        </div>

        <div class="mt40">
            <input-select @toggled="form.optionsTime.error = false" :title="'Когда планируете начать ремонт?'" v-model="form.optionsTime.value" :error="form.optionsTime.error" :options="optionsTime"/>
        </div>

        <div class="mt40">
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="'Комментарий руководителя ремонта'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Комментарий руководителя ремонта"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
        </div>

        <div class="df df-space-between mt40">
            <submit-btn @click="send" tag="button" :type="btnType">
                подтвердить
            </submit-btn>

            <span class="text-right text-size-xs text-color-grey text-italic">
                Объект будет перемещён в «Предоплата»
            </span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'
    import InputSelect from '@/components/forms/InputSelect.vue';
    import InputPrice from "@/components/forms/InputPrice.vue";

    export default {
        name: "RepairPrepay",
        components: {
            Tooltip,
            SubmitBtn,
            TextareaField,
            InputSelect,
            InputPrice,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if((field == 'text' || field == 'comment' || field == 'sum') && this.form[field].value.length == 0){
                        errorField = true;
                    }

                    if((field == 'optionsTime') && this.form[field].value === false){
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'TakePrepay',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if( field == 'optionsTime'){
                            params.params[field] = this.form[field].value.valueClear;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }

                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.takePrepay.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                optionsTime: [],
                form: {
                    sum: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    optionsTime:{
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            for(let index in this.item.props.months) {
                this.optionsTime.push({value: 'Через ' + (parseInt(index) + 1) + ' мес.',valueClear: this.item.props.months[index], desc: this.item.props.months[index]})
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
