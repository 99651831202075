<template>
    <div class="members" v-if="user.groupCode == 'D'">
        <tooltip
                :direction="'top-center-direction'"
                :is-btn-close="false"
                v-for="sticker in item.extProps.staff"
                :key="sticker.role"
                :add-style="{width: 'auto'}">
            <template v-slot:tooltipBtn>
                <span class="member" :class="sticker.char">{{ sticker.code }}</span>
            </template>

            <template v-slot:tooltipContent>
                {{ sticker.role }}:<br>
                <span class="text-bold">{{ sticker.user }}</span>
            </template>
        </tooltip>
    </div>
    <div class="members" v-else-if="isChangeLeader">
        <template  v-for="sticker in item.extProps.staff" :key="sticker.role">
            <tooltip
                v-if="sticker.char === 'r' || sticker.char === 'k' || sticker.char === 'ar'"
                :direction="'bottom-center-direction'"
                :is-btn-close="false"
                :add-style="{width: 'auto'}">
                <template v-slot:tooltipBtn>
                    <span class="member" :class="sticker.char">{{ sticker.code }}</span>
                </template>

                <template v-slot:tooltipContent>
                <span v-if="sticker.char === 'r'" @click="openChangeModal( popups.changeLeader)" class="link" style="cursor: pointer;">Сменить</span>
                <span v-if="sticker.char === 'k'" @click="openChangeModal( popups.changeCoordinator)" class="link" style="cursor: pointer;">Сменить</span>
                <span v-if="sticker.char === 'ar'" @click="openChangeModal( popups.changeAssistant)" class="link" style="cursor: pointer;">Сменить</span>
                </template>
            </tooltip>
            <span v-else style="cursor: default;" class="member" :class="sticker.char">{{ sticker.code }}</span>
        </template>
    </div>
    <div class="members" v-else>
        <span v-for="sticker in item.extProps.staff" style="cursor: default;" class="member" :class="sticker.char">{{ sticker.code }}</span>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Tooltip from '@/components/Tooltip.vue';
import Popup from "../modal/Popup.vue";

export default {
    name: "Stickers",
    components:{
        Popup,
        Tooltip
    },
    props: {
        item: {}
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            popups: state => state.popups.items,
        }),
        isChangeLeader() {
            return (this.user.groupCode === 'R' || this.user.groupCode === 'K' || this.user.groupCode === 'AR')
                && this.hasState(['measure_queue_*','measure_confirmed_*','measure_in_work_*','concepts_approved_*','concepts_accepted_*']);
        },
    },
    methods: {
        ...mapActions('popups', [
            'open'
        ]),
        openChangeModal(modal) {
            this.$store.dispatch('popups/getData',{id: this.item.extProps.team.id, modalData: 'AllWorkers'}).then(response => {
                this.$store.commit('requests/setAllWorkers', response.items);
                this.$store.dispatch('popups/open', {name: modal.name, request: this.item});
            });
        },
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
    }
}
</script>

<style scoped>

</style>