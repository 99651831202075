<template>
    <div>
        <div class="popup__header">
            <div class="title">Передать заказчику дизайн</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="mb40">
                <input-styled v-model="form.pdf.value"
                                  :placeholder="'Ссылка на папку объекта'"
                                  :placeholder-input="'Вставьте ссылку на папку объекта'"
                                  :isError="form.pdf.error"
                                  :errorText="form.pdf.errorText"
                                  @focus="removeError(form.pdf)"
                                  @input="removeError(form.pdf)"></input-styled>
            </div>
            <div class="mb40">
                <input-styled v-model="form.photo.value"
                                  :placeholder="'Ссылка на фото объекта'"
                                  :placeholder-input="'Вставьте ссылку на фото объекта'"
                                  :isError="form.photo.error"
                                  :errorText="form.photo.errorText"
                                  @focus="removeError(form.photo)"
                                  @input="removeError(form.photo)"></input-styled>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'
    import InputStyled from '@/components/forms/InputStyled.vue';
    export default {
        name: "GiveDesign",
        components: {
            SubmitBtn,
            InputStyled
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    this.form[field].error = false;
                    let errorField = false;
                    if(this.form[field].value.length == 0){
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if (!error) {
                    let params = {
                        action: 'GiveDesign',
                        id: this.item.id,
                        params: {},
                    };
                    for (let field in this.form) {
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((result) => {
                        this.btnType = 'ok';
                        if (result.status === 'error') {
                            this.$store.commit('popups/close', { name: this.popups.giveDesign.name });
                            this.$store.commit('popups/setErrorCustomText', !!(result.data?.message) ? result.data.message : 'Произошла неизвестная ошибка');
                            this.$store.dispatch('popups/open', { name: this.popups.errorCustomTextModal.name });
                        } else {
                            this.$store.commit('requests/deleteRequest', { id: this.item.id });
                            setTimeout(() => {
                                this.$store.commit('popups/close', { name: this.popups.giveDesign.name });
                            }, this.options.delayPopupClose);
                        }
                    }, () => {});

                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    pdf: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    photo: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
