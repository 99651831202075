<template>
    <div>
        <div class="popup__header">
            <div class="title">Возобновить замер</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
            <div class="">
                <calendar
                    :type-metering="item.props.typeMetering"
                    v-if="calendarShow"
                    :disabled-dates="dates"
                    v-model="form.selectedDate"
                    :calendar-hidden="calendarHidden"
                    @calendarshow="calendarHidden = false">
                </calendar>
            </div>
            <div v-if="!calendarHidden" class="mt24">
                <tumbler @change="form.tumbler.error=false" :options="early" :elem-width="114" :error="form.tumbler.error" v-model="form.option"/>
            </div>
            <div v-if="!calendarHidden" class="mt24">
                <textarea-field :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                :label="'Комментарий'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="calendar__close">

            </div>
            <div class="mb40 mt40">
                <textarea-field :class="'full'"
                                v-model="form.characterKR.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.characterKR.error"
                                @focus="form.characterKR.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div>
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Заказчик уведомлён, что замерщик позвонит <strong>{{ dateCall }}</strong> (за 2 дня)</label>
            </div>

            <hr>

            <span class="text-italic text-color-grey">
                Заказчику будет отправлено СМС с датой и временем замера
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic error">
                    Объект будет перемещен в раздел «Подтверждены» и по нему<br>
                    необходимо будет сделать отзвон за 2 дня до замера
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import Calendar from '@/components/Calendar.vue';
    export default {
        name: "ResumeMeasure",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField,
            Calendar,
            InputStyled,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            setDate(val){
                this.selectedDate = val;
            },
            daysCount(){
                let choosenDate = new Date(this.form.selectedDate.date);
                let today = new Date();
                return ((choosenDate.getTime() - today.getTime()) / (1000 * 3600 * 24)).toFixed(0)
            },
            send(){
                let error = false;
                this.form.tumbler.error = false;
                this.form.text.error = false;
                if(Object.keys(this.form.option).length == 0){
                    setTimeout(() => {
                        this.form.tumbler.error = true;
                    });
                    error = true;
                }
                if(this.form.text.value.length == 0){
                    setTimeout(() => {
                        this.form.text.error = true;
                    });
                    error = true;
                }
                if(this.form.checkbox1.value === false){
                    setTimeout(() => {
                        this.form.checkbox1.error = true;
                    });
                    error = true;
                }
                if(!error){
                    this.btnType = 'load'
                    let params =  {
                        action: 'ResumeMeasure',
                        id: this.item.id,
                        params: {
                            selectedDate: this.form.selectedDate.date+' '+this.form.selectedDate.time.hours+':'+this.form.selectedDate.time.minutes,
                            comment: this.form.text.value,
                            characterKR: this.form.characterKR.value,
                            isEarlier: this.form.option.id,
                        }
                    };
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.resumeMeasure.name});
                        },this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    selectedDate: {date: '2020.12.12', time: {hours: 11, minutes: '00'}},
                    option: {},
                    tumbler: {
                        error: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    characterKR: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },
                early: [
                    {name: 'Готовы раньше', id: '168'},
                    {name: 'Не готовы раньше', id: '185'},
                ],
                dates: [],
                calendarHidden: false,
                calendarShow: true,
            }
        },
        mounted() {
            if(this.item){
                this.form.selectedDate = {date: this.item.props.dateProject.calendarDate, time: {hours: this.item.props.dateProject.calendarHour, minutes: this.item.props.dateProject.calendarMinute}};
                if(this.item.props.putAside !== false){
                    this.form.option = {name:'', id: this.item.props.putAside};
                }
                this.form.text.value = this.item.props.putAsideComment;
                this.form.selectedDate.date =  this.item.closest;
                this.form.characterKR.value = this.item.props.characterKR;
            }
        },
        computed: {
            offDates(){
                let arr = this.dates.slice(0, this.dates.length);
                return arr;
            },
            dateCall(){
                let options = {
                    month: 'long',
                    day: 'numeric',
                };
                let prevDate = new Date(this.form.selectedDate.date+' '+this.form.selectedDate.time.hours+':'+this.form.selectedDate.time.minutes+':00').getTime() - 48 * 3600000;
                return new Date(prevDate).toLocaleString("ru", options)
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                dates: state => state.popups.curItemData.dates,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
