<template>
    <div>
        <div class="popup__header">
            <div class="title">Перевести в «Посмотрели»</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Я понимаю, что заказчик не получит стандартных уведомлений</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.checkbox3)" v-model="form.checkbox2.value" :class="{error:form.checkbox2.error, 'repeat-error': form.checkbox2.repeatError}" type="checkbox" id="checkbox2">
                <label for="checkbox2">Я знаю, что заказчик не посмотрит видео Алексея о руководителях</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.checkbox3)" v-model="form.checkbox3.value" :class="{error:form.checkbox3.error, 'repeat-error': form.checkbox3.repeatError}" type="checkbox" id="checkbox3">
                <label for="checkbox3">Я подтверждаю, что работаю в логике 4.0</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Посмотрели»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';

    export default {
        name: "MoveSeen",
        components: {
            SubmitBtn,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if(this.form[field].value.toString().length == 0 || this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'MoveSeen',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form){
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id})
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.moveSeen.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox2: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox3: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
