<template>
    <div class="nothing-block text-center">
        <div class="nothing">
            <img src="@/assets/img/nothing.svg" alt="">
        </div>
        <div class="text-bold mt16">
            Здесь пока ничего нет
        </div>
    </div>
</template>

<script>
    export default {
        name: "NothingBlock",
        props: {
        },
        computed: {
        },
        methods:{

        }
    }
</script>
