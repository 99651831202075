<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    name: "ConceptsPause",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
        requestStatusDays() {
            return this.item.props.daysFromStatusDate;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
                    <span v-if="requestStatusDays > 24" class="i i-info3 mr4"
                          :class="{'i--yellow': requestStatusDays < 36,'i--red': requestStatusDays > 35}"></span>
            <a href="javascript:void(0);"
               style="margin-left: 0 !important;"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
               v-html="requestStatusTimePassed"></a>
        </template>
        <template v-slot:tooltipContent>
            <span v-html="convertNewLine(item.props.reasonPause)"></span>
            <div class="mt8" v-if="item.props.reasonPauseUser">
                <br/>
                {{ item.props.reasonPauseUser.lastName }} {{ item.props.reasonPauseUser.firstName }}
                ({{ item.props.reasonPauseUser.groupCode }}{{ item.props.reasonPauseUser.number }}),
                {{ item.props.statusDate }}
            </div>
        </template>
    </tooltip>
</template>

<style scoped>

</style>