<template>
    <div class="popup__header">
        <div class="title">Редактировать комментарий</div>
        <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
    </div>

    <div class="popup__content">
        <div class="mt40">
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="'Служебный комментарий'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Подробно опишите ситуацию, чтобы в любой момент времени можно было вспомнить все детали..."
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
        </div>

        <div class="df df-space-between mt40">
            <submit-btn @click="send" tag="button" :type="btnType">
                Сохранить
            </submit-btn>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'

    export default {
        name: "EditMasterComment",
        components: {
            Tooltip,
            SubmitBtn,
            TextareaField
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(field == 'text' && this.form[field].value.length == 0) {
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'EditMasterComment',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        params.params[field] = this.form[field].value;
                    }

                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/changeRequest', {id: this.item.id, prop: 'repairComment', value: this.form.text.value});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.editMasterComment.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                optionsTime: [],
                form: {
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            this.form.text.value = this.item.props.repairComment;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
