<template>
    <div>
        <div class="popup__header">
            <div class="title">Отменить замер</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :auto-focus="true"
                            :label="'Причина отмены'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Укажите причину отмены"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value"
                       :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox"
                       id="checkbox1">
                <label for="checkbox1">Заказчик уведомлён, что внесённые 5000 ₽ не вернутся</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.checkbox3)" v-model="form.checkbox3.value"
                       :class="{error:form.checkbox3.error, 'repeat-error': form.checkbox3.repeatError}" type="checkbox"
                       id="checkbox3">
                <label for="checkbox3">Я знаю, что дата в календаре останется занятой</label>
            </div>
            <hr>
            <span class="text-italic text-color-grey">
                Заказчику будет отправлено СМС с порядком возобновления замера<br>
                и телефоном куратора проекта
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Отменённые»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';

export default {
    name: 'CancelMeasure',
    components: {
        SubmitBtn,
        TextareaField,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send() {
            let error = false;
            for (let field in this.form) {
                let errorField = false;
                this.form[field].error = false;

                if (this.form[field].value.toString().length == 0 || this.form[field].value === false) {
                    errorField = true;
                }
                if (errorField) {
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }
            if (!error) {
                //console.log('1111');
                let params = {
                    action: 'CancelMeasure',
                    id: this.item.id,
                    params: {},
                };
                for (let field in this.form) {
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', { id: this.item.id });
                    setTimeout(() => {
                        this.$store.commit('popups/close', { name: this.popups.cancelMeasure.name });
                    }, this.options.delayPopupClose);
                }, () => {});
            }
        },
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                checkbox1: {
                    value: false,
                    error: false,
                    repeatError: false,
                },
                checkbox3: {
                    value: false,
                    error: false,
                    repeatError: false,
                },
                text: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
            },

        };
    },
    mounted() {

    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
};
</script>

<style scoped>

</style>
