<template>
    <div>
        <div class="popup__header">
            <div class="title">Отправить СМС заказчику</div>
            <div class="text-color-white-trans text-bold mt4">{{ clientContact }}</div>
        </div>


        <div class="popup__content">
            <div class="mt40">
                <textarea-field :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                :label="'Текст СМС'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Напишите здесь СМС для заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    отправить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">

                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';

    export default {
        name: "SendSms",
        props:{
        },
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            getMessageTemplate(form) {
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                }, () => {
                });
            },
            send() {
                let error = false;
                for (let field in this.form) {
                    let errorField = false;
                    this.form[field].error = false
                    if (this.form[field].value.toString().length == 0 || this.form[field].value === false && !this.notCall) {
                        errorField = true;
                    }
                    if (errorField) {
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if (!error) {
                    let params = {
                        action: 'SendSms',
                        id: this.item.id,
                        params: {}
                    };
                    for (let field in this.form) {
                        params.params[field] = this.form[field].value;
                    }

                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        setTimeout(() => {
                            this.$store.commit('popups/close', {name: this.popups.sendSms.name});
                        }, this.options.delayPopupClose);
                    }, () => {
                    });
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            this.form.text.value = this.text;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
            clientContact() {
                let clientContact = this.item.props.clientSurname + ' ' + this.item.props.clientName + ' ' + this.item.props.clientPatronymic + ', ' + this.item.props.clientPhone;
                return clientContact.trim();
            },
            text() {
                return this.item.props.smsSendText;
            },
        },
    }
</script>

<style scoped>

</style>
