<template>
    <div>
        <div class="popup__header">
            <div class="title">Взять объект в работу</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
            <div class="">
				<div class="text-bold text-uppercase text-size-xs" style="margin-bottom: -11px;">Дата начала работ по договору</div>
                <calendar
                    :disabled-dates="[]"
					:noTitle="true"
					:isTimer="false"
                    v-model="form.selectedDate" >
                </calendar>
            </div>
			<hr>
			<div class="df">
   			   <div class="inp-s-high" style="flex: 1 1 0" :class="{'error': false}">
   				   <div class="top-label">
   					   общая стоимость работ по договору <div class="preloader"></div>
   				   </div>
				   <input-price   v-model="form.sum.value"
	   							  name="sum"
	   							  :isError="form.sum.error"
	   							  :errorText="form.sum.errorText"
	   							  @focus="removeError(form.sum)"
	   							  @input="setPreloader();removeError(form.sum)"></input-price>
   				   <div class="bottom-label" :class="{'load': formSumPreloader}" v-if="form.sum.value.length > 5">
   					  <span v-if="true">{{ sumPerMeter }}</span> ₽ за метр
   				   </div>
   			   </div>
   			   <div class="ml48" style="width: 188px;">
   				   <div class="text-color-grey text-uppercase text-size-xs">
   					   сумма отчёта
   				   </div>
   				   <div class="text-bold text-size-xxl mt8 mb8 sum-loader" :class="{'load': formSumPreloader}" style="line-height: 32px;">
                       <span v-if="!formSumPreloader">{{ number_format(formSumValueNum * (percentForMaster / 100)) }}</span> <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
   				   </div>
   				   <div class="text-color-grey text-size-xs dif">
   					   {{ percentForMaster }}% от {{ number_format(formSumValueNum) }} ₽
   				   </div>
   			   </div>
   		   </div>
            <div class="mb40 mt40">
                <textarea-field :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                :label="'Комментарий руководителя ремонта'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Подробно опишите все нюансы, связанные с договором и объектом..."
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Я готов сдать финансовый отчёт по данному объекту</label>
            </div>
			<hr>
			<span class="text-italic text-color-grey">
                Нашему бухгалтеру будет отправлено СМС о том,<br>
				что вы заключили договор и готовы сдать отчёт.
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType" :isLoaderIn="true">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «В работе»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
	import InputPrice from '@/components/forms/InputPrice.vue';
	import InputSelectTime from '@/components/forms/InputSelectTime.vue';
    import Calendar from '@/components/Calendar.vue';
    import {number_format} from '@/helpers/formatted.js';
    export default {
        name: "TakeToWork",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField,
            Calendar,
            InputStyled,
			InputSelectTime,
			InputPrice,
        },
        methods: {
            setPreloader() {
                this.formSumPreloader = true;
                setTimeout(() => {
                    this.formSumPreloader = false;
                }, 3000);
            },
            removeError(form) {
                form.error = false;
            },
            setDate(val){
                this.selectedDate = val;
            },
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    if(field != 'selectedDate'){
                        this.form[field].error = false;
                        if((field == 'text' || field == 'sum') && this.form[field].value.length == 0){
                            errorField = true;
                        }
                        if((field == 'checkbox1' || field == 'checkbox2') && this.form[field].value === false){
                            errorField = true;
                        }
                        if(errorField){
                            error = true;
                            setTimeout(() => {
                                this.form[field].error = true;
                            });
                        }
                    }

                }
                if(!error) {
                    let params = {
                        action: 'TakeToWork',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field == 'selectedDate'){
                            params.params[field] = this.form[field].date;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((data) => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.takeToWork.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            },
            formatDate(date) {
                let plusHour = 0;
                let m = date.getMinutes();
                let minutes = '00';
                if(m > 0) minutes = 15;
                if(m > 15) minutes = 30;
                if(m > 30) minutes = 45;
                if(m > 45) {minutes = '00'; plusHour++; };
                date.setHours(date.getHours()+plusHour);

                let dd = date.getDate();
                if (dd < 10) dd = '0' + dd;

                let mm = date.getMonth() + 1;
                if (mm < 10) mm = '0' + mm;
                let yyyy = date.getFullYear();

                return {date:yyyy + '.' + mm + '.' + dd, time:{hours: date.getHours(), minutes: minutes}};
            },
        },
        data: function () {
            return {
                btnType: 'normal',
                formSumPreloader: false,
                form: {
                    selectedDate: {date: '2020.12.12', time: {hours: 12, minutes: 30}},
                    sum: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
					checkbox2: {
                        value: true,
                        error: false,
                        repeatError: false,
                    },

                },
            }
        },
        mounted() {
            let date = new Date();
            date.setHours(date.getHours()+2);
            this.form.selectedDate = this.formatDate(date);
            this.form.sum.value = '0';
        },

        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
            formSumValueNum() {
                return this.form.sum.value.split(' ').join('');
            },
            percentForMaster() {
                return +this.item.props.repairMasterPercent;
            },
            sumPerMeter(){
                let sum = this.formSumValueNum;
                if (sum > 9999999) {
                    this.form.sum.value = '9 999 999';
                    sum = 9999999;
                }

                return +this.item.props.flatArea.replace(',','.') > 0 ? number_format(+sum / +this.item.props.flatArea.replace(',','.'), 0, '', ' ') : 0;
            }
        },
    }
</script>

<style scoped>
    .popup__content .disabled {
        opacity: 0.3;
        pointer-events:none;
    }
</style>
