<script>
import Tooltip from '@/components/Tooltip.vue';
import { mapState } from 'vuex';
export default {
    name: "RequestReject",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        },
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :add-class-wrap="'details-tooltip'"
        :isChangeablePos="false"
        :add-style="{width: '264px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);" class="link ml0 link-yellow text-size-xs text-uppercase text-bold"
               v-html="requestStatusTimePassed"></a>
        </template>

        <template v-slot:tooltipContent>
            <div class="">
                {{ item.props.reasonReject }} <br> <br>
                {{ item.props.reasonRejectUser.lastName }} {{ item.props.reasonRejectUser.firstName }}
                ({{ item.props.reasonRejectUser.groupCode + item.props.reasonRejectUser.number }}),
                {{ item.props.statusDate }}
            </div>
        </template>
    </tooltip>
</template>

<style scoped>

</style>