<template>
    <div>
        <div class="popup__header">
            <div class="title">Принять деньги у замерщика</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="df mb40">
                <div>
                    <div class="text-color-grey text-uppercase text-size-xs">
                        Площадь
                    </div>
                    <div class="text-bold text-size-xxl mt8 mb8" style="line-height: 32px;">
                        {{ item.props.flatArea}} <span>м<sup>2</sup></span>
                    </div>
                    <div class="text-color-grey text-size-xs dif text-uppercase">
                        {{ item.props.isSecondary ? 'Старше 25 лет' : 'До 25 лет'}}
                    </div>
                </div>
                <div class="ml64">
                    <div class="text-color-grey text-size-xs df to-pay">
                        <span class="text-uppercase mr4">К оплате</span>
                        <tooltip
                                :direction="'top-center-direction'"
                                :is-btn-close="false"
                                :add-style="{width: 234 + 'px'}">
                            <template v-slot:tooltipBtn>
                                <span class="i i-info4 i--dark-trans"></span>
                            </template>

                            <template v-slot:tooltipContent>
                                <span>Стоимость проекта за вычетом<br>предоплаты {{prepayTooltip}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
                            </template>
                        </tooltip>
                    </div>
                    <div class="text-bold text-size-xxl mt8 mb8" style="line-height: 32px;">
                        {{ prepay }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                    <div class="text-color-grey text-size-xs dif">
                        {{ item.props.typeTax}}
                    </div>
                </div>
            </div>

            <span class="text-italic text-color-grey">
                Владельцу, Директору и Замерщику будет отправлено СМС,<br>а бухгалтеру – Email об усешной оплате.
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Концепции / В разработке»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import {number_format} from '@/helpers/formatted.js';
    import Tooltip from '@/components/Tooltip.vue';

    export default {
        name: "AcceptMeasurerMoney",
        components: {
            SubmitBtn,
            InputStyled,
            Tooltip,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
            send(){
                let params = {
                    action: 'AcceptMeasurerMoney',
                    id: this.item.id,
                    params: {}
                };
                for(let field in this.form){
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id})
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.acceptMeasurerMoney.name});
                    }, this.options.delayPopupClose);
                },()=>{});
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    area: {
                        value: '',
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {

        },
        computed: {
            prepay(){
                return this.number_format(this.item.props.price.replace(/ /g, "") - this.item.props.prepay);
            },
            prepayTooltip(){
                return this.item.props.prepay;
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
                typeTax: state => state.options.items.typeTax ? state.options.items.typeTax : [],
            }),

        },
    }
</script>

<style scoped>

</style>
