<script>
import Tooltip from '@/components/Tooltip.vue';
import { mapState } from 'vuex';
export default {
    name: "PurposeThink",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            popups: state => state.popups.items,
        }),
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        },
        openEditComment(item, code) {
            this.$store.commit('requests/setItemProps', {
                item: this.item, props: {
                    commentField: code,
                }
            });
            this.$store.dispatch('popups/open', {name: this.popups.editComment.name, request: item});
        },
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :add-class-wrap="'details-tooltip'"
        :isChangeablePos="false"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
               v-html="requestStatusTimePassed"></a>
        </template>
        <template v-slot:tooltipContent>
            <span v-html="convertNewLine( item.props.statusComment )"></span> <br> <br>
            {{ item.props.statusChangeUser.lastName }} {{ item.props.statusChangeUser.firstName }}
            ({{ item.props.statusChangeUser.groupCode + item.props.statusChangeUser.number }}),
            {{ item.props.statusDate }}
            <div v-if="user.groupCode == 'S'" class="mt16 dif"
                 @click="openEditComment(item, 'statusComment')"><span
                class="i i-pencil2 i--blue mr4"></span><a href="javascript:void(0);">Редактировать</a></div>
        </template>
    </tooltip>
</template>

<style scoped>

</style>