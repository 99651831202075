export default {
    isPhone (value) {
        let phone = value.replace(/[^\+0-9]/g, '');
        if(phone.length < 12 || phone[2] !== '9') {
            return false;
        }
        return true;
    },
    isAnyPhone (value) {
        let phone = value.replace(/[^\+0-9]/g, '');
        if(phone.length < 12) {
            return false;
        }
        return true;
    },
    isEmail(value){
        let r = /^[\w\.\d-_\+]+@[\w\.\d-_]+\.\w{2,99}$/i;
        return r.test(value)
    },
    isFloat(value){
        let r = /^[0-9]+[.,][0-9]+/i;
        return r.test(value)
    }
}
