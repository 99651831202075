<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    name: "PurposeNew",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
               v-html="item.props.autosale_type_title ? item.props.autosale_type_title : 'Без комментария...'"></a>
        </template>
        <template v-slot:tooltipContent><span
            v-html="convertNewLine(item.props.autosale_type_comment)"></span> <br>
            {{ item.props.clientName }} {{ item.props.clientPatronymic }} (заказчик),
            {{ item.props.autosale_type_date }}
        </template>
    </tooltip>
</template>

<style scoped>

</style>