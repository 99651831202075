<template>

    <div class="top__item date-block"  :class="{mr40: hasState(listsState.margin)}" v-if="hasState(listsState.isShowBlock)">
        <span class="text-color-grey text-size-xs"  v-if="hasState(listsState.approximatePrice)">Примерная стоимость работ</span>
        <span class="text-color-grey text-size-xs"  v-if="hasState(listsState.declaredPrice)">озвученная стоимость работ</span>
        <span class="text-color-grey text-size-xs"  v-if="hasState(listsState.contractPrice)">стоимость работ по договору</span>

        <span class="text-bold text-size-xxl mt8 mb8" v-if="hasState(listsState.priceRepairPerMeter)">{{ item.props.priceRepairPerMeter }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>/м<sup>2</sup></span>
        <span class="text-bold text-size-xxl mt8 mb8" v-else-if="hasState(listsState.contractPrice)">{{ item.props.priceRepairContract }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
        <span class="text-bold text-size-xxl mt8 mb8" v-else>{{ item.props.priceRepair }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>

        <span class="text-color-grey text-size-xs" v-if="hasState(listsState.repairSale)">{{ item.props.priceRepairPerMeter }}<span class="rouble ml4 mr4">{{ $store.getters['options/setRouble'] }}</span> за метр</span>
        <span class="text-color-grey text-size-xs" v-if="hasState(listsState.contractPrice)">{{ item.props.priceRepairContractPerMeter }} ₽ за метр</span>
        <span class="text-color-grey text-size-xs" v-else-if="hasState(['repair_sale_masters_set_*'])">{{ item.props.priceRepairPerMeter }} ₽ за метр</span>
        <span class="text-color-grey text-size-xs" v-else-if="hasState(listsState.priceRepairPerMeter)">{{ item.props.priceRepair }} ₽</span>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "TopItemBlockContractPrice",
    props: {
        item: {}
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
    },
    data(){
        return {
            listsState: {
                margin: [
                    'repair_in_process_*',
                    'repair_terminate_*',
                    'repair_finish_*',
                    'repair_sale_no_masters_*'
                ],
                isShowBlock: [
                    'repair_sale_new_*',
                    'repair_sale_think_*',
                    'repair_sale_masters_late_*',
                    'repair_sale_masters_set_*',
                    'repair_request_*',
                    'repair_think_*',
                    'repair_reject_*',
                    'repair_aside_*',
                    'repair_in_process_*',
                    'repair_terminate_*',
                    'repair_finish_*',
                    'repair_sale_no_masters_*',
                    'repair_prepay_*'
                ],
                approximatePrice: [
                    'repair_sale_new_*',
                    'repair_sale_think_*',
                    'repair_sale_masters_late_*',
                    'repair_sale_no_masters_*'
                ],
                declaredPrice: [
                    'repair_sale_masters_set_*',
                    'repair_request_*',
                    'repair_think_*',
                    'repair_reject_*',
                    'repair_aside_*',
                    'repair_prepay_*'
                ],
                contractPrice: [
                    'repair_in_process_*',
                    'repair_terminate_*',
                    'repair_finish_*'
                ],
                priceRepairPerMeter: [
                    'repair_request_*',
                    'repair_think_*',
                    'repair_reject_*',
                    'repair_aside_*',
                    'repair_prepay_*'
                ],
                repairSale: [
                    'repair_sale_new_*',
                    'repair_sale_think_*',
                    'repair_sale_masters_late_*',
                    'repair_sale_no_masters_*'
                ]
            }

        }
    },
    methods: {
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
    }
}
</script>

<style scoped>

</style>