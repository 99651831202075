<template>
    <div>
        <div class="popup__header">
            <div class="title">Отложить объект</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <baza-select @toggled="removeError(form.month)" v-model="form.month.value" :title="'на какой срок откладывается ремонт?'" :error="form.month.error" :error-text="form.month.errorText"/>
            <div class="mt40">
                <textarea-field :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                :label="'Комментарий руководителя ремонта'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Подробно опишите, о чём вы договорились с заказчиком, чтобы потом точно знать, стоит ли ему ещё раз звонить и напоминать о себе."
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>



            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import BazaSelect from '@/components/forms/BazaSelect.vue';
    export default {
        name: "RepairAsideMore",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField,
            BazaSelect
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if((field == 'text') && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if((field == 'month') && this.form[field].value == false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'RepairAsideMore',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((data) => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItem', {item: this.item, returnItem: data.data});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.repairAsideMore.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    month: {
                        value: false,
                        error: false,
                        repeatError: false,
                        errorText: ''
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },

                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
