<template>
    <div v-if="showConceptGallery" class="fullscreen-viewer" ref="viewerConcept"
         @keyup.space="slideNext" @keyup.esc="closeViewerConcept">
        <div class="fullscreen-viewer__inner" :class="{'nav-hovered-prev': hoveredPrev, 'nav-hovered-next': hoveredNext}">
            <splide class="fullscreen-viewer__carousel" ref="viewerConceptSlider" :infiniteScroll="true">
                <splide-slide  v-if="item.props.conceptFile.path">
                    <div class="item">
                        <a target="_blank" :href="appPath + item.props.conceptFile.path"><img :src="appPath + item.props.conceptFile.path" alt=""></a>
                        <div class="inner-nav" v-if="item.props.conceptFileNew.path">
                            <div class="prev" @click="slidePrevConcept" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                            <div class="next" @click="slideNextConcept"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                        </div>
                    </div>
                </splide-slide>
                <splide-slide v-if="item.props.conceptFileNew.path">
                    <div class="item">
                        <a target="_blank" :href="appPath + item.props.conceptFileNew.path"><img :src="appPath + item.props.conceptFileNew.path" alt=""></a>
                        <div class="inner-nav" v-if="item.props.conceptFile.path">
                            <div class="prev" @click="slidePrevConcept" @mouseover="hoveredPrev = true" @mouseleave="hoveredPrev = false"></div>
                            <div class="next" @click="slideNextConcept"  @mouseover="hoveredNext = true" @mouseleave="hoveredNext = false"></div>
                        </div>
                    </div>
                </splide-slide>
            </splide>
        </div>
        <div role="button" class="fullscreen-viewer__close" @click="closeViewerConcept"></div>
        <div class="fullscreen-viewer__navigation" v-if="item.props.conceptFile.path && item.props.conceptFileNew.path">
            <button type="button" class="prev" @click="slidePrevConcept"></button>
            <button type="button" class="next" @click="slideNextConcept"></button>
        </div>
    </div>
</template>

<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';

export default {
    name: "ConceptGallery",
    components: {
        Splide,
        SplideSlide,
    },
    props: {
        item: {},
        showConceptGallery: false
    },
    data(){
        return {
            appPath: process.env.VUE_APP_PATH_BACK,
            viewerShow: false,
            hoveredPrev: false,
            hoveredNext: false,
            currentSlide: '',
            isShowedViewerConcept: false,
        }
    },
    methods: {
        slideNextConcept() {
            this.$refs.viewerConceptSlider.go( '+' );
        },
        slidePrevConcept() {
            this.$refs.viewerConceptSlider.go( '-' );
        },
        slideNext() {
            this.$refs.viewerSlider.go( '+' );
        },
        slidePrev() {
            this.$refs.viewerSlider.go( '-' );
        },
        closeViewerConcept() {
            if (this.isShowedViewerConcept) {
                this.$refs.viewerConcept.classList.add('viewer-transition');
                this.$refs.viewerConcept.classList.remove('showed');
                document.getElementsByTagName('body')[0].classList.remove("noscroll");
                let self = this;
                setTimeout(function () {
                    if (self.$refs.viewerConcept) {
                        self.$refs.viewerConcept.classList.remove('viewer-transition');
                    }
                    if (self.$refs.viewerHooper) {
                        self.$refs.viewerHooper.restart();
                    }
                    self.isShowedViewerConcept = false;
                }, 200);
            }
        },
        showViewerConcept(index) {
            this.$refs.viewerConceptSlider.go(index);
            this.$refs.viewerConcept.classList.add('viewer-transition');
            this.$refs.viewerConcept.querySelector('.splide__track').setAttribute('tabindex', 0);
            let self = this;
            setTimeout(function () {
                self.$refs.viewerConcept.classList.remove('viewer-transition');
            }, 400);
            setTimeout(function () {
                self.$refs.viewerConcept.classList.add('showed');
                document.getElementsByTagName('body')[0].classList.add("noscroll");
                self.isShowedViewerConcept = true;
                self.$refs.viewerConcept.querySelector('.splide__track').focus();
            }, 10);
        },
    }
}
</script>

<style scoped>

</style>