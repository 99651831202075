<template>
    <div>
        <div class="popup__header">
            <div class="title">Причина отклонения</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-bold mt8">
                {{ item.props.reasonReject }}
            </div>
            <div class="mt8" v-if="item.props.reasonRejectUser">
                {{ item.props.reasonRejectUser.lastName }} {{ item.props.reasonRejectUser.firstName }} ({{ item.props.reasonRejectUser.groupCode+item.props.reasonRejectUser.number }}), {{ item.props.reasonRejectUser.post }}
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';

    export default {
        name: "RejectReason",
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            removeError(form) {
                form.error = false;
            }
        },
        data: function () {
            return {
                form: {
                    btnType: 'normal',
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox2: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
