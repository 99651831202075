<template>
    <div>
        <div class="popup__header">
            <div class="title">Принять наличные</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div :class="{'error': form.square.error}">
                Укажите площадь объекта с точностью до десятой доли метра.<br class="o-desc">
                Если площадь целое число – поставьте в конце ноль через запятую.
            </div>
            <div class="df mt40 mb40">
                <div class="inp-s-high" style="width: 152px" :class="{'error': form.square.error}">
                    <div class="top-label">
                        Площадь объекта, м2
                    </div>
                    <input-styled v-model="form.square.value"
                                      name="area"
                                      :mask="masks.squareMeterDecimals"
                                      :placeholder-input="'0'"
                                      :isError="form.square.error"
                                      :errorText="form.square.errorText"
                                      @focus="removeError(form.square)"
                                      @input="removeError(form.square)"></input-styled>
                    <div class="bottom-label">
                        {{ item.props.isSecondary ? 'Старше 25 лет' : 'До 25 лет'}}
                    </div>
                </div>
                <div class="ml64">
                    <div class="text-color-grey text-size-xs df to-pay">
                        <span class="text-uppercase mr4">К оплате</span>
                        <tooltip
                                :direction="'top-center-direction'"
                                :is-btn-close="false"
                                :add-style="{width: 234 + 'px'}">
                            <template v-slot:tooltipBtn>
                                <span class="i i-info4 i--dark-trans"></span>
                            </template>

                            <template v-slot:tooltipContent>
                                <span>Стоимость проекта за вычетом<br>предоплаты {{prepay}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
                            </template>
                        </tooltip>
                    </div>
                    <div class="text-bold text-size-xxl mt8 mb8" style="line-height: 32px;">
                        {{ number_format(item.props.costPerMeter * calcSquare - prepay) }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                    <div class="text-color-grey text-size-xs dif">
                        ПАТЕНТ МО
                    </div>
                </div>
            </div>
			<hr>
			<div class="text-size-xs text-color-grey text-uppercase mb12">высота потолков</div>
			<tumbler @change="removeError(form.option)" :options="options.items.height" :elem-width="214" v-model="form.option.value" :error="form.option.error"/>
            <hr>
            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Заказчик в курсе, что через пару дней будет созвон по концепции</label>
            </div>
            <hr>
            <div class="text-italic text-color-grey mt32">
                Заказчику и директору будет отправлено СМС об оплате,<br class="o-desc">
                а так же сформирован номер договора.
            </div>
            <div class="df df-space-between mt40 btn-wrapper">
                <submit-btn  @click="send()" tag="button" :type="btnType">
                    принять наличные
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект останется «В работе»<br class="o-desc">
					до передачи наличных Директору
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import {number_format} from '@/helpers/formatted.js';
    import validate from '@/helpers/validate';
    import Tooltip from '@/components/Tooltip.vue';
	import Tumbler from '@/components/Tumbler.vue';

    export default {
        name: "AcceptCash",
        components: {
            SubmitBtn,
            InputStyled,
            Tooltip,
			Tumbler,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            number_format: function (v) {
                return number_format(v, 0, '', ' ')
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if(field == 'square' && !validate.isFloat(this.form[field].value)){
                        errorField = true;
                    }
                    if(field == 'option' && this.form[field].value === false){
                        errorField = true;
                    }
                    if(this.form[field].type == 'checkbox' && this.form[field].value === false){
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'AcceptCash',
                        id: this.item.id,
                        params: {
                            sum: this.item.props.costPerMeter * this.calcSquare - this.prepay
                        }
                    };
                    for(let field in this.form) {
                        if( field == 'option'){
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((data) => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItem', {item: this.item, returnItem: data.data});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.acceptCash.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    square: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
					option: {
						value: false,
						error: false,
						repeatError: false,
					},
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                        type: 'checkbox'
                    },
                },

            }
        },
        mounted() {
            this.form.square.value = validate.isFloat(this.item.props.square) ? this.item.props.square : this.item.props.square + ',0'
        },
        computed: {
            prepay(){
                return this.item.props.prepay;
            },
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
                masks: state => state.masks.items,
            }),
            calcSquare() {
                let result = parseFloat(this.form.square.value.replace(',', '.')),
                    minArea;

                if (this.item.props.minSquare) {
                    minArea = this.item.props.minSquare;
                } else {
                    minArea = 60;
                }

                if (result < minArea) {
                    result = minArea;
                }

                return result;
            }
        },
    }
</script>

<style scoped>

</style>
