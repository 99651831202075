<template>
    <div>
        <div class="popup__header">
            <div class="title">Что делать с освободившейся<br>датой {{item.busyDayFormat}}?</div>
        </div>


        <div class="popup__content">
            <div>
                <input @change="removeError(form.radio)" v-model="form.radio.value" :class="{error:form.radio.error, 'repeat-error': form.radio.repeatError}" type="radio" id="radio1" value="free">
                <label for="radio1">Освободить для всех желающих</label>
            </div>
            <div class="mt8">
                <input @change="removeError(form.radio)" v-model="form.radio.value" :class="{error:form.radio.error, 'repeat-error': form.radio.repeatError}" type="radio" id="radio2" value="moscow">
                <label for="radio2">Освободить только для обычного замера</label>
            </div>
            <div class="mt8">
                <input @change="removeError(form.radio)" v-model="form.radio.value" :class="{error:form.radio.error, 'repeat-error': form.radio.repeatError}" type="radio" id="radio3" value="noaction">
                <label for="radio3">Оставить занятой</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';

    export default {
        name: "AvailableDate",
        components: {
            SubmitBtn,
        },
        props: {
            date: {
                default: function () {
                    return ''
                }
            }
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if(this.form[field].value.toString().length == 0 || this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });

                    }
                }
                if(!error) {
                    let params = {
                        action: 'AvailableDate',
                        id: this.item.id,
                        params: {
                            calendarDayId: this.item.busyDayId,
                            radio: this.form.radio.value,
                        }
                    };
                    for(let field in this.form){
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        //this.$store.commit('requests/deleteRequest', {id: this.item.id})
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.availableDate.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    radio: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
