<template>
    <div class="worker-select baza-select" ref="inpWrap" :class="{error: error, opened: opened}">
        <div class="text-color-grey text-uppercase text-size-xs">
            {{title}}
        </div>
        <div class="mt8 mb8 choose-block" @click.stop="toggle">
            <div class="text-bold text-size-xxl choosen-elem" v-if="modelValue">
                <span class="mr8 name">{{modelValue}} ч.
                  <div class="choosen-arrow">
                  </div>
                </span>
            </div>
            <a href="javascript:void(0);" class="choose text-bold text-size-xxl" v-else>
                Выбрать
                <div class="arrow">
                </div>
            </a>
            <div class="select-list" v-if="opened">
                <div class="select-item df df-space-between" v-for="i in 24" @click="changeValue(i)">
                    <span class="i i-ok2" v-if="i === currentIndex"></span>
                    <div class="name">{{i}} ч.</div>
                </div>
            </div>
        </div>
        <div class="error-text text-right">
            <span class="text-color-red text-right">Всегда старайтесь дать заказчику<br>как можно МЕНЬШЕ времени</span>
        </div>
    </div>
</template>

<script>
export default {
        name: "InputSelectTime",
        props: {
            modelValue: {default: ''},
            error: false,
            title: {default: ''},
            type: {default: ''},
            errorText: {default: ''},
        },
        data: function(){
            return {
                opened: false,
                currentIndex: '',
            }
        },
        emits: ['update:modelValue', 'toggled'],
        mounted(){

        },
        computed: {

        },
        methods:{
            toggle(){
                this.opened = !this.opened;
                this.$emit('toggled');
            },
            changeValue(index){
                this.currentIndex = index;
                this.$emit('update:modelValue', index);
            }
        },
        watch: {
			error(val,oldVal){
                if(val){
					if(this.$el.closest('.popup-wrapper')){
                        let $top = 0;
                        let pr = this.$el.closest('.popup-wrapper');
                        $top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
                        pr.scrollTo({top: $top, left: 0, behavior: 'smooth'});
                    }
                }
            },
        },
        directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('click', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }
</script>
