<template>
    <div>
        <div class="popup__header">
            <div class="title">Завершить ремонт</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <calendar
                v-if="calendarShow"
                v-model="form.selectedDate"
                :min-date="null"
                :is-timer="false"
                title="Дата окончания ремонта">
            </calendar>
            <div class="text-bold mb12">
                Рекомендуете ли вы снять на объекте видео для нашего канала?
            </div>
            <tumbler @change="removeError(form.option)" :options="options.items.video" :elem-width="214" v-model="form.option.value" :error="form.option.error"/>
            <div class="mt32">
                <textarea-field
                                 :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                 :min-height="86"
                                 :label="'Комментарий к рекомендации по видео'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Например, «Отличный дизайнерский ремонт, много интересных решений по свету, можно сделать мастер-класс об этом...»"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="mt32">
                <div class="text-bold mb12">
                    Как был сдан объект, какие отношения с заказчиком?
                </div>
                <textarea-field
                                 :class="'full'"
                                v-model="form.comment.value"
                                :error-text="''"
                                 :min-height="86"
                                :label="'Как был сдан объект? Какие отношения с заказчиком?'"
                                :is-error="form.comment.error"
                                @focus="form.comment.error = false"
                                placeholder="Например, «Ремонт завершен на позитивной ноте и в срок, с заказчиком прекрасные отношения, советует нас друзьям...»"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Завершены»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'
    import Calendar from '@/components/Calendar.vue';
    export default {
        name: "RepairFinish",
        components: {
            Calendar,
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if((field == 'text') && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if((field == 'comment') && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if((field == 'option') && this.form[field].value === false){
                        errorField = true;
                    }

                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'RepairFinish',
                        id: this.item.id,
                        params: {
                            selectedDate: this.form.selectedDate.date+' '+this.form.selectedDate.time.hours+':'+this.form.selectedDate.time.minutes,
                        }
                    };
                    for(let field in this.form) {
                        if (field !== 'selectedDate') {
                            if( field == 'option'){
                                params.params[field] = this.form[field].value.id;
                            } else {
                                params.params[field] = this.form[field].value;
                            }
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.repairFinish.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            },
            formatDate(date) {
                let dd = date.getDate();
                if (dd < 10) dd = '0' + dd;
                let mm = date.getMonth() + 1;
                if (mm < 10) mm = '0' + mm;
                let yyyy = date.getFullYear();

                return {date:yyyy + '.' + mm + '.' + dd, time:{hours: '12', minutes: '00'}};
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                calendarShow: false,
                form: {
                    selectedDate: {date: '2021.02.1', time: {hours: 12, minutes: 30}},
                    comment: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            let date = new Date();
            date.setHours(date.getHours()+2);
            this.form.selectedDate = this.formatDate(date);
            this.calendarShow = true;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
