<template>
    <div class="">
        <requests-list/>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Popup from '@/components/modal/Popup.vue';
    import RequestsList from '@/components/requests/RequestsList.vue';

    export default {
        name: "RequestsApproved",
        props: {
            status: {
            }
        },
        components: {
            Popup,
            RequestsList
        },
        data: function(){
          return {

          }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
        methods: {
            fetchData () {
                //console.log('fetch');
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
