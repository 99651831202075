<template>
    <div class="popup__header">
        <div class="title">Характеристика заказчика</div>
        <div class="text-color-white-trans text-bold mt4">{{item.props.clientSurname}} {{item.props.clientName}} {{item.props.clientPatronymic}}</div>
    </div>


    <div class="popup__content">
        <template v-if="user.groupCode === 'K' || user.groupCode === 'R' || user.groupCode === 'AR'">
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от замерщика
            </div>
            <div class="mt8" >
                <span v-if="form.textZ.value" v-html="convertNewLine(form.textZ.value)"></span>
                <span v-else>Пока нет характеристики...</span>
            </div>
            <hr>
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="'Характеристика от координатора и руководителя'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Напишите совместную характеристику на заказчика"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
        </template>
        <template v-else-if="user.groupCode === 'Z'">
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от координатора и руководителя
            </div>
            <div class="mt8">
                <span v-if="form.text.value" v-html="convertNewLine(form.text.value)"></span>
                <span v-else>Пока нет характеристики...</span>
            </div>
            <hr>
            <textarea-field :class="'full'"
                            v-model="form.textZ.value"
                            :error-text="''"
                            :label="'Характеристика от замерщика'"
                            :is-error="form.textZ.error"
                            @focus="form.textZ.error = false"
                            placeholder="Напишите характеристику на заказчика"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
        </template>
        <template v-else>
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от координатора и руководителя
            </div>
            <div class="mt8">
                <span v-if="form.text.value" v-html="convertNewLine(form.text.value)"></span>
                <span v-else>Пока нет характеристики...</span>
            </div>
            <hr>
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от замерщика
            </div>
            <div class="mt8" >
                <span v-if="form.textZ.value" v-html="convertNewLine(form.textZ.value)"></span>
                <span v-else>Пока нет характеристики...</span>
            </div>
        </template>

        <div class="df df-space-between mt40 btn-wrapper" v-if="user.groupCode === 'K' || user.groupCode === 'R'|| user.groupCode === 'AR' || user.groupCode === 'Z'">
            <submit-btn @click="send()" tag="button" :type="btnType">
                Сохранить
            </submit-btn>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import { convertNewLine } from "@/helpers/util/helper";

    export default {
        name: "CustomerCharacter",
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                this.form.text.error = false;
                this.form.textZ.error = false;
                if((this.user.groupCode === 'K' || this.user.groupCode === 'R' || this.user.groupCode === 'AR') && this.form.text.value.length == 0){
                    setTimeout(() => {
                        this.form.text.error = true;
                    });

                    error = true;
                }
                if((this.user.groupCode === 'Z') && this.form.textZ.value.length == 0){
                    setTimeout(() => {
                        this.form.textZ.error = true;
                    });
                    error = true;
                }
                if(!error) {
                    let params = {
                        action: 'SetCharacteristic',
                        id: this.item.id,
                        params: {}
                    };
                    if((this.user.groupCode === 'K' || this.user.groupCode === 'R' || this.user.groupCode === 'AR') ){
                        params.params.text = this.form.text.value;
                    }
                    if((this.user.groupCode === 'Z') ){
                        params.params.textZ = this.form.textZ.value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItemProps', {item: this.item, props: {
                            characterKR: this.form.text.value,
                            characterZ: this.form.textZ.value,
                        }});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.customerCharacter.name});
                        },this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    textZ: {
                        value: '',
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {
            this.form.text.value = this.item.props.characterKR;
            this.form.textZ.value  = this.item.props.characterZ;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                user: state => state.auth.user,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
