<template>
    <div>
        <div class="popup__header">
            <div class="title">Данные заказчика</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="">
                <input-styled v-model="form.clientSurname.value"
                              name="clientSurname"
                              :placeholder="'Фамилия'"
                              :placeholder-input="'Иванов'"
                              :isError="form.clientSurname.error"
                              :errorText="form.clientSurname.errorText"
                              @focus="removeError(form.clientSurname)"
                              @input="removeError(form.clientSurname)"></input-styled>
            </div>
            <div class="mt24">
                <input-styled v-model="form.clientName.value"
                              name="clientName"
                              :placeholder="'Имя'"
                              :placeholder-input="'Иван'"
                              :isError="form.clientName.error"
                              :errorText="form.clientName.errorText"
                              @focus="removeError(form.clientName)"
                              @input="removeError(form.clientName)"></input-styled>
            </div>
            <div class="mt24">
                <input-styled v-model="form.clientPatronymic.value"
                              name="clientPatronymic"
                              :placeholder="'Отчество'"
                              :placeholder-input="'Иванович'"
                              :isError="form.clientPatronymic.error"
                              :errorText="form.clientPatronymic.errorText"
                              @focus="removeError(form.clientPatronymic)"
                              @input="removeError(form.clientPatronymic)"></input-styled>
            </div>
            <div class="mt24 focus-clear">
                <input-styled v-model="form.phone.value"
                              name="phone"
                              :mask="masks.phone"
                              :placeholder="'Основной телефон'"
                              :placeholder-input="'Телефон'"
                              :isError="form.phone.error"
                              :is-clear-btn="form.phone.value.length > 1"
                              :errorText="form.phone.errorText"
                              @focus="removeError(form.phone)"
                              @input="removeError(form.phone)"></input-styled>
            </div>
            <div class="mt24">
                <input-styled v-model="form.email.value"
                              name="email"
                              :placeholder="'Email'"
                              :placeholder-input="'Укажите почту'"
                              :isError="form.email.error"
                              :errorText="form.email.errorText"
                              @focus="removeError(form.email)"
                              @input="removeError(form.email)"></input-styled>
            </div>
            <div class="mt24">
                <input-styled v-model="form.skype.value"
                              name="skype"
                              :placeholder="'Skype'"
                              :placeholder-input="'Укажите skype'"
                              :isError="form.skype.error"
                              :errorText="form.skype.errorText"
                              @focus="removeError(form.skype)"
                              @input="removeError(form.skype)"></input-styled>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Сохранить
                </submit-btn>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue'
    import InputStyled from '@/components/forms/InputStyled.vue';
    import validate from '@/helpers/validate';
    export default {
        name: "CustomerData",
        components: {
            InputStyled,
            SubmitBtn
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    if(this.form[field].error){
                        this.form[field].error = false;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                        error = true;
                    }else{
                        let errorField = false;
                        if(this.form[field].value.length == 0){
                            errorField = true;
                        }
                        if(field == 'phone' && !validate.isPhone(this.form[field].value)){
                            errorField = true;
                        }
                        if(field == 'email' && !validate.isEmail(this.form[field].value)){
                            errorField = true;
                        }
                        if(errorField){
                            error = true;
                            this.form[field].error = true;
                        }
                    }
                }
                if(!error) {
                    let params = {
                        action: 'SetClientData',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form){
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItemProps', {item: this.item, props: {
                                clientSurname: this.form.clientSurname.value,
                                clientName: this.form.clientName.value,
                                clientPatronymic: this.form.clientPatronymic.value,
                                clientEmail: this.form.email.value,
                                clientPhone: this.form.phone.value,
                                clientSkype: this.form.skype.value,
                            }});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.customerData.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    clientSurname: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите фамилию'
                    },
                    clientName: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите имя'
                    },
                    clientPatronymic: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите отчество'
                    },
                    email: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите почту'
                    },
                    phone: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите телефон'
                    },
                    skype: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: 'Укажите скайп'
                    },
                },

            }
        },
        mounted() {
            this.form.clientSurname.value = this.item.props.clientSurname;
            this.form.clientName.value  = this.item.props.clientName;
            this.form.clientPatronymic.value  = this.item.props.clientPatronymic;
            this.form.email.value  = this.item.props.clientEmail;
            this.form.phone.value  = this.item.props.clientPhone;
            this.form.skype.value  = this.item.props.clientSkype;
        },
        computed: {
            ...mapState({
                masks: state => state.masks.items,
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
