<template>
    <div class="worker-select" :class="{error: error, opened: opened}">
        <div class="text-color-grey text-uppercase text-size-xs">
            {{title}}
        </div>
        <div class="mt8 mb8 choose-block" @click.stop="toggle">
            <div class="text-bold text-size-xxl dif" v-if="modelValue.firstName.length > 0">
                <span class="mr8 name">{{modelValue.firstName}} {{modelValue.lastName}}</span>
                <span class="member" :class="[type]">{{type}}{{modelValue.number}}</span>
            </div>
            <a href="javascript:void(0);" class="choose text-bold text-size-xxl" v-else>
                Выбрать
                <div class="arrow">

                </div>
            </a>
            <div class="select-list" v-show="opened">
                <div v-if="type == 'p' || type == 't'" class="select-item" v-for="(worker, index) in workers" @click="changeValue(worker, index)" :key="worker+index">
                    <span class="i i-ok2" v-if="index === currentIndex"></span>
                    <div class="num">{{type}}{{worker.number}}</div>
                    <div class="name">{{worker.firstName}} {{worker.lastName}}<br>
                    <div class="name-ext">Всего в работе: {{(+worker.modelsSquare + +worker.drawingsSquare).toFixed(1)}} м<sup>2</sup></div></div>
                    <div class="count" v-if="type !== 'r' && type !== 'z'" :style="{'width': '64px'}">
                        <div class="df df-space-between">
                            <span>3D:</span>
                            <span class="text-bold df" style="text-transform: lowercase;"><div :style="{'width': '11px'}" class="text-right mr4">{{worker.models}}</div><div class="text-right" :style="{'width': '33px'}">{{worker.modelsSquare}} м<sup>2</sup></div></span>
                        </div>
                        <div class="df df-space-between">
                            <span>2D:</span>
                            <span class="text-bold df" style="text-transform: lowercase;"><div :style="{'width': '11px'}" class="text-right mr4">{{worker.drawings}}</div><div class="text-right" :style="{'width': '33px'}">{{worker.drawingsSquare}} м<sup>2</sup></div></span>
                        </div>
                    </div>
					<div class="count" v-if="type === 'z'">
                        <div class="df df-space-between">
                            <div class="mr4">Замеров:</div>
                            <div class="text-bold">{{worker.cnt}}</div>
                        </div>
                    </div>
                </div>
                <div v-else class="select-item" v-for="(worker, index) in workers" @click="changeValue(worker, index)">
                    <span class="i i-ok2" v-if="index === currentIndex"></span>
                    <div class="num">{{type}}{{worker.number}}</div>
                    <div class="name">{{worker.firstName}} {{worker.lastName}}</div>
                    <div class="count" v-if="type !== 'r' && type !== 'k' && type !== 'z'">
                        <div class="df df-space-between">
                            <span>3d-модели:</span>
                            <span class="text-bold">{{worker.models}}</span>
                        </div>
                        <div class="df df-space-between">
                            <span>Чертежи:</span>
                            <span class="text-bold">{{worker.drawings}}</span>
                        </div>
                    </div>
                    <div class="count" v-if="type === 'z' || type === 'r' || type === 'k'">
                        <div class="df df-space-between">
                            <div v-if="type === 'z'" class="mr4">Замеров:</div>
                            <div v-else class="mr4">Объектов:</div>
                            <div class="text-bold">{{worker.cnt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-color-grey text-size-xs dif text-uppercase" v-if="modelValue.firstName.length > 0 && type != 'r' && type != 'z' && type != 'k'">
            <span>в работе: 3d-модели – {{modelValue.models}}, ЧЕРТЕЖИ – {{modelValue.drawings}}</span>
        </div>
		<div class="text-color-grey text-size-xs dif text-uppercase" v-if="modelValue.number > 0 && type == 'r' && !changePerson">
            <span>КОМАНДА {{modelValue.number}}</span>
        </div>
        <div class="text-color-grey text-size-xs dif text-uppercase" v-if="modelValue.firstName.length > 0 && (type == 'k' && changePerson || type == 'r' && changePerson)">
            <span>ОБЬЕКТОВ: {{modelValue.cnt}}</span>
        </div>
		<div class="text-color-grey text-size-xs text-uppercase df" v-if="modelValue.firstName.length > 0 && type == 'z'">
            <div>ЗАМЕРОВ:</div><div class="text-bold ml4">{{modelValue.cnt}}</div>
        </div>
        <div class="error-text" v-if="error">
            <span class="i i-info3 i--red mr4"></span>
            <span class="text-uppercase">{{errorText}}</span>
        </div>
    </div>
</template>

<script>
export default {
        name: "WorkerSelect",
        props: {
            modelValue: {default: ''},
            error: false,
            workers: [],
            title: {default: ''},
            type: {default: ''},
            changePerson: {default: false},
            errorText: {default: ''},
        },
        data: function(){
            return {
                opened: false,
                currentIndex: '',
            }
        },
        emits: ['update:modelValue', 'toggled'],
        mounted(){
        },
        computed: {

        },
        methods:{
            toggle(){
                this.opened = !this.opened;
				if(this.modelValue.firstName.length > 0 && this.currentIndex === ''){
					this.currentIndex = this.workers.findIndex((item,i,arr) => item.id == this.modelValue.id);
	            }
                this.$emit('toggled');
            },
            changeValue(option, index){
                this.currentIndex = index;
                //console.log(index);
                this.$emit('update:modelValue', option);
            },
        },
        watch: {
        },
        directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('click', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }
</script>
<style scoped>
.worker-select .select-list .select-item .name-ext{
    font-size: 10px;
    color: rgba(84, 84, 84, 0.5);
}
</style>
