<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    name: "NpsCollect",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        openEditMasterComment(item) {
            this.$store.commit('setMasterComment', item);
            this.$store.commit('setMasterCommentVisible', true);
        },
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold">недозвон
                {{ item.props.autosale_mark_date }}</a>
        </template>

        <template v-slot:tooltipContent>
            <span v-html="convertNewLine(item.props.nps_status_comment )"></span><br> <br>
            {{ item.props.nps_status_user.lastName }} {{ item.props.nps_status_user.firstName }}
            ({{ item.props.nps_status_user.groupCode + item.props.nps_status_user.number }}),
            {{ item.props.autosale_mark_date }}
            <br><br>
            <a @click="openEditMasterComment(item);" href="javascript:void(0);"
               v-if="hasState(['repair_think_M','repair_reject_M','repair_prepay_M','repair_aside_M','repair_in_process_M','repair_terminate_M', 'repair_finish_M'])">
                <span class="i i-pencil2 --edit i--blue "></span>
                Редактировать
            </a>
        </template>

    </tooltip>
</template>

<style scoped>

</style>