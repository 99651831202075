<template>
    <div class="calendar" :class="{'timer-left': timerLeft, 'showed': !cdHidden}">
        <div class="calendar__picked">
            <div class="title text-uppercase df" v-if="!noTitle">
                <div class="text-color-grey text-size-xs remote" v-if="typeMetering && typeMetering=='remote'">дистанционный замер</div>
                <div class="text-color-grey text-size-xs normal" v-else-if="typeMetering && typeMetering=='normal'">обычный замер</div>
                <div class="text-uppercase text-size-xs text-bold" v-else-if="title.length > 0">{{ title }}</div>
                <div class="text-uppercase text-size-xs text-bold" v-else-if="!isCall">Дата замера</div>
                <div class="text-bold text-uppercase text-size-xs" v-else-if="isCall">Дата Созвона</div>
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 text-uppercase df" v-if="cdHidden">
                {{currentDate}}<span class="i-wrapper pencil ml4" @click="openCalendar"><span class="i i-pencil2 i--blue"></span><span class="i i-pencil2-active i--blue"></span></span>
            </div>
            <div class="text-color-grey text-size-xs df text-uppercase" v-if="cdHidden">
                <span class="mr8" v-html="hintText"></span>
                <tooltip
                        v-if="putAsideYes"
                        :direction="'right-center-direction'"
                        :is-btn-close="false"
                        :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <span class="link">
                            Готовы раньше
                        </span>
                    </template>

                    <template v-slot:tooltipContent><span v-html="convertNewLine(putAsideComment)"></span></template>
                </tooltip>
            </div>
        </div>
        <div class="calendar__date-picker" v-if="!cdHidden">
            <div class="nav-arrow left" @click="slideLeft">
                <span class="i i-calendar-arrow"></span>
            </div>
            <div class="nav-arrow right" @click="slideRight">
                <span class="i i-calendar-arrow"></span>
            </div>
            <v-date-picker @update:modelValue="updateValue" v-model="date" :attributes="attrs" :min-date='minDate' :disabled-dates="disabledDates" :locale="{masks: { weekdays: 'WW'}}" :model-config="modelConfig" ref="calendar"/>
        </div>
        <div class="calendar__time-picker text-bold text-size-xxl" v-if="isTimer">
            <div class="hours time-input">
                <div class="up time-input__arrow" @click="addHour"><span class="i i-arrow2"></span></div>
                <div>{{hours}}</div>
                <div class="down time-input__arrow" @click="removeHour"><span class="i i-arrow2"></span></div>
            </div>
            <div class="ml4 mr4">:</div>
            <div class="minutes time-input">
                <div class="up time-input__arrow" @click="addMinutes"><span class="i i-arrow2"></span></div>
                <div>{{minutes}}</div>
                <div class="down time-input__arrow" @click="removeMinutes"><span class="i i-arrow2"></span></div>
            </div>
            <div class="after text-uppercase" v-if="!cdHidden">
                <span v-html="hintText" style="white-space: pre-wrap;"></span>
            </div>

			<div class="switch" v-if="isUrgent">
                <input v-model="switch1.value" type="checkbox" id="switch1" @change="switchChanged">
                <label for="switch1">срочный замер</label>
			</div>
        </div>
    </div>
</template>

<script>
    import Tooltip from '@/components/Tooltip.vue';
    import { convertNewLine } from "@/helpers/util/helper";

    export default {
        name: "Calendar",
        components: {
            Tooltip,
        },
        props: {
            modelValue: {},
            disabledDates: null,
			isUrgent: {
				default: function(){
					return false;
				}
			},
            noTitle: {
                default: function(){
                    return false;
                }
            },
            title: {
                default: function(){
                    return '';
                }
            },
            timerLeft: {
                default: function(){
                    return false
                }
            },
            calendarHidden: {
                default: function(){
                    return false
                }
            },
            typeMetering:{
                default: function(){
                    return false
                }
            },
            isCall: {
                default: function(){
                    return false
                }
            },
            measureDate: {
                default: function(){
                    return false
                }
            },
            putAsideYes: {
                default: function(){
                    return false
                }
            },
			isTimer: {
                default: function(){
                    return true
                }
            },
            putAsideComment: {
                default: function(){
                    return ""
                }
            },
            minDate: {
                default: function(){
                    return new Date()
                }
            }
        },
        watch: {
            calendarHidden(val, oldVal){
                this.cdHidden = val;
            }
        },
        data: function () {
            return {
                //date: null,
                modelConfig:{
                    type: 'string',
                    mask: 'YYYY.MM.DD',
                },
                attrs: [
                    {
                      key: 'today',
                      dot: true,
                      dates: new Date(),
                    },
                 ],
                 //minutes: '00',
                 //hours: '11',
                 weekdays: ['воскресенье','понедельник','вторник','среда', 'четверг','пятница', 'суббота'],
                 months: ['янв','фев','мар', 'апр','мая', 'июн','июл','авг','сен','окт','ноя','дек'],
                 cdHidden: false,
                 hint: '',
				 switch1: {
					 value: false,
					 error: false,
					 repeatError: false,
				 },
            }
        },
        computed: {
            date: {
                get() {
                    //setTimeout(()=>{
                        //this.$refs.calendar.focusDate(this.modelValue.date);
                        //console.log(this.modelValue.date);
                    //}, 5);
                    return this.modelValue.date;
                },
                set(value) {}
            },
            minutes: {
                get() {
                    return this.modelValue.time.minutes;
                },
                set(value) {
                    this.modelValue.time.minutes = value;
                }
            },
            hours: {
                get() {
                    return this.modelValue.time.hours;
                },
                set(value) {
                    this.modelValue.time.hours = value;
                }
            },
            currentDate(){
                let date = this.date.split('.');
                let choosenDate = this.date instanceof Date ? this.date : new Date(date[0], date[1] - 1, date[2]);
                return choosenDate.getDate() + ' ' + this.months[choosenDate.getMonth()] + ' ' + choosenDate.getFullYear()
            },
            daysCount(){
                let today = new Date();
                let date = this.date.split('.');
                let choosenDate = this.date instanceof Date ? this.date : new Date(date[0], date[1] - 1, date[2]);
                return ((choosenDate - today.getTime()) / (1000 * 3600 * 24)).toFixed(0)
            },
            weekday(){
                let date = this.date.split('.');
                let choosenDate = this.date instanceof Date ? this.date : new Date(date[0], date[1] - 1, date[2]);
                return this.weekdays[choosenDate.getDay()];
            },
            hintText(){
                let txt;
                if(!this.isCall){
                    txt = 'Через ' + this.daysCount + ' дн. \n(' + this.weekday + ')'
                }else if(!this.measureDate){
                    //let days = this.daysCount * 1 +1; // Закомментил тк не верно отображалась дата в модалках Назначить согласование, Принять решение по ремонту
                    let days = this.daysCount * 1;
                    let cH = this.hours;
                    let cDate = this.date instanceof Date ? this.date : new Date(this.date);
                    let today = new Date();
                    let h,when;

                    switch(days){
                        case 0:
                            when = 'СЕГОДНЯ';
                            h = cH - today.getHours();
                            txt = when + ', \nЧЕРЕЗ ' + h + ' ч.';
                            break;
                        case 1:
                            when = 'ЗАВТРА';
                            h = (days * 24) + (this.hours - today.getHours());
                            txt = when + ', \nЧЕРЕЗ ' + h + ' ч.';
                            break;
                        case 2:
                            when = 'ПОСЛЕЗАВТРА';
                            h = (days * 24) + (this.hours - today.getHours());
                            txt = when + ', \nЧЕРЕЗ ' + h + ' ч.';
                            break;
                        default:
                            when = 'ЧЕРЕЗ ' + days + ' ДН.'
                            h = (days * 24) + (this.hours - today.getHours());
                            txt = when + ' \n(' + this.weekday + ')'
                            break;
                    }
                }else{
                    let mDateArr = this.measureDate.date.split('.');
                    let mDate = this.measureDate.date instanceof Date ? this.measureDate.date : new Date(mDateArr[0], mDateArr[1] - 1, mDateArr[2]);


                    let date = this.date.split('.');
                    let choosenDate = this.date instanceof Date ? this.date : new Date(date[0], date[1] - 1, date[2]);


                    let days = ((mDate.getTime() -choosenDate) / (1000 * 3600 * 24)).toFixed(0);
                    if(days > 0){
                        txt = 'ЗА ' + days + ' ДН. \nДО ЗАМЕРА \n(' + this.weekday + ')';
                    }else{
                        txt = 'В ДЕНЬ ЗАМЕРА \n(' + this.weekday + ')';
                    }
                }
                return txt
            },
        },
        mounted: function () {
            /*this.date = this.modelValue.date;
            if(this.modelValue.time){
                this.minutes = this.modelValue.time.minutes < 10 ? '0' + this.modelValue.time.minutes : this.modelValue.time.minutes;
                this.hours = this.modelValue.time.hours < 10 ? '0' + this.modelValue.time.hours : this.modelValue.time.hours;
            }*/
            this.cdHidden = this.calendarHidden;
        },
        emits: ['update:modelValue', 'calendarshow','switchChanged'],
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
			switchChanged(){
			    this.$nextTick(()=>{
                    this.$emit('switchChanged', this.switch1.value);
                })
			},
			switchSet(value){
			    this.$nextTick(()=>{
                    this.switch1.value = value;
                    this.$emit('switchChanged', value);
                })
			},
            openCalendar(){
                this.cdHidden = false;
                this.$emit('calendarshow');
            },
            updateValue(value){
                let val = {date: value instanceof Date ? this.formatDate(value) : value, time: {hours: this.hours, minutes: this.minutes}};
                this.$emit('update:modelValue', val);
            },
            slideLeft(){
                this.$refs.calendar.move(-1);
            },
            slideRight(){
                this.$refs.calendar.move(1);
            },
            addHour(){
                let h = this.hours;
                if(h == 23){
                    this.hours = '00';
                }else if(h == '00'){
                    this.hours = '01';
                }else{
                    this.hours = h * 1 + 1 < 10 ? '0' + (h * 1 + 1) : h * 1 + 1;
                }
                this.updateValue(this.date);
            },
            removeHour(){
                let today = new Date();
                let h = this.hours;

                if((this.daysCount == -1) && (h - today.getHours() < 1)){ return;}

                if(h == '01'){
                    this.hours = '00';
                }else if(h == '00'){
                    this.hours = 23;
                }else{
                    this.hours = h * 1 + 1 < 12 ? '0' + (h * 1 - 1) : h * 1 - 1;
                }
                this.updateValue(this.date);
            },
            addMinutes(){
                let m = this.minutes;
                if(m * 1 + 15 == '60'){
                    this.minutes = '00';
                }else{
                    this.minutes= m * 1 + 15 < 10 ? '0' + (m * 1 + 15) : m * 1 + 15;
                }
                this.updateValue(this.date);
            },
            removeMinutes(){
                let m = this.minutes;
                if(m == '00'){
                    this.minutes = '45';
                }else{
                    this.minutes= m * 1 - 15 < 10 ? '0' + (m * 1 - 15) : m * 1 - 15;
                }
                this.updateValue(this.date);
            },
            formatDate(date) {
              let dd = date.getDate();
              if (dd < 10) dd = '0' + dd;

              let mm = date.getMonth() + 1;
              if (mm < 10) mm = '0' + mm;

              let yyyy = date.getFullYear();

              return yyyy + '.' + mm + '.' + dd;
            }
        },
    }
</script>

<style scoped>
</style>
