<script>
import Tooltip from '@/components/Tooltip.vue';
export default {
    name: "RepairComment",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
    },
    methods: {
        openEditMasterComment(item) {
            this.$store.commit('setMasterComment', item);
            this.$store.commit('setMasterCommentVisible', true);
        },
        convertNewLine(str) {
            return str.replace(/\n/g, "<br />");
        },
        hasState(states) {
            return states.includes(this.item.props.state);
        }
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :add-class-wrap="'details-tooltip'"
        :isChangeablePos="false"
        :add-style="{width: '392px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);" class="link ml0 link-yellow text-size-xs text-uppercase text-bold"
               v-html="requestStatusTimePassed">

            </a>
        </template>

        <template v-slot:tooltipContent>
            <div class="">
                <div class="" v-if="item.props.repairComment && item.props.repairFinishComment">
                    <div class="text-bold">
                        Рекомендую снять видео:
                    </div>
                    <br>
                    <span v-html="convertNewLine(item.props.repairComment )"></span><br><br>
                    {{ item.props.repairCommentUser.lastName }} {{ item.props.repairCommentUser.firstName }}
                    ({{ item.props.repairCommentUser.groupCode + item.props.repairCommentUser.number }}),
                    {{ item.props.statusDate }}
                </div>

                <hr style="margin-top: 16px; margin-bottom: 16px;"
                    v-if="item.props.repairComment && item.props.repairFinishComment">

                <div class="" v-if="item.props.acceptReportComment && !hasState([ 'repair_finish_*'])">
                    <div class="text-bold">
                        Принять отчёт:
                    </div>
                    <br>
                    <span v-html="convertNewLine(item.props.acceptReportComment)"></span>
                    <br><br>
                    {{ item.props.acceptReportUser.lastName }} {{ item.props.acceptReportUser.firstName }}
                    ({{
                        item.props.acceptReportUser.groupCode + (item.props.acceptReportUser.number ? item.props.acceptReportUser.number : '')
                    }}), {{ item.props.repairMasterStartDate }}
                </div>

                <hr style="margin-top: 16px; margin-bottom: 16px;"
                    v-if="item.props.acceptReportComment && !hasState([ 'repair_finish_*'])">

                <div class="" v-if="item.props.repairComment && !item.props.repairFinishComment">
                    <div class="text-bold">
                        Взять в работу:
                    </div>
                    <br>
                    <span v-html="convertNewLine(item.props.repairComment )"></span>
                    <br><br>
                    {{ item.props.repairCommentUser.lastName }} {{ item.props.repairCommentUser.firstName }}
                    ({{
                        item.props.repairCommentUser.groupCode + (item.props.repairCommentUser.number ? item.props.repairCommentUser.number : '')
                    }}), {{ item.props.statusDate }}

                </div>

                <hr style="margin-top: 16px; margin-bottom: 16px;"
                    v-if="!item.props.repairComment && item.props.repairFinishComment">

                <div class="" v-if="item.props.repairFinishComment">
                    <div class="text-bold" v-if="item.props.repairFinishComment">
                        Как был сдан объект? Какие отношения с заказчиком?
                    </div>
                    <br>
                    <span v-html="convertNewLine(item.props.repairFinishComment)"></span>
                    <br><br>
                    {{ item.props.repairCommentUser.lastName }} {{ item.props.repairCommentUser.firstName }}
                    ({{ item.props.repairCommentUser.groupCode + item.props.repairCommentUser.number }}),
                    {{ item.props.statusDate }}
                </div>

                <br>
                <a @click="openEditMasterComment(item);" href="javascript:void(0);"
                   v-if="hasState(['repair_think_M','repair_reject_M','repair_prepay_M','repair_aside_M','repair_in_process_M','repair_terminate_M'])">
                    <span class="i i-pencil2 --edit i--blue "></span>
                    Редактировать
                </a>

            </div>
        </template>
    </tooltip>
</template>

<style scoped>

</style>