<template>
    <div class="top__item date-block"  :class="{mr40: hasState(['repair_in_process_*','repair_terminate_*','repair_finish_*'])}">
        <span class="text-color-grey text-size-xs">Площадь</span>
        <span class="text-bold text-size-xxl mt8 mb8">{{ item.props.flatArea }} м<sup>2</sup></span>
        <span class="text-color-grey text-size-xs">{{ item.props.flatType }}</span>
    </div>
</template>

<script>
export default {
    name: "TopItemBlockArea",
    props: {
        item: {}
    },
    methods: {
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
    }
}
</script>

<style scoped>

</style>