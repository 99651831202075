<template>
    <div>
        <div class="popup__header">
            <div class="title">Назначить исполнителя</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-color-grey text-uppercase text-size-xs">
                Характеристика от замерщика
            </div>
            <div class="mt8" v-html="convertNewLine(item.props.characterZ)"></div>

            <div class="mt40 mb40">
                <textarea-field :class="'full'"
                                v-model="form.characterKR.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.characterKR.error"
                                @focus="form.characterKR.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <div class="text-color-grey text-uppercase text-size-xs mt40 mb8">Будем предлагать ремонт?</div>
            <tumbler @change="removeError(form.option)" :options="optionsMaster" :elem-width="222" v-model="form.option.value" :error="form.option.error"/>
            <div class="mt40">
                <textarea-field :class="'full'"
                                v-model="form.status_comment.value"
                                :error-text="''"
                                :label="'Служебный комментарий'"
                                :is-error="form.status_comment.error"
                                @focus="form.status_comment.error = false"
                                placeholder="Напишите служебный комментарий..."
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Я в курсе, что руководитель ремонтов увидит мой комментарий</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Ремонты / Новые»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import {convertNewLine} from "@/helpers/util/helper";

export default {
        name: "PurposeMasterSet",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            TextareaField
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if((field == 'status_comment' || field == 'characterKR') && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if((field == 'checkbox1') && this.form[field].value === false){
                        errorField = true;
                    }
                    if(field === 'option' && this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'PurposeMasterSet',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field === 'option' && this.form[field].value !== false){
                            params.params[field+'Id'] = this.form[field].value.id;
                            params.params[field+'User'] = this.form[field].value.user;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.purposeSetMaster.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    characterKR: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    status_comment: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    option: {
                        value: false,
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {
            this.form.characterKR.value = this.item.props.characterKR;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
            optionsMaster(){
                return [
                    {id: 134590, user: 411, name: 'Саратов'},
                    {id: 149804, user: 6228, name: 'ФЁДОР (S-GROUP)'},
                ]
            },
        },
    }
</script>

<style scoped>

</style>
