<template>
    <div>
        <div class="popup__header">
            <div class="title">Сообщить об отказе</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-color-grey text-uppercase text-size-xs">
                Причина отказа
            </div>
            <div class="text-bold mt8">
                {{ item.props.reasonCancel }}
            </div>
            <div class="mt8"  v-if="item.extProps.team">
                {{ item.extProps.team.users.leader.lastName }} {{ item.extProps.team.users.leader.firstName }}, Руководитель проекта
            </div>

            <hr>

            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :auto-focus="true"
                            :label="'Причина отклонения'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Укажите причину отклонения"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Заказчику объяснены причины отклонения</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.checkbox2)" v-model="form.checkbox2.value" :class="{error:form.checkbox2.error, 'repeat-error': form.checkbox2.repeatError}" type="checkbox" id="checkbox2">
                <label for="checkbox2">Я в курсе, что заказчику будет отправлено уведомление об отказе</label>
            </div>

            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Отклонить заявку
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Заявка будет перемещена<br>
                    в раздел «Отклонённые»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';

    export default {
        name: "RejectReport",
        components: {
            SubmitBtn,
            TextareaField,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(this.form[field].value.toString().length == 0 || this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'RejectRequest',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form){
                        params.params[field] = this.form[field].value;
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id})
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.rejectReport.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    checkbox2: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: 'Руководитель проекта отказал в сотрудничестве.',
                        error: false,
                        repeatError: false,
                    }
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
