<template>
    <div>
        <div class="popup__header">
            <div class="title">Запросить подтверждение</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">

            <div class="download-concept no-hover">
                <div class="files">
                    <a target="_blank" :href="appPath + item.props.conceptFile.path" class="file img old" :style="{'background-image':' url(\'' + appPath + item.props.conceptFile.icon +  '\')'}">
                        <div v-if="item.props.conceptFileNew.path" class="type">Старая</div>
                    </a>
                    <a v-if="item.props.conceptFileNew.path" target="_blank" :href="appPath + item.props.conceptFileNew.path" class="file img new" :style="{'background-image':' url(\'' + appPath + item.props.conceptFileNew.icon +  '\')'}">
                        <div class="type">Новая</div>
                    </a>
                </div>
            </div>
            <div class="mt40">
                <worker-select v-model="form.workerTech" :workers="workers.technologists" :title="'Технолог'" :type="'t'" :error="form.workerTech.error" :error-text="'Выберите технолога'" @toggled="removeError(form.workerTech)"/>
            </div>
            <hr>
            <div class="mt40">
                <worker-select v-model="form.workerCheck" :workers="workers.inspectors" :title="'Проверяющий'" :type="'p'" :error="form.workerCheck.error" :error-text="'Выберите проверяющего'" @toggled="removeError(form.workerCheck)"/>
            </div>
            <hr>

            <div class="mb40">
                <textarea-field :class="'full'"
                                v-model="form.text.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.text.error"
                                @focus="form.text.error = false"
                                placeholder="Укажите характеристику"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <span class="text-italic text-color-grey ">
                Заказчику будет отправлена СМС и Email со ссылкой на подтверждение концепции и просьбой сделать это как можно скорей.<br><br>
                Уведомление технологу о назначении будет направлено только после того, как заказчик подтвердит концепцию.
            </span>


            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType" :class="{disabled: false}">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Ожидают подтверждения»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import WorkerSelect from '@/components/forms/WorkerSelect.vue';

    export default {
        name: "RequestConceptConfirm",
        components: {
            SubmitBtn,
            InputStyled,
            TextareaField,
            WorkerSelect,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(field == 'text' && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if((field == 'workerTech' || field == 'workerCheck') && !this.form[field].id){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'RequestConceptConfirm',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field == 'text'){
                            params.params[field] = this.form[field].value;
                        } else {
                            params.params[field] = this.form[field].id;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.requestConceptConfirm.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                appPath: process.env.VUE_APP_PATH_BACK,
                form: {
                    workerTech: {
                        id: '',
                        firstName: '',
                        lastName: '',
                        number: '',
                        models: '',
                        drawings: '',
                        error: false,
                    },
                    workerCheck: {
                        id: '',
                        firstName: '',
                        lastName: '',
                        number: '',
                        models: '',
                        drawings: '',
                        error: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },

            }
        },
        mounted() {
            this.form.text.value = this.item.props.characterKR;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                workers: state => state.requests.workers,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
