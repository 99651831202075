<script>
import Tooltip from '@/components/Tooltip.vue';
import {convertNewLine} from "@/helpers/util/helper";

export default {
    name: "NpsMeasure",
    components: { Tooltip },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        convertNewLine(text) {
            return convertNewLine(text);
        },
    }
}
</script>

<template>
    <tooltip
        :direction="'bottom-right-direction'"
        :is-btn-close="false"
        :isChangeablePos="false"
        :add-class-wrap="'details-tooltip ml16'"
        :add-style="{width: 264 + 'px'}">
        <template v-slot:tooltipBtn>
            <a href="javascript:void(0);"
               class="link link-yellow ml16 text-size-xs text-uppercase text-bold">{{
                    item.props.nps_mark
                }} из 10</a>
        </template>
        <template v-slot:tooltipContent>
                        <span v-if="item.props.nps_mark_comment"
                              v-html="convertNewLine(item.props.nps_mark_comment)"></span><span v-else>Комментария нет</span>
            <br><br>
            {{ item.props.clientName }} {{ item.props.clientPatronymic }} (заказчик),
            {{ item.props.nps_mark_date }}
        </template>
    </tooltip>
</template>

<style scoped>

</style>