<template>
    <div class="top__item date-block" v-if="item.props.contractNumber && !(user.groupCode == 'T' || user.groupCode == 'P') && !hasRepair">
        <span class="text-color-grey text-size-xs">Договор</span>
        <span class="text-bold text-size-xxl mt8 mb8">{{ item.props.contractNumber }}</span>
        <span class="text-color-grey text-size-xs" v-if="item.props.typePay == 165">оплата наличным</span>
        <span class="text-color-grey text-size-xs" v-if="item.props.typePay == 186">оплата картой</span>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "TopItemBlockContract",
    props: {
        item: {}
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        hasRepair(){
            return this.item.extProps.state.indexOf('repair') !== -1 || this.item.extProps.state.indexOf('repair_sale') !== -1;
        },
    },
    methods: {
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
    }
}
</script>

<style scoped>

</style>