<template>
    <div class="top__item date-block" v-if="hasState(['repair_in_process_*','repair_terminate_*','repair_finish_*'])">
        <span class="text-color-grey text-size-xs" >сумма отчёта</span>
        <span class="text-bold text-size-xxl mt8 mb8" >{{ item.props.masterSumReport }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
        <span class="text-color-grey text-size-xs" >{{ item.props.repairMasterPercent }}% от {{ item.props.priceRepairContract }} ₽</span>
    </div>
    <div class="top__item date-block" v-else-if="!(user.groupCode == 'T' || user.groupCode == 'P') && !hasState(['repair_sale_new_*','repair_sale_think_*','repair_sale_masters_late_*','repair_sale_masters_set_*','repair_sale_no_masters_*','repair_request_*','repair_prepay_*','repair_think_*','repair_reject_*','repair_aside_*','repair_in_process_*','repair_terminate_*','repair_finish_*'])">
        <div class="text-color-grey text-size-xs" v-html="costString"></div>
        <span class="text-bold text-size-xxl mt8 mb8">{{ item.props.price }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></span>
        <span class="text-color-grey text-size-xs">{{item.props.typeTax}}</span>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "TopItemBlockReport",
    props: {
        item: {}
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        costString() {
            let res = 'Стоимость',
                color = '',
                costPerMeter = parseFloat(this.item.props.costPerMeter);

            if (costPerMeter === 2500) {
                color = 'red'
            }
            if (costPerMeter === 3000) {
                color = 'yellow'
            }
            if (costPerMeter === 4000) {
                color = 'green'
            }
            res += ' <span class="text-color-grey text-size-xs ml8 remote '+ color +'">' + costPerMeter + '&nbsp;<span class="rouble" style="line-height: 12px;">' + this.$store.getters['options/setRouble'] + '</span></span>';
            return res;
        },
    },
    methods: {
        hasState(list) {
            let state = this.item.extProps.state;
            for(let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },

    }
}
</script>

<style scoped>

</style>