<template>
<div>
    <div class="popup__header">
        <div class="title">Сменить команду</div>
        <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
    </div>
    <div class="popup__content">
        <div class="mt40">
            <worker-select v-model="form.workerLeader" :workers="allWorkers.leaders" :title="'Руководитель'" :type="'r'" :error="form.workerLeader.error" :error-text="'Выберите технолога'" @toggled="removeError(form.workerLeader)"/>
        </div>
        <div class="df df-space-between mt40">
            <submit-btn @click="send" tag="button" :type="btnType" :class="{disabled: false}">
                подтвердить
            </submit-btn>

            <!--<span class="text-right text-size-xs text-color-grey text-italic">
                Объект будет перемещён в «Ожидают подтверждения»
            </span>-->
        </div>
    </div>
</div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import WorkerSelect from '@/components/forms/WorkerSelect.vue';

    export default {
        name: "ChangeCommand",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn,
            WorkerSelect
        },
        mounted() {
            /*if(this.item.extProps.staff.R){
                let R = this.item.extProps.staff.R;
                this.form.workerLeader.id =  R.id;
                this.form.workerLeader.firstName =  R.firstName;
                this.form.workerLeader.lastName =  R.lastName;
                this.form.workerLeader.number =  R.number;
            }*/
            if(this.item.extProps.team.users){
                let R = this.item.extProps.team.users.leader;
                this.form.workerLeader.id =  R.id;
                this.form.workerLeader.firstName =  R.firstName;
                this.form.workerLeader.lastName =  R.lastName;
                this.form.workerLeader.number =  R.number;
            }
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                if(!error) {
                    let params = {
                        action: 'RequestChangeCommand',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field == 'text'){
                            params.params[field] = this.form[field].value;
                        } else {
                            params.params[field] = this.form[field].id;
                        }
                    }
                    this.btnType = 'load';

                    if(this.form.workerLeader.id != this.item.extProps.team.users.leader.id){
                        this.$store.dispatch('requests/actionRequest', params).then(() => {
                            this.btnType = 'ok';
                            this.$store.commit('requests/deleteRequest', {id: this.item.id});
                            setTimeout(()=>{
                                this.$store.commit('popups/close', {name: this.popups.changeCommand.name});
                            }, this.options.delayPopupClose);
                        },()=>{});
                    }
                    // Если не изменилась команда
                    else{
                        this.btnType = 'ok';
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.changeCommand.name});
                        }, this.options.delayPopupClose);  
                    }
                }   
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                appPath: process.env.VUE_APP_PATH_BACK,
                form: {
                    workerLeader:{
                        id: '',
                        firstName: '',
                        lastName: '',
                        number: '',
                        models: '',
                        drawings: '',
                        error: false,
                    }
                }
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                options: state => state.options,
                user: state => state.auth.user,
                item: state => state.popups.curItemData,
                optionsMain: state => state.options,
                allWorkers: state => state.requests.allWorkers,
            }),
        },
    }
    </script>

<style scoped>

</style>
