<template>
    <div>
        <div class="popup__header">
            <div class="title">Загрузить концепцию и замер</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="download-concept">
                <div class="df df-space-between input">
                    <div class="" style="width: 211px">
                        <button @click="openInputConcept()" type="button" name="button" class="btn btn--trans full">Загрузить концепцию</button>
                        <input style="opacity: 0;position: absolute; width: 0;" ref="fileInputConcept" @change="uploadFile($event, form.fileInputConcept)" type="file">
                    </div>
                    <span>или</span>
                    <div class="" style="width: 211px;">
                        <input-styled v-model="screenshot1.value"
                                      name="area"
                                      :placeholder="'Ссылка  на скриншот'"
                                      :placeholder-input="'Укажите ссылку на скриншот'"
                                      :isError="screenshot1.error"
                                      :errorText="screenshot1.errorText"
                                      @focus="removeError(screenshot1)"
                                      @input="sendScreenshot(screenshot1)"></input-styled>
                    </div>
                    <div class="download-error" v-if="form.fileInputConcept.error">
                        <span class="i i-info3 i--red"></span>
                        <span class="text-uppercase" v-if="form.fileInputConcept.errorType == 'file'">Ошибка загрузки! Допускаются файлы в формате JPG, PNG, весом не более 30Мб.</span>
                        <span class="text-uppercase" v-else>Загрузите концепцию</span>
                    </div>
                </div>
                <div class="files mt24" v-if="form.fileInputConcept.value.icon || form.fileInputConcept.loading">
                    <div class="file img" :style="{'background-image':' url(\'' + appPath + form.fileInputConcept.value.icon +  '\')'}">
                        <div @click="form.fileInputConcept.value = {id: false,name: '',icon: ''}" class="i i-close3" v-if="!form.fileInputConcept.loading"></div>
                        <div class="preloader" v-if="form.fileInputConcept.loading"></div>
                        <span class="name" v-if="form.fileInputConcept.loading">{{ form.fileInputConcept.value.name}}</span>
                    </div>
                </div>
            </div>

            <div class="download-concept mt40">
                <div class="df df-space-between input">
                    <div class="" style="width: 211px">
                        <button @click="openInputRemove()" type="button" name="button" class="btn btn--trans full">Загрузить демонтаж</button>
                        <input style="opacity: 0;position: absolute; width: 0;" ref="fileInputRemove" @change="uploadFile($event, form.fileInputRemove)" type="file">
                    </div>
                    <span>или</span>
                    <div class="" style="width: 211px;">
                        <input-styled v-model="screenshot2.value"
                                      name="area"
                                      :placeholder="'Ссылка  на скриншот'"
                                      :placeholder-input="'Укажите ссылку на скриншот'"
                                      :isError="screenshot2.error"
                                      :errorText="screenshot2.errorText"
                                      @focus="removeError(screenshot2)"
                                      @input="sendScreenshot(screenshot2)"></input-styled>
                    </div>
                    <div class="download-error" v-if="form.fileInputRemove.error">
                        <span class="i i-info3 i--red"></span>
                        <span class="text-uppercase" v-if="form.fileInputRemove.errorType == 'file'">Ошибка загрузки! Допускаются файлы в формате JPG, PNG, весом не более 30Мб.</span>
                        <span class="text-uppercase" v-else>Загрузите демонтаж</span>
                    </div>
                </div>
                <div class="files mt24" v-if="form.fileInputRemove.value.icon || form.fileInputRemove.loading">
                    <div class="file img" :style="{'background-image':' url(\'' + appPath + form.fileInputRemove.value.icon +  '\')'}">
                        <div @click="form.fileInputRemove.value = {id: false,name: '',icon: ''}" class="i i-close3" v-if="!form.fileInputRemove.loading"></div>
                        <div class="preloader" v-if="form.fileInputRemove.loading"></div>
                        <span class="name" v-if="form.fileInputRemove.loading">{{ form.fileInputRemove.value.name}}</span>
                    </div>
                </div>
            </div>
            <hr>
            <div class="df df-space-between input">
            <input-styled v-model="form.nameJK.value"
                                      name="nameJK"
                                      :placeholder="'Название ЖК...'"
                                      :placeholder-input="'Укажите название ЖК...'"
                                      :isError="form.nameJK.error"
                                      :errorText="form.nameJK.errorText"
                                      @focus="removeError(form.nameJK)"></input-styled>
            <span style="width: 50px;"></span>
            <input-styled v-model="form.developer.value"
                                      name="developer"
                                      :placeholder="'Название застройщика...'"
                                      :placeholder-input="'Укажите название застройщика...'"
                                      :isError="form.developer.error"
                                      :errorText="form.developer.errorText"
                                      @focus="removeError(form.developer)"></input-styled>
            </div>
            <hr>
            <textarea-field :class="'full'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="'Характеристика от замерщика'"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Укажите характеристику"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Подтверждаю, что концепция одобрена Директором</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType" :class="{disabled: (form.fileInputRemove.loading || form.fileInputRemove.loading)}">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Одобрены»<br>
					и после этого будет вам недоступен
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';

    export default {
        name: "DownloadConcept",
        components: {
            SubmitBtn,
            InputStyled,
            TextareaField
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            openInputConcept: function () {
                this.$refs.fileInputConcept.click();
            },
            openInputRemove: function () {
                this.$refs.fileInputRemove.click();
            },
            sendScreenshot(screenshot){
                screenshot.error = false;
                if (screenshot.value == '' || screenshot.value != '' && screenshot.value.match(/prntscr.com|yadi.sk|prnt.sc/) === null) {
                    screenshot.error = true;
                } else {
                    let file = (screenshot.name == 'screenshot1')?this.form.fileInputConcept:this.form.fileInputRemove;       
                    file.errorType = false;
                    file.error = false;
                    file.loading = true;
                    file.value = {
                        id: false,
                        name: '',
                        icon: ''
                    }
                    let params = {
                        action: 'ScreenShot',
                        id: this.item.id,
                        params: {
                            value: screenshot.value
                        }
                    };
                    this.$store.dispatch('requests/actionRequest', params).then((res) => {
                        if (res.data.icon) {
                            file.value = res.data;
                        }
                        screenshot.value = '';
                        file.loading = false;
                    });


                }
            },
            getExt: function (name) {
                let parts;
                return (parts = name.split(".")).length > 1 ? parts.pop().toLowerCase() : "";
            },
            uploadFile(e, file){
                file.errorType = false;
                file.error = false;
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                let error = false;
                for (let i in files) {
                    if (!files[i].size) {
                        continue;
                    }
                    let ext = this.getExt(files[i].name);

                    if (!(files[i].size < 30 * 1024 * 1024 && (ext === 'jpg' || ext === 'jpeg' || ext === 'png' /*|| ext === 'doc' || ext === 'docx' || ext === 'pdf' || ext === 'xls' || ext === 'xlsx'*/))) {
                        error = true;
                    }
                }
                if (error) {
                    file.error = true;
                    file.errorType = 'file';
                } else {
                    file.loading = true;

                    for (let f in files) {
                        if (!files[f].size) {
                            continue;
                        }
                        file.value = {
                            id: false,
                            name: files[f].name,
                            icon: ''
                        }
                        let params = {
                            action: 'UploadFiles',
                            id: this.item.id,
                            params: {
                                file: files[f]
                            }
                        };
                        this.$store.dispatch('requests/actionRequest', params).then((res) => {
                            file.loading = false;
                            if (res && res.data.path) {
                                file.value = res.data;
                                this.$refs[file.ref].value = '';
                            }
                        });
                    }
                }
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(field == 'text' && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if(field == 'checkbox1' && this.form[field].value === false){
                        errorField = true;
                    }
                    if((field == 'fileInputConcept' || field == 'fileInputRemove')  && this.form[field].value.id === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }

                if(!error && !this.form.fileInputConcept.loading && !this.form.fileInputRemove.loading) {
                    let params = {
                        action: 'UploadConcept',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field == 'fileInputConcept' || field == 'fileInputRemove'){
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.downloadConcept.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                btnType: 'normal',
                appPath: process.env.VUE_APP_PATH_BACK,
                screenshot1: {
                    value: '',
                    error: false,
                    repeatError: false,
                    errorText: 'скрины – только из lightshot / yandex',
                    name: 'screenshot1',
                },
                screenshot2: {
                    value: '',
                    error: false,
                    repeatError: false,
                    errorText: 'скрины – только из lightshot / yandex',
                    name: 'screenshot2',
                },
                form: {
                    fileInputConcept: {
                        value: {
                            id: false,
                            name: '',
                            icon: ''
                        },
                        error: false,
                        errorType: false,
                        repeatError: false,
                        ref: 'fileInputConcept',  
                        loading: false,  
                    },
                    fileInputRemove: {
                        value: {
                            id: false,
                            name: '',
                            icon: ''
                        },
                        error: false,
                        errorType: false,
                        repeatError: false,
                        ref: 'fileInputRemove',
                        loading: false, 
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    nameJK: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: '',
                    },
                    developer: {
                        value: '',
                        error: false,
                        repeatError: false,
                        errorText: '',
                    }
                },

            }
        },
        mounted() {
            this.form.text.value  = this.item.props.characterZ;
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
