<template>
    <div class="">
        <requests-list/>

        <!-- Обычный замер -->
        <popup :show="popups.standardMeasure.show"
                :width="540"
               :name="popups.standardMeasure.name">
            <standard-measure/>
        </popup>

        <!-- Дистанционный замер -->
        <popup :show="popups.remoteMeasure.show"
                :width="540"
               :name="popups.remoteMeasure.name">
            <remote-measure/>
        </popup>

        <!-- Отклонить заявку -->
        <popup :show="popups.rejectRequest.show"
                :width="540"
               :name="popups.rejectRequest.name">
            <reject-request/>
        </popup>
        <!-- Отклонить заявку сразу -->
        <popup :show="popups.rejectRequestNow.show"
                :width="540"
               :name="popups.rejectRequestNow.name">
            <reject-request :not-call="true"/>
        </popup>

        <!-- Сообщить об отказе -->
        <popup :show="popups.rejectReport.show"
                :width="540"
               :name="popups.rejectReport.name">
            <reject-report/>
        </popup>

        <!-- Дата замера -->
        <popup :show="popups.measureDate.show"
                :width="540"
               :name="popups.measureDate.name">
            <measure-date/>
        </popup>

        <!-- Адрес объекта -->
        <popup :show="popups.objectAddr.show"
                :width="540"
               :name="popups.objectAddr.name">
            <object-addr/>
        </popup>

        <!-- Данные заказчика -->
        <popup :show="popups.customerData.show"
                :width="540"
               :name="popups.customerData.name">
            <customer-data/>
        </popup>

        <!-- Характеристика заказчика -->
        <popup :show="popups.customerCharacter.show"
                :width="540"
               :name="popups.customerCharacter.name">
            <customer-character/>
        </popup>

        <!-- Причина отказа -->
        <popup :show="popups.refuseReason.show"
                :width="540"
               :name="popups.refuseReason.name">
            <refuse-reason/>
        </popup>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Popup from '@/components/modal/Popup.vue';
    import RequestsList from '@/components/requests/RequestsList.vue';
    import StandardMeasure from '@/components/modal/requests/StandardMeasure.vue';
    import RemoteMeasure from '@/components/modal/requests/RemoteMeasure.vue';
    import RejectRequest from '@/components/modal/requests/RejectRequest.vue';
    import MeasureDate from '@/components/modal/requests/MeasureDate.vue';
    import ObjectAddr from '@/components/modal/requests/ObjectAddr.vue';
    import RejectReport from '@/components/modal/requests/RejectReport.vue';
    import CustomerData from '@/components/modal/requests/CustomerData.vue';
    import CustomerCharacter from '@/components/modal/requests/CustomerCharacter.vue';
    import RefuseReason from '@/components/modal/requests/RefuseReason.vue';

    export default {
        name: "RequestsNew",
        props: {
        },
        components: {
            Popup,
            RequestsList,
            StandardMeasure,
            RemoteMeasure,
            RejectRequest,
            MeasureDate,
            ObjectAddr,
            RejectReport,
            CustomerData,
            CustomerCharacter,
            RefuseReason,
        },
        data: function(){
          return {

          }
        },
        mounted(){

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
        methods: {
            fetchData () {
                //console.log('fetch');
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
