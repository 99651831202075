<template>
    <div>
        <div class="popup__header">
            <div class="title">Назначить демонстрацию 3D</div>
            <div class="text-color-white-trans text-bold mt4">{{item.name}}</div>
        </div>


        <div class="popup__content">
            <div class="">
                <calendar
                    v-if="calendarShow"
                    :timer-left="true"
                    :is-call="true"
                    :disabled-dates="offDates"
                    v-model="form.selectedDate"
                    :calendar-hidden="calendarHidden"
                    @calendarshow="calendarHidden = false">
                </calendar>
            </div>
            <div class="calendar__close" :class="{'--showed': !calendarHidden}" @click="calendarHidden = true">
                <div class="up">
                    <span class="i i-arrow2"></span>
                </div>
            </div>
            <div class="mt40">
                <input v-model="form.checkbox1.value" type="checkbox" id="checkbox1">
                <label for="checkbox1">Отправить заказчику СМС с датой и временем согласования</label>
            </div>
            <div class="mt16">
                <input v-model="form.checkbox2.value" type="checkbox" id="checkbox2">
                <label for="checkbox2">Отправить руководителю СМС с датой и временем согласования</label>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Демонстрация»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import Calendar from '@/components/Calendar.vue';
export default {
    name: "ModelDemo",
    components: {
        Tooltip,
        Tumbler,
        SubmitBtn,
        TextareaField,
        Calendar,
        InputStyled,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        setDate(val){
            this.selectedDate = val;
        },
        send(){
            let error = false;
            if(!error){
                this.btnType = 'load'
                let params =  {
                    action: 'ModelDemo',
                    id: this.item.id,
                    params: {
                        selectedDate: this.form.selectedDate.date+' '+this.form.selectedDate.time.hours+':'+this.form.selectedDate.time.minutes,
                        checkboxSendSmsC: this.form.checkbox1.value,
                        checkboxSendSmsR: this.form.checkbox2.value,
                    }
                };
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id});
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.modelDemo.name});
                    },this.options.delayPopupClose);
                },()=>{});
            }
        },
        formatDate(date) {
            let plusHour = 0;
            let m = date.getMinutes();
            let minutes = '00';
            if(m > 0) minutes = 15;
            if(m > 15) minutes = 30;
            if(m > 30) minutes = 45;
            if(m > 45) {minutes = '00'; plusHour++; };
            date.setHours(date.getHours()+plusHour);

            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;

            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yyyy = date.getFullYear();

            return {date:yyyy + '.' + mm + '.' + dd, time:{hours: date.getHours(), minutes: minutes}};
        }
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                selectedDate: {date: '2020.12.12', time: {hours: 12, minutes: 30}},
                option: {},
                tumbler: {
                    error: false,
                },
                objectName: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                text1: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                text2: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                checkbox1: {
                    value: true,
                    error: false,
                    repeatError: false,
                    type: 'checkbox'
                },
                checkbox2: {
                    value: true,
                    error: false,
                    repeatError: false,
                    type: 'checkbox'
                },
            },
            early: [
                {name: 'Готовы раньше', id: '168'},
                {name: 'Не готовы раньше', id: '185'},
            ],
            dates: [],
            calendarHidden: true,
            calendarShow: false,
        }
    },
    mounted() {
        if(this.item){
            let date = new Date();
            date.setHours(date.getHours()+2);
            this.form.selectedDate = this.formatDate(date);
            this.calendarShow = true;
        }
    },
    computed: {
        offDates(){
            let arr = this.dates.slice(0, this.dates.length);
            return arr;
        },
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
}
</script>

<style scoped>

</style>
