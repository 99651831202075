<template>
    <div>
        <div class="popup__header --error">
            <div class="title">Действие невозможно!</div>
        </div>


        <div class="popup__content">
            <div>Сначала сдайте финансовый отчёт.</div>
            <button type="button" name="button" class="btn btn--trans mt40" @click="close">всё понятно!</button>
        </div>
    </div>
</template>

<script>

    import {mapState} from "vuex";

    export default {
        name: "RepairFinishError",
        methods: {
            close(){
                this.$store.commit('popups/close', {name: this.popups.repairFinishError.name});
            }
        },
        data: function () {
            return {

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
