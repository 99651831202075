<template>
    <div>
        <div class="popup__header">
            <div class="title">Отметить 3D проверенным</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="df">
                <div class="inp-s-high" style="width: 152px" :class="{'error': false}">
                    <div class="top-label">
                        ОШИБКИ
                    </div>
                    <input-styled v-model="form.points.value"
                                  :placeholder-input="'0'"
                                  :isError="form.points.error"
                                  :errorText="form.points.errorText"
                                  :autoFocus="true"
                                  @focus="removeError(form.points)"
                                  @input="removeError(form.points)"></input-styled>
                    <div class="bottom-label">
                        1 ОШИБКА = {{ options.items.fineOnePoint }}{{ $store.getters['options/setRouble'] }}
                    </div>
                </div>
                <div class="ml64">
                    <div class="text-color-grey text-uppercase text-size-xs">
                        ШТРАФ
                    </div>
                    <div class="text-bold text-size-xxl mt8 mb8" style="line-height: 32px;" :class="{'text-color-trans': form.points.value.length < 1}">
                        {{ pointsRouble }} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                    <div class="text-color-grey text-size-xs dif">
                        <span v-if="form.points.value != ''">{{ form.points.value }}</span><span v-else>0</span><span>&nbsp;х {{ options.items.fineOnePoint }}{{ $store.getters['options/setRouble'] }}</span>
                    </div>
                </div>
            </div>

            <hr>

            <div>
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Подтверждаю, что 3D-модель можно демонстрировать заказчику</label>
            </div>

            <hr>

            <span class="text-italic text-color-grey">
                Координатору и Руководителю будет отправлено СМС,<br>
                что 3D-модель проверена и можно демонстрировать её заказчику.
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещён в «Проверены»<br>
					и после этого будет вам недоступен
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import {number_format} from '@/helpers/formatted.js';

export default {
    name: "ModelChecked",
    components: {
        SubmitBtn,
        InputStyled,
    },
    data: function () {
        return {
            btnType: 'normal',
            isSend: false,
            form: {
                checkbox1: {
                    value: false,
                    error: false,
                    repeatError: false,
                },
                points: {
                    value: '',
                    error: false,
                    repeatError: false,
                }
            },

        }
    },
    mounted() {

    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
        pointsRouble() {
            return number_format(this.form.points.value * this.options.items.fineOnePoint, 0, '', ' ');
        }
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send(){
            let error = false;

            for(let field in this.form){
                let errorField = false;
                this.form[field].error = false;
                /*if(this.form[field].value.length == 0){
                    errorField = true;
                }*/

                if (field === 'checkbox1' && this.form[field].value === false) {
                    errorField = true;
                }

                if(errorField){
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }
            if(!error && !this.isSend) {
                this.isSend = true;
                let params = {
                    action: 'ModelChecked',
                    id: this.item.id,
                    params: {}
                };
                for(let field in this.form){
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id});
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.modelChecked.name});
                    }, this.options.delayPopupClose);
                },()=>{});
            }
        }
    },
}
</script>

<style scoped>

</style>
