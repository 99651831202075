<template>
    <div>
        <div class="popup__header">
            <div class="title">Зафиксировать недозвон</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full'"
                            v-model="form.status_comment.value"
                            :error-text="''"
                            :auto-focus="true"
                            :label="'Служебный комментарий'"
                            :is-error="form.status_comment.error"
                            @focus="form.status_comment.error = false"
                            placeholder="Напишите краткую заметку, чтобы после вспомнить ситуацию..."
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            <div class="text-italic text-color-red2 mt40">
                Внимание! После нажатия кнопки заказчику будут отправлены уведомления,
                а также запущен процесс, который автоматически отклонит эту заявку через 24 ч.
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send()" tag="button" :type="btnType">
                    подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Недозвон»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';

export default {
    name: "NotCall",
    components: {
        SubmitBtn,
        TextareaField,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        send() {
            let error = false;
            for (let field in this.form) {
                let errorField = false;
                this.form[field].error = false;
                if (this.form[field].value.toString().length === 0
                    && this.form[field].type === 'text'
                    || this.form[field].value === false) {
                    errorField = true;
                }

                if (errorField) {
                    error = true;
                    setTimeout(() => {
                        this.form[field].error = true;
                    });
                }
            }

            if (!error) {
                let params = {
                    action: 'RequestNotCall',
                    id: this.item.id,
                    params: {}
                };
                for (let field in this.form) {
                    params.params[field] = this.form[field].value;
                }
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id})
                    setTimeout(() => {
                        this.$store.commit('popups/close', {name: this.popups.notCall.name});
                    }, this.options.delayPopupClose);
                }, () => {
                });
            }
        }
    },
    data: function () {
        return {
            btnType: 'normal',
            form: {
                status_comment: {
                    value: '',
                    error: false,
                    repeatError: false,
                    type: 'text',
                }
            },

        }
    },
    mounted() {

    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
}
</script>

<style scoped>

</style>
