<template>
    <div>
        <div class="popup__header">
            <div class="title">Подтвердить замер</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="">
                <calendar
                    :putAsideYes="form.option.id == options.putAsideYes"
                    :putAsideComment="form.text1.value"
                    :typeMetering="item.props.typeMetering==''?false:item.props.typeMetering"
                    v-if="calendarShow" :disabled-dates="offDates"
                    v-model="form.selectedDate"
                    :calendar-hidden="calendarHidden"
                    @calendarshow="calendarHidden = false">
                </calendar>
            </div>
            <div v-if="!calendarHidden" class="mt24">
                <tumbler @change="form.tumbler.error=false" :error="form.tumbler.error" :options="early"
                         :elem-width="114" v-model="form.option"/>
            </div>
            <div v-if="!calendarHidden" class="mt24">
                <textarea-field :class="'full'"
                                v-model="form.text1.value"
                                :error-text="''"
                                :label="'Комментарий'"
                                :is-error="form.text1.error"
                                @focus="form.text1.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>
            <div class="calendar__close" :class="{'--showed': !calendarHidden}" @click="calendarHidden = true">
                <div class="up">
                    <span class="i i-arrow2"></span>
                </div>
            </div>
            <div class="mb40 mt40">
                <textarea-field :class="'full'"
                                v-model="form.text2.value"
                                :error-text="''"
                                :label="'Характеристика от координатора и руководителя'"
                                :is-error="form.text2.error"
                                @focus="form.text2.error = false"
                                placeholder="Напишите совместную характеристику на заказчика"
                                :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            </div>

            <div>
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value"
                       :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox"
                       id="checkbox1">
                <label for="checkbox1">Папка объекта создана, договор подготовлен</label>
            </div>
            <hr>
            <span class="text-italic text-color-grey" v-if="form.sendSms.value">
                Заказчику будет отправлено СМС с датой и временем замера, а так же информацией о том, что замерщик позвонит за 2 дня.<a
                href="#" @click.prevent="form.sendSms.value = !form.sendSms.value" class="ml16">Не отправлять</a>
            </span>
            <span class="text-italic text-color-grey" v-if="!form.sendSms.value">
                Заказчику НЕ будет отправлено СМС с датой и временем замера, а так же информацией о том, что замерщик позвонит за 2 дня.<a
                href="#" @click.prevent="form.sendSms.value = !form.sendSms.value" class="ml16">Отправлять</a>
            </span>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="form.btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект будет перемещен в «Подтверждены»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Tumbler from '@/components/Tumbler.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import TextareaField from '@/components/forms/TextareaField.vue';
import InputStyled from '@/components/forms/InputStyled.vue';
import Calendar from '@/components/Calendar.vue';

export default {
    name: 'ConfirmMeasure',
    components: {
        Tooltip,
        Tumbler,
        SubmitBtn,
        TextareaField,
        Calendar,
        InputStyled,
    },
    methods: {
        removeError(form) {
            form.error = false;
        },
        setDate(val) {
            this.selectedDate = val;
        },
        send() {
            let error = false;
            this.form.checkbox1.error = false;
            this.form.tumbler.error = false;
            this.form.text1.error = false;
            this.form.text2.error = false;

            if (!this.form.checkbox1.value) {
                setTimeout(() => {
                    this.form.checkbox1.error = true;
                });
                error = true;
            }
            if (Object.keys(this.form.option).length == 0) {
                setTimeout(() => {
                    this.form.tumbler.error = true;
                });
                error = true;
            }
            if (this.form.text1.value.length == 0) {
                setTimeout(() => {
                    this.form.text1.error = true;
                });
                error = true;
            }
            if (this.form.text2.value.length == 0) {
                setTimeout(() => {
                    this.form.text2.error = true;
                });
                error = true;
            }
            if (!error) {
                this.form.btnType = 'load';
                let params = {
                    action: 'ConfirmMeasure',
                    id: this.item.id,
                    params: {
                        selectedDate: this.form.selectedDate.date + ' ' + this.form.selectedDate.time.hours + ':' + this.form.selectedDate.time.minutes,
                        isEarlier: this.form.option.id,
                        isEarlierComment: this.form.text1.value,
                        characterKR: this.form.text2.value,
                        sendSms: this.form.sendSms.value,
                    },
                };
                this.$store.dispatch('requests/actionRequest', params).then((data) => {
                    this.form.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', { id: this.item.id });
                    if (data.data.ext_data) {
                        this.$store.commit('popups/close', { name: this.popups.confirmMeasure.name });
                        this.$store.commit('popups/open', { name: this.popups.availableDate.name, request: data.data.ext_data });
                    } else {
                        setTimeout(() => {
                            this.$store.commit('popups/close', { name: this.popups.confirmMeasure.name });
                        }, this.options.delayPopupClose);
                    }
                }, () => {});
            }
            ;
        },
    },
    data: function () {
        return {
            form: {
                btnType: 'normal',
                selectedDate: { date: '2020.12.12', time: { hours: 12, minutes: 30 } },
                option: {},
                tumbler: {
                    error: false,
                },
                objectName: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                text1: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                text2: {
                    value: '',
                    error: false,
                    repeatError: false,
                },
                checkbox1: {
                    value: false,
                    error: false,
                    repeatError: false,
                },
                sendSms: {
                    value: true,
                },
            },
            early: [
                { name: 'Готовы раньше', id: '168' },
                { name: 'Не готовы раньше', id: '185' },
            ],
            dates: [],
            calendarHidden: true,
            calendarShow: false,
        };
    },
    mounted() {
        if (this.item) {
            this.form.selectedDate = { date: this.item.props.dateProject.calendarDate, time: { hours: this.item.props.dateProject.calendarHour, minutes: this.item.props.dateProject.calendarMinute } };
            if (this.item.props.putAside !== false) {
                this.form.option = { name: '', id: this.item.props.putAside };
                this.calendarHidden = false;
            }
            this.form.text1.value = this.item.props.putAsideComment;
            if (this.item.props.putAsideComment === '') {
                this.calendarHidden = false;
            }
            this.form.text2.value = this.item.props.characterKR;
            this.calendarShow = true;
            this.$store.dispatch('popups/getData', { id: this.item.id, modalData: 'CalendarDays' }).then(response => {
                this.dates = response.items.days;
            });
        }
    },
    computed: {
        dateNotify() {
            let str = '',
                d = new Date(this.form.selectedDate.date),
                month = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

            d.setDate(d.getDate() - 2);
            return d.getDate() + ' ' + month[d.getMonth()];
        },
        offDates() {
            let arr = this.dates.slice(0, this.dates.length);
            return arr;
        },
        ...mapState({
            popups: state => state.popups.items,
            item: state => state.popups.curItemData,
            options: state => state.options,
        }),
    },
};
</script>

<style scoped>

</style>
