<template>
    <div>
        <div class="popup__header">
            <div class="title">Заменить концепцию</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <textarea-field :class="'full'"
                            v-model="form.reasonReplace.value"
                            :error-text="''"
                            :label="'Причина замены концепции'"
                            :is-error="form.reasonReplace.error"
                            @focus="form.reasonReplace.error = false"
                            placeholder="Укажите причину, по которой изменилась концепция"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <hr>

            <div class="download-concept">
                <div class="df df-space-between input" :class="{downloaded: true}">
                    <div class="" style="width: 211px">
                        <button @click="openInput()" type="button" name="button" class="btn btn--trans full">Загрузить файл</button>
                        <input style="opacity: 0;position: absolute; width: 0;" ref="fileInput" @change="uploadFile($event)" type="file">
                    </div>
                    <span>или</span>
                    <div class="" style="width: 211px;">
                        <input-styled v-model="screenshot.value"
                                      name="area"
                                      :placeholder="'Ссылка  на скриншот'"
                                      :placeholder-input="'Укажите ссылку на скриншот'"
                                      :isError="screenshot.error"
                                      :errorText="screenshot.errorText"
                                      @focus="removeError(screenshot)"
                                      @input="sendScreenshot"></input-styled>
                    </div>
                    <div class="download-error" v-if="form.file.error">
                        <span class="i i-info3 i--red"></span>
                        <span class="text-uppercase" v-if="form.file.errorType == 'file'">Ошибка загрузки! Допускаются файлы в формате JPG, PNG, весом не более 30Мб.</span>
                        <span class="text-uppercase" v-else>Загрузите новую концепцию</span>
                    </div>
                </div>
                <div class="files mt24">

                    <a target="_blank" :href="appPath + item.props.conceptFile.path" class="file img old" :style="{'background-image':' url(\'' + appPath + item.props.conceptFile.icon +  '\')'}">
                        <div class="type">Старая</div>
                        <div class="locked">
                            <span class="i i-lock5 i--red"></span>
                        </div>
                    </a>
                        <div v-if="form.file.value.icon || fileLoad" class="file img new" :style="{'background-image':' url(\'' + appPath + form.file.value.icon +  '\')'}">
                        <div class="type">Новая</div>
                        <div @click="form.file.value = {id: false,name: '',icon: ''}" class="i i-close3" v-if="!fileLoad"></div>
                        <div class="preloader" v-if="fileLoad"></div>
                        <span class="name" v-if="fileLoad">{{ form.file.value.name}}</span>
                    </div>
                </div>
            </div>
            <hr>
            <textarea-field :class="'full'"
                            v-model="form.characterZ.value"
                            :error-text="''"
                            :label="'Характеристика от замерщика'"
                            :is-error="form.characterZ.error"
                            @focus="form.characterZ.error = false"
                            placeholder="Укажите характеристику"
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>

            <div class="mt40">
                <input @change="removeError(form.checkbox1)" v-model="form.checkbox1.value" :class="{error:form.checkbox1.error, 'repeat-error': form.checkbox1.repeatError}" type="checkbox" id="checkbox1">
                <label for="checkbox1">Подтверждаю, что НОВАЯ концепция одобрена Директором</label>
            </div>
            <div class="mt16">
                <input @change="removeError(form.checkbox2)" v-model="form.checkbox2.value" :class="{error:form.checkbox2.error, 'repeat-error': form.checkbox2.repeatError}" type="checkbox" id="checkbox2">
                <label for="checkbox2">Я знаю, что Владельцу и Директору будут отправлены уведомления</label>
            </div>


            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType" :class="{disabled: false}">
                    сохранить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Объект останется в «На согласовании»<br>
					и будет ожидать ваших дальнейших действий
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import InputStyled from '@/components/forms/InputStyled.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';

    export default {
        name: "ReplaceConcept",
        components: {
            SubmitBtn,
            InputStyled,
            TextareaField
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            openInput: function () {
                this.$refs.fileInput.click();
            },
            sendScreenshot(){
                this.screenshot.error = false;
                if (this.screenshot.value == '' || this.screenshot.value != '' && this.screenshot.value.match(/prntscr.com|yadi.sk|prnt.sc/) === null) {
                    this.screenshot.error = true;
                } else {
                    this.form.file.errorType = false;
                    this.form.file.error = false;
                    this.fileLoad = true;
                    this.form.file.value = {
                        id: false,
                        name: '',
                        icon: ''
                    }
                    let params = {
                        action: 'ScreenShot',
                        id: this.item.id,
                        params: {
                            value: this.screenshot.value
                        }
                    };
                    this.$store.dispatch('requests/actionRequest', params).then((res) => {
                        if (res.data.icon) {
                            this.form.file.value = res.data;
                        }
                        this.screenshot.value = '';
                        this.fileLoad = false;
                    });


                }
            },
            getExt: function (name) {
                let parts;
                return (parts = name.split(".")).length > 1 ? parts.pop().toLowerCase() : "";
            },
            uploadFile(e){
                this.form.file.errorType = false;
                this.form.file.error = false;
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                let error = false;
                for (let i in files) {
                    if (!files[i].size) {
                        continue;
                    }
                    let ext = this.getExt(files[i].name);

                    if (!(files[i].size < 30 * 1024 * 1024 && (ext === 'jpg' || ext === 'jpeg' || ext === 'png' /*|| ext === 'doc' || ext === 'docx' || ext === 'pdf' || ext === 'xls' || ext === 'xlsx'*/))) {
                        error = true;
                    }
                }
                if (error) {
                    this.form.file.error = true;
                    this.form.file.errorType = 'file';
                } else {
                    this.fileLoad = true;

                    for (let f in files) {
                        if (!files[f].size) {
                            continue;
                        }
                        this.form.file.value = {
                            id: false,
                            name: files[f].name,
                            icon: ''
                        }
                        let params = {
                            action: 'UploadFiles',
                            id: this.item.id,
                            params: {
                                file: files[f]
                            }
                        };
                        this.$store.dispatch('requests/actionRequest', params).then((res) => {
                            this.fileLoad = false;
                            if (res && res.data.path) {
                                this.form.file.value = res.data;
                                this.$refs.fileInput.value = '';
                            }
                        });
                    }
                }
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;
                    if(this.form[field].type == 'text' && this.form[field].value.length == 0){
                        errorField = true;
                    }
                    if(this.form[field].type == 'checkbox' && this.form[field].value === false){
                        errorField = true;
                    }
                    if(this.form[field].type == 'file' && this.form[field].value.id === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error && !this.fileLoad) {
                    let params = {
                        action: 'ReplaceConcept',
                        id: this.item.id,
                        params: {}
                    };
                    for(let field in this.form) {
                        if(field == 'file'){
                            params.params[field] = this.form[field].value.id;
                        } else {
                            params.params[field] = this.form[field].value;
                        }
                    }
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then((data) => {
                        this.btnType = 'ok';
                        this.$store.commit('requests/setItem', {item: this.item, returnItem: data.data});
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.replaceConcept.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            }
        },
        data: function () {
            return {
                appPath: process.env.VUE_APP_PATH_BACK,
                btnType: 'normal',
                fileLoad: false,
                screenshot: {
                    value: '',
                    error: false,
                    repeatError: false,
                    errorText: 'скрины – только из lightshot / yandex',
                },
                form: {
                    file: {
                        value: {
                            id: false,
                            name: '',
                            icon: ''
                        },
                        error: false,
                        errorType: false,
                        repeatError: false,
                        type: 'file'
                    },
                    reasonReplace: {
                        value: '',
                        error: false,
                        repeatError: false,
                        type: 'text'
                    },
                    characterZ: {
                        value: '',
                        error: false,
                        repeatError: false,
                        type: 'text'
                    },
                    checkbox1: {
                        value: false,
                        error: false,
                        repeatError: false,
                        type: 'checkbox'
                    },
                    checkbox2: {
                        value: false,
                        error: false,
                        repeatError: false,
                        type: 'checkbox'
                    },
                },

            }
        },
        mounted() {
            this.form.reasonReplace.value  = this.item.props.reasonReplace;
            this.form.characterZ.value  = this.item.props.characterZ;
            if(this.item.props.conceptFileNew){
                this.form.file.value  = this.item.props.conceptFileNew;
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
