<template>
    <div>
        <div class="popup__header">
            <div class="title">Предварительно одобрить</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-color-grey text-uppercase text-size-xs">
                Дата замера
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 text-uppercase">
                {{ item.props.dateProject.cardDate }}
            </div>
            <div class="text-color-grey text-size-xs df">
                <span class="mr8">{{ item.props.dateProject.cardTime }}</span>
                <tooltip
                        v-if="item.props.putAside == options.putAsideYes"
                        :direction="'right-center-direction'"
                        :is-btn-close="false"
                        :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <span class="link-color text-uppercase">
                            Готовы раньше
                        </span>
                    </template>

                    <template v-slot:tooltipContent>
                        <span v-html="convertNewLine(item.props.putAsideComment)"></span>
                    </template>
                </tooltip>
            </div>

            <hr>

            <div class="text-color-grey text-uppercase text-size-xs">
                Замерщик
            </div>
            <div class="text-bold text-size-xxl mt8 mb8 dif" v-if="item.extProps.team">
                <span class="mr8">{{ item.extProps.team.users.measurer.lastName }} {{ item.extProps.team.users.measurer.firstName }}</span>
                <span class="member" :class="item.extProps.team.users.measurer.char">{{ item.extProps.team.users.measurer.groupCode }}{{ item.extProps.team.users.measurer.number }}</span>
            </div>

            <hr>

            <div class="text-color-grey text-uppercase text-size-xs">
                Налогообложение
            </div>
            <div class="text-bold text-size-xxl mt8 mb8">
                Патент МО
            </div>
            <hr>
            <span class="text-italic text-color-grey">
                Координатору будет отправлено уведомление,<br>
                чтобы он организовал созвон с заказчиком
            </span>
            <div class="df df-space-between mt40">
                <submit-btn  @click="send()" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>

                <span class="text-right text-size-xs text-color-grey text-italic">
                    Заявка будет перемещена<br>
                    в раздел «Предварительно одобрены»
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Tooltip from '@/components/Tooltip.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';
    import { convertNewLine } from "@/helpers/util/helper";

    export default {
        name: "PreApprove",
        components: {
            Tooltip,
            Tumbler,
            SubmitBtn
        },
        methods: {
            convertNewLine(text) {
                return convertNewLine(text);
            },
            removeError(form) {
                form.error = false;
            },
            send(){
                let params = {
                    action: 'PreApprove',
                    id: this.item.id,
                    params: {}
                };
                this.btnType = 'load';
                this.$store.dispatch('requests/actionRequest', params).then(() => {
                    this.btnType = 'ok';
                    this.$store.commit('requests/deleteRequest', {id: this.item.id})
                    setTimeout(()=>{
                        this.$store.commit('popups/close', {name: this.popups.measureDetail.name});
                        this.$store.commit('popups/close', {name: this.popups.preApprove.name});
                    }, this.options.delayPopupClose);
                },()=>{});

            }
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                },

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
        },
    }
</script>

<style scoped>

</style>
